import { Component, OnInit, Input, } from '@angular/core';
import { GameService } from '../service/game.service';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { GruposComponent } from '../grupos/grupos.component';
import { DetalleService } from '../service/detalle.service';
import { CrearPlanes, JuegosRecursosSesion } from '../models/planes';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-modal-addplan',
  templateUrl: './modal-addplan.component.html',
  styleUrls: ['./modal-addplan.component.css']
})
export class ModalAddplanComponent implements OnInit {

  private token: string = localStorage.getItem('token');

  public misplanes = [];
  public planselected;
  public idplanselected;
  public id_plan;
  public sesionesinfo ;

  @Input() idgame: string;
  public juegosRecursos = new JuegosRecursosSesion();

  constructor(private gameService: GameService, private router: Router, private detalleService: DetalleService) {

  }

  ngOnInit() {
    this.detalleService.mostrarPlanes(this.token).subscribe((response: any) => {
      this.misplanes = response.planes;
    });

    $(".modal .close").click(function () {
      $('.modal').fadeOut();
      $('.modal').removeClass('show');
      setTimeout(function () {
        // Medium
        $(".js-modal-group").on('click', function (e) {
          e.preventDefault();
          $(".modal--medium").modal("show");
        });
      }, 1000);
    });

  }
  refresh() {
    location.reload();
  }

  changePlan:any = (e) => {
    let p = e.target.value;
    this.planselected = this.misplanes[p];
    this.id_plan = this.misplanes[p].id;

    this.detalleService.detallePlanes(this.token, this.id_plan, this.planselected.slug).subscribe((response: any) => {
      this.sesionesinfo = response.plan.sesiones;
      console.log(response);
    });
  }

  agregar(){
    console.log(this.planselected);
    console.log(this.sesionesinfo);
    this.gameService.vincularJuego(this.token, this.id_plan, this.idgame).subscribe((response:any)=>{

      if( this.sesionesinfo!=undefined && this.sesionesinfo.length>0 ){
        this.sesionesinfo.forEach(ses => {
          this.juegosRecursos.juegos = ses.juegos;
          this.juegosRecursos.otros_recursos = ses.otros_recursos;
          this.juegosRecursos.id = ses.id;
          let j = this.juegosRecursos.juegos.split(',');
  
          if(j.includes(this.idgame)==false){
            if(this.juegosRecursos.juegos=="") this.juegosRecursos.juegos = this.idgame;
            else this.juegosRecursos.juegos = this.juegosRecursos.juegos + ',' + this.idgame;
          }
          this.detalleService.editarJuegoRecSesiones(this.token, this.juegosRecursos ,this.id_plan).subscribe();
        });
      }

      Swal.fire({
        type: 'success',
        title: 'Recurso agregado',
        showConfirmButton: false,
        timer: 2000
      });
      
    },error => {
      console.log(error);
      Swal.fire({
        type: 'error',
        title: '¡Algo falló! \n Intenta de nuevo',
        showConfirmButton: false,
        timer: 2000
      });
    });

  }

}
