import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DetalleService } from '../service/detalle.service';
import { UserService } from '../service/user.service';
import { imagenesBlog } from '../service/config'; 
import Swal from 'sweetalert2';
import { CapacitacionesService } from '../service/capacitaciones.service';
import { Navegacion } from '../models/navigate';

@Component({
  selector: 'app-capacitacion-webinar',
  templateUrl: './capacitacion-webinar.component.html',
  styleUrls: ['./capacitacion-webinar.component.css']
})
export class CapacitacionWebinarComponent implements OnInit {

  private token: string = localStorage.getItem('token');
  public navegacion = new Navegacion();
  
  private IDwebinar: any;
  private webinar: any;
  private otros: any = [];
  private origen: any;
  private dateAvaible: boolean = true;
  
  private urlIMG = imagenesBlog.url;
  private sections: any;

  constructor(private route: ActivatedRoute, 
              private userService: UserService, 
              private router: Router,
              private detalleService: DetalleService, 
              private capacitacionService: CapacitacionesService) {
    this.navegacion.seccion = 27;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
  }

  inscribir(){
    if(this.sections.webinars.includes(''+this.webinar.id_seccion)){
      //ya esta inscrito, redirigir al webinar-Live
      this.router.navigate(['capacitacion-docente/webinar-live/0/'+this.IDwebinar]);
    }
    if(!this.sections.webinars.includes(''+this.webinar.id_seccion) && this.webinar.cobrar == 0){
      //inscribir gratis
      Swal.showLoading();
      let data = {
        id: this.IDwebinar,
        titulo: this.webinar.titulo,
        cobrar: this.webinar.cobrar,
      }
      console.log(data);
      this.capacitacionService.postInscribirWebinarGratis(data,this.token).subscribe((response: any) => {
        console.log(response);
        localStorage.setItem('token', response.token);
        this.refreshData();
        Swal.close();
      });
    }
    if(!this.sections.webinars.includes(''+this.webinar.id_seccion) && this.webinar.cobrar == 1){
      //inscribir con pago
      let data = {
        id: this.IDwebinar,
        titulo: this.webinar.titulo,
        cobrar: this.webinar.cobrar,
        precio: this.webinar.precio
      }
      this.capacitacionService.postInscribirWebinarCobro(data,this.token).subscribe((response: any) => {
        console.log(response);
      });
    }
  }

  back(){
    this.router.navigate(['capacitacion-docente/']);
  }

  refreshData(){
    this.sections = this.userService.getSecciones();
  }

  setDateAvability(dateWebinar:any){
    let today:any = new Date();
    let dateWeb: any = new Date(dateWebinar);
    var diff:any  = (dateWeb - today)/10000;
    if(diff < 0){
      this.dateAvaible = false;
    }else{
      this.dateAvaible = true;
    }
  }

  ngOnInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    this.IDwebinar = this.route.snapshot.paramMap.get('id');
    this.refreshData();
    this.capacitacionService.getWebinarInfo(this.IDwebinar,this.token).subscribe((response: any) => {
      this.webinar = response.webinar;
      this.setDateAvability(this.webinar.fecha);
      console.log("webinar:",this.webinar);
    });
    this.capacitacionService.getOtros(this.token).subscribe((response: any) => {
      this.otros = response;
      console.log("otros:", this.otros);
    });
    console.log("secciones:",this.sections);
  }
}
