export class Vincular {
    public username = "";
    public password = "";
    public fullname = "";
    public grado = "";
    public grupo = "";
}
export class NewGroup {
    public grado;
    public grupo = "";
    public color = "#f00ff0";
    public letra = "";
    public name = "";
}