import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { UpdateNuevosPlanes } from '../models/planes';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DetalleService } from './detalle.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class ImprimirService {
  
  private token = localStorage.getItem('token');
  
  public updatePlan = new UpdateNuevosPlanes();
  private ccts = [];
  Grados: Array<any> = [
    { grado: 'Primero de primaria', id: 1 },
    { grado: 'Segundo de primaria', id: 2 },
    { grado: 'Tercero de primaria', id: 3 },
    { grado: 'Cuarto de primaria', id: 4 },
    { grado: 'Quinto de primaria', id: 5 },
    { grado: 'Sexto de primaria', id: 6 },
  ];
  
  constructor(private detalleService: DetalleService,) { 
    this.detalleService.detallePerfil(this.token).subscribe((response:any)=>{
      this.ccts = response.cct_info;
    });
  }

  async generarPdf(plan, detallePlan) {

    var pdf = [];
    let gradotxt = '';
    var games = plan.games;
    var recursos = plan.contenidos.recursos;
    var libros = plan.contenidos.libros;

    if(plan.info.slug!='' || plan.info.slug!=null){

    /* Primera parte - Nombre */
    pdf.push( { text: "Planeación: " + plan.info.name + "\n", style: 'header', alignment: 'center'}, { text: plan.info.descripcion + "\n\n", style: 'subheader', alignment: 'center' });
    
    /* Segunda parte - Modelo Educativo */
    this.Grados.forEach(g => {
      if(plan.info.grado == g.id) gradotxt = g.grado;
    });

    pdf.push( { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 1, color: '#e533a2' }]}, "\n");
    var columnaizq = [];
    var columnader = [];
    if(plan.info.reforma =="2017"){ /* 2017 */
      let campo = '', asignatura = '', eje = '', tema = '';
      let aprendizajesselected = [];
      let aprenselectedid = plan.mod_edu[0].id_aprendizajes.split(',');
      detallePlan.modedu.forEach(me => {
        if(me.idModEdu == plan.mod_edu[0].id_modelo_educativo) {
          campo = me.formacionAcademica;
          asignatura = me.asignatura;
          eje = me.descripcion;
          tema = me.practicas_temas;
        }
      });
      detallePlan.aprendizajes.forEach(ap => {
          aprenselectedid.forEach(aprenid => {
          if(ap.id == aprenid) aprendizajesselected.push(ap.aprendizajes);
          });
      });
      columnaizq.push({text: 'Sistema Educativo' , style: 'titles'}, {text: 'México - Reforma educativa 2017' + '\n\n', style: 'answer'});
      columnader.push({text: 'Grado' , style: 'titles'}, {text: gradotxt + '\n\n', style: 'answer'});
      columnaizq.push({text: 'Campos de formación académica y áreas de desarrollo' , style: 'titles'}, {text: campo + '\n\n', style: 'answer'});
      columnader.push({text: 'Asignatura' , style: 'titles'}, {text: asignatura + '\n\n', style: 'answer'});
      if(asignatura == 'Lengua Materna') columnaizq.push({text: 'Ambito' , style: 'titles'}, {text: eje + '\n\n', style: 'answer'});
      else columnaizq.push({text: 'Eje' , style: 'titles'}, {text: eje + '\n\n', style: 'answer'});
      columnader.push({text: 'Tema' , style: 'titles'}, {text: tema + '\n\n', style: 'answer'});

      var columnas = [ columnaizq, columnader ];
      pdf.push({columns: columnas} );
      pdf.push( {text: 'Aprendizajes esperados', style: 'titles'}, {ul: aprendizajesselected, style: 'answer'});
      pdf.push( {text: '\n\n'});
    }

    else if(plan.info.reforma =="2011"){ /* 2011 */
      let aprendizajesp = [];
      let competenciaesp = [];
      let aprenselectedid = plan.mod_edu[0].id_apren_2011.split(',');
      let comptselectedid = plan.mod_edu[0].id_comp_2011.split(',');
      columnaizq.push({text: 'Sistema Educativo' , style: 'titles'}, {text: 'México - Reforma educativa 2011' + '\n\n', style: 'answer'});
      columnader.push({text: 'Grado' , style: 'titles'}, {text: gradotxt + '\n\n', style: 'answer'});
      if(plan.mod_edu[0].vida_saludable != null){
        let parte = '', contenido = '', sesion = '', objetivo = '';
        detallePlan.reforma.vida_saludable.forEach(vs => {
          if(vs.id == plan.mod_edu[0].vida_saludable){parte = vs.partes; contenido = vs.contenido; sesion = vs.sesion; objetivo = vs.objetivos; } 
        });
        columnaizq.push({text: 'Materia' , style: 'titles'}, {text: 'Vida saludable' + '\n\n', style: 'answer'});
        columnader.push({text: 'Parte' , style: 'titles'}, {text: parte + '\n\n', style: 'answer'});
        columnaizq.push({text: 'Contenido' , style: 'titles'}, {text: contenido + '\n\n', style: 'answer'});
        columnader.push({text: 'Sesión' , style: 'titles'}, {text: sesion + '\n\n', style: 'answer'});
        columnaizq.push({text: 'Objetivo' , style: 'titles'}, {text: objetivo + '\n\n', style: 'answer'});
        var columnas = [ columnaizq, columnader ];
        pdf.push({columns: columnas} );
      }
      else{
        let bloque = '', asig = '';
        let bloques = [
          { bloque: 'Bloque 1', id: 1 },
          { bloque: 'Bloque 2', id: 2 },
          { bloque: 'Bloque 3', id: 3 },
          { bloque: 'Bloque 4', id: 4 },
          { bloque: 'Bloque 5', id: 5 },
        ];
        if(plan.reformaonce[0].asignatura==11){
          let ambito = '', ttxt = '', psl = '';
          detallePlan.reforma.forEach(r => {
            if(r.id == plan.mod_edu[0].id_mod_edu_2011){
               ambito = r.descripcion; ttxt = r.tipo_texto; psl = r.practica_social_lenguaje; asig = r.asignatura
               bloques.forEach(b => {
                 if(r.bloque == b.id) bloque = b.bloque;
               });
            } 
          });
          columnaizq.push({text: 'Materia' , style: 'titles'}, {text: asig + '\n\n', style: 'answer'});
          columnader.push({text: 'Bloque' , style: 'titles'}, {text: bloque + '\n\n', style: 'answer'});
          columnaizq.push({text: 'Ambito' , style: 'titles'}, {text: ambito + '\n\n', style: 'answer'});
          columnader.push({text: 'Tipo de texto' , style: 'titles'}, {text: ttxt + '\n\n', style: 'answer'});
          columnaizq.push({text: 'Practica social del lenguaje' , style: 'titles'}, {text: psl + '\n\n', style: 'answer'});

        }else if(plan.reformaonce[0].asignatura==2){
          let eje = '', tema = '';
          detallePlan.reforma.forEach(r => {
            if(r.id == plan.mod_edu[0].id_mod_edu_2011){
               eje = r.descripcion; tema = r.practicas_temas; asig = r.asignatura
               bloques.forEach(b => {
                 if(r.bloque == b.id) bloque = b.bloque;
               });
            } 
          });
          columnaizq.push({text: 'Materia' , style: 'titles'}, {text: asig + '\n\n', style: 'answer'});
          columnader.push({text: 'Bloque' , style: 'titles'}, {text: bloque + '\n\n', style: 'answer'});
          columnaizq.push({text: 'Eje' , style: 'titles'}, {text: eje + '\n\n', style: 'answer'});
          columnader.push({text: 'Tema' , style: 'titles'}, {text: tema + '\n\n', style: 'answer'});

        }else if(plan.reformaonce[0].asignatura==15){
          let eje = '', tema = '', lenguaje = '';
          detallePlan.reforma.forEach(r => {
            if(r.id == plan.mod_edu[0].id_mod_edu_2011){
               eje = r.descripcion; tema = r.practicas_temas; lenguaje = r.lenguaje_artistico; asig = r.asignatura
               bloques.forEach(b => {
                 if(r.bloque == b.id) bloque = b.bloque;
               });
            } 
          });
          columnaizq.push({text: 'Materia' , style: 'titles'}, {text: asig + '\n\n', style: 'answer'});
          columnader.push({text: 'Bloque' , style: 'titles'}, {text: bloque + '\n\n', style: 'answer'});
          columnaizq.push({text: 'Lenguaje artístico' , style: 'titles'}, {text: lenguaje + '\n\n', style: 'answer'});
          columnader.push({text: 'Eje' , style: 'titles'}, {text: eje + '\n\n', style: 'answer'});
          columnaizq.push({text: 'Tema' , style: 'titles'}, {text: tema + '\n\n', style: 'answer'});

        }else if(plan.reformaonce[0].asignatura==14){
          let eje = '';
          detallePlan.reforma.forEach(r => {
            if(r.id == plan.mod_edu[0].id_mod_edu_2011){
               eje = r.descripcion; asig = r.asignatura
               bloques.forEach(b => {
                 if(r.bloque == b.id) bloque = b.bloque + ' - ' + r.nombre_bloque;
               });
            } 
          });
          columnaizq.push({text: 'Materia' , style: 'titles'}, {text: asig + '\n\n', style: 'answer'});
          columnader.push({text: 'Bloque' , style: 'titles'}, {text: bloque + '\n\n', style: 'answer'});
          columnaizq.push({text: 'Ambito' , style: 'titles'}, {text: eje + '\n\n', style: 'answer'});

        }else if(plan.reformaonce[0].asignatura==17){
          let eje = '';
          detallePlan.reforma.forEach(r => {
            if(r.id == plan.mod_edu[0].id_mod_edu_2011){
               eje = r.descripcion; asig = r.asignatura
               bloques.forEach(b => {
                 if(r.bloque == b.id) bloque = b.bloque + ' - ' + r.nombre_bloque;
               });
            } 
          });
          columnaizq.push({text: 'Materia' , style: 'titles'}, {text: asig + '\n\n', style: 'answer'});
          columnader.push({text: 'Bloque' , style: 'titles'}, {text: bloque + '\n\n', style: 'answer'});
          columnaizq.push({text: 'Eje Tematico' , style: 'titles'}, {text: eje + '\n\n', style: 'answer'});
        }else{
          detallePlan.reforma.forEach(r => {
            if(r.id == plan.mod_edu[0].id_mod_edu_2011){
              asig = r.asignatura
               bloques.forEach(b => {
                 if(r.bloque == b.id) bloque = b.bloque + ' - ' + r.nombre_bloque;
               });
            } 
          });
          columnaizq.push({text: 'Materia' , style: 'titles'}, {text: asig + '\n\n', style: 'answer'});
          columnader.push({text: 'Bloque' , style: 'titles'}, {text: bloque + '\n\n', style: 'answer'});
        }

        var columnas = [ columnaizq, columnader ];
        pdf.push({columns: columnas} );

        detallePlan.competencias.forEach(cp => {
          if(cp.mod_edu_2011 == plan.mod_edu[0].id_mod_edu_2011) {
            comptselectedid.forEach(compid => {
              if(cp.idcomp == compid) competenciaesp.push(cp.competencias);
              });
          }
        });
        detallePlan.aprendizajes.forEach(ap => {
          if(ap.mod_edu_2011 == plan.mod_edu[0].id_mod_edu_2011) {
            aprenselectedid.forEach(aprenid => {
            if(ap.idapren == aprenid) aprendizajesp.push(ap.aprendizajes);
            });
          }
        });
        pdf.push( {text: 'Competencias que se favorecen', style: 'titles'}, {ul: competenciaesp, style: 'answer'});
        pdf.push( {text: '\n'});
        pdf.push( {text: 'Aprendizajes esperados', style: 'titles'}, {ul: aprendizajesp, style: 'answer'});
        pdf.push( {text: '\n\n'});
      }



      
    }

    else if(plan.info.reforma =="libre"){ /* Libre */
      columnaizq.push({text: 'Sistema Educativo' , style: 'titles'}, {text: 'Planeación libre' + '\n\n', style: 'answer'});
      columnader.push({text: 'Grado' , style: 'titles'}, {text: gradotxt + '\n\n', style: 'answer'});
      var columnas = [ columnaizq, columnader ];
      pdf.push({columns: columnas} );
      if(plan.mod_edu[0].formacion != '/') pdf.push({text: 'Campos de formación académica y áreas de desarrollo' , style: 'titles'}, {text: plan.mod_edu[0].formacion + '\n\n', style: 'answer'});
      if(plan.mod_edu[0].asignatura != '/') pdf.push({text: 'Asignatura' , style: 'titles'}, {text: plan.mod_edu[0].asignatura + '\n\n', style: 'answer'});
      if(plan.mod_edu[0].eje != '/') pdf.push({text: 'Eje' , style: 'titles'}, {text: plan.mod_edu[0].eje + '\n\n', style: 'answer'});
      if(plan.mod_edu[0].tema != '/') pdf.push({text: 'Tema' , style: 'titles'}, {text: plan.mod_edu[0].tema + '\n\n', style: 'answer'});
      if(plan.mod_edu[0].aprendizajes_esperados != '/') pdf.push({text: 'Aprendizajes esperados' , style: 'titles'}, {text: plan.mod_edu[0].aprendizajes_esperados + '\n\n', style: 'answer'});

    }
    
    pdf.push( {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 1, color: '#e533a2' }]}, "\n");

    /* Tercera parte - Escuela */
    var columna1izq = [];
    var columna1der = [];

    if(plan.info.institucion!='/'){
      columna1izq.push( {text: 'Nombre de la institución educativa' , style: 'titles'}, {text: plan.info.institucion + '\n\n', style: 'answer'});
    }
    if(plan.info.nombre_docente!='/'){
      columna1izq.push( {text: 'Nombre del docente ' , style: 'titles'}, {text: plan.info.nombre_docente + '\n\n', style: 'answer'});
    }
    if(plan.info.ciclo!='/'){
      columna1izq.push( {text: 'Ciclo escolar', style: 'titles'}, {text: plan.info.ciclo + '\n\n', style: 'answer'});
    }
    if(plan.sesiones.length>0){
      columna1izq.push( {text: 'Número de sesiones de la planeación', style: 'titles'}, {text: plan.info.sesiones + '\n\n', style: 'answer'});
    }

    if(plan.info.cct!=0){
      let cct;
      this.ccts.forEach(c => {
        if(c.id == plan.info.cct) cct = c.cct;
      });
      columna1der.push( {text: 'Clave del centro de trabajo (CCT)' , style: 'titles'}, {text: cct + '\n\n', style: 'answer'});
    }
    if(plan.info.area!='/'){
      columna1der.push( {text: 'Área' , style: 'titles'}, {text: plan.info.area + '\n\n', style: 'answer'});
    }
    if(plan.info.duracion!='/'){
      columna1der.push( {text: 'Periodo (tiempo)' , style: 'titles'}, {text: plan.info.duracion + '\n\n', style: 'answer'});
    }
    if(plan.info.semana!='/'){
      columna1der.push( {text: 'Fecha de aplicación', style: 'titles'},{text: plan.info.semana + '\n\n', style: 'answer'});
    }

    var columnas1 = [ columna1izq, columna1der ]
    pdf.push({columns: columnas1} );
    pdf.push( {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 1, color: '#e533a2' }]}, "\n");

    /* Cuarta parte - Escuela */
    if(plan.info.aprendizajes_clave!='/'){
      pdf.push( {text: 'Aprendizajes clave', style: 'titles'}, {text: plan.info.aprendizajes_clave + '\n\n', style: 'answer'});
    }
    if(plan.info.propositos_pedagogicos!='/'){
      pdf.push( {text: 'Propósitos pedagógicos', style: 'titles'}, {text: plan.info.propositos_pedagogicos + '\n\n', style: 'answer'},);
    }
    if(plan.info.contexto!='/'){
      pdf.push( {text: 'Contexto', style: 'titles'}, {text: plan.info.contexto + '\n\n', style: 'answer'});
    }
    if(plan.info.bloque!='/' && plan.info.reforma!='2011'){
      pdf.push( {text: 'Bloque', style: 'titles'}, {text: plan.info.bloque + '\n\n', style: 'answer'});
    }
    pdf.push( {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 1, color: '#e533a2' }]}, "\n");

    /* Quinta parte - Sesiones */

    if(plan.sesiones.length>0){
      pdf.push({text: 'Actividades\n\n',  pageBreak: 'before', style: 'titles'});
      
      let i = 0;
      plan.sesiones.forEach((sesion,k) => {
        let number = k+1;

        if(sesion.status == 1){
          if(i!=0)  pdf.push({text: ' ',  pageBreak: 'before', style: 'titles'});
          let evaluaciones = sesion.instrumentos_evaluacion.split(',');
          let materiales = sesion.materiales_complementarios.split(',');

          let juegossesion = sesion.juegos.split(',');
          let recursosesion = sesion.otros_recursos.split(',');
          let librosesion = sesion.libros.split(',');
          //let columnasesionizq = []; 
          let columnasesionder = []; 

          recursos.forEach(async recurso => {
            if(recursosesion.includes(recurso.id_recurso.toString())) {
              //columnasesionizq.push( {image: await this.getBase64ImageFromURL('assets/images/icon/recursos.png'), width: 40, height: 40});
              columnasesionder.push( {text: '\n'+recurso.nombre, style: 'titles'}, {text: 'Contenido digital', style: 'answer', italics: true});
              columnasesionder.push( {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*120, y2: 5, lineWidth: 1, color: '#0098CE' }]}); 
            }
          });

          games.forEach(async game => {
            if(juegossesion.includes(game.id_game.toString())){ 
              //let url = 'assets/images/avataresgames/' + game.imagename;
              //columnasesionizq.push( {image: await this.getBase64ImageFromURL(url), width: 40, height: 40});
              columnasesionder.push( {text: '\n'+game.title, style: 'titles'}, {text: 'Videojuego', italics: true, style: 'answer'});
              columnasesionder.push( {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*120, y2: 5, lineWidth: 1, color: '#FCB619' }]}); 
            }
          });

          libros.forEach(async libro => {
            if(librosesion.includes(libro.id_libro.toString())) {
              columnasesionder.push( {text: '\n'+libro.tema, style: 'titles'}, {text: libro.libro+'-'+libro.grado+'\n'+libro.bloque+'- Página: '+libro.pag+'\nLink del libro: '+libro.linktx, style: 'answer', italics: true});
              columnasesionder.push( {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*120, y2: 5, lineWidth: 1, color: '#00729B' }]}); 
            }
          });

          columnasesionder.push({text: '\n\n', style: 'titles'});
          var columnassesiones = [ {text: '' , width: 20}, columnasesionder ];

          pdf.push(
            {	table: { widths: [70 ,'*', 100],
            body: [
                  [{text: 'Sesión ' + number, style: 'titles' }, {text: 'Descripción', style: 'titles' }, {text: 'Duración', style: 'titles' }],
                  [{text: 'Inicio ', style: 'titles' }, {text: sesion.descripcion_inicio, style: 'answer' }, {text: sesion.duracion_inicio, style: 'answer' }],
                  [{text: 'Desarrollo ', style: 'titles' }, {text: sesion.descripcion_desarrollo, style: 'answer' }, {text: sesion.duracion_desarrollo, style: 'answer' }],
                  [{text: 'Cierre ', style: 'titles' }, {text: sesion.descripcion_cierre, style: 'answer' }, {text: sesion.duracion_cierre, style: 'answer' }],
                  (sesion.materiales_complementarios=='/') ? [{text: 'Recursos didácticos', border: [true, false, true, false], style: 'titles', colSpan: 3}] 
                     : [{text: 'Recursos didácticos', border: [true, false, true, false], style: 'titles', colSpan: 2}, {}, {text: 'Materiales complementarios\n', border: [true, false, true, false], style: 'titles'}] ,
                  (sesion.materiales_complementarios=='/') ? [{columns: columnassesiones, border: [true, false, true, true], style: 'answer', colSpan: 3}] 
                     : [{columns: columnassesiones, border: [true, false, true, true], style: 'answer', colSpan: 2}, {}, {ul: materiales, border: [true, false, true, true], style: 'answer'}] ,
                  (sesion.reflexion!='/') ? [{text: 'Preguntas para la reflexión\n', border: [true, false, true, false], style: 'titles', colSpan: 3}] : [{text: '', border: [true, false, true, false], colSpan: 3}],
                  (sesion.reflexion!='/') ? [{text:  sesion.reflexion, border: [true, false, true, true], style: 'answer', colSpan: 3}] : [{text: '', border: [true, false, true, false], colSpan: 3}],
                  (sesion.activacion!='/') ? [{text: 'Actividades para la activación de conocimientos y saberes previos\n', border: [true, false, true, false], style: 'titles', colSpan: 3}] : [{text: '', border: [true, false, true, false], colSpan: 3}],
                  (sesion.activacion!='/') ? [{text: sesion.activacion, border: [true, false, true, true], style: 'answer', colSpan: 3}] : [{text: '', border: [true, false, true, false], colSpan: 3}],
                  (sesion.problematizacion!='/') ? [{text: 'Actividades para la problematización del conocimiento\n',  border: [true, false, true, false], style: 'titles', colSpan: 3}] : [{text: '',  border: [true, false, true, false], colSpan: 3}],
                  (sesion.problematizacion!='/') ? [{text: sesion.problematizacion, border: [true, false, true, true], style: 'answer', colSpan: 3}] : [{text: '',  border: [true, false, true, false], colSpan: 3}],
                  (sesion.profundizacion!='/') ? [{text: 'Actividades para profundización y reconstrucción de conocimiento\n',  border: [true, false, true, false], style: 'titles', colSpan: 3}] : [{text: '',border: [true, false, true, false], colSpan: 3}],
                  (sesion.profundizacion!='/') ? [{text: sesion.profundizacion, border: [true, false, true, true], style: 'answer', colSpan: 3}] : [{text: '',border: [true, false, true, false], colSpan: 3}],
                  [{text: 'Evaluación ' + number, style: 'titles', rowSpan: 5}, {table: {widths: [70, '*'],  border: [false, false, false, false], body : [
                      [ {text: 'Instrumento de evaluación',  border: [false, false, true, false], style: 'titles'}, {ul: evaluaciones,  border: [false, false, false, false]}]
                  ]}, border: [false, false, false, true]}, { text: ' ', border: [false, true, true, true]}],
                  (sesion.criterios_evaluacion!='/') ? [{},{text: 'Criterios de evaluación\n',  border: [true, false, true, false], style: 'titles', colSpan: 2}] : [{},{text: '',border: [true, false, true, false], colSpan: 2}],
                  (sesion.criterios_evaluacion!='/') ? [{},{text: sesion.criterios_evaluacion, border: [true, false, true, true], style: 'answer', colSpan: 2}] : [{},{text: '',border: [true, false, true, false], colSpan: 2}],
                  (sesion.evidencias!='/') ? [{},{text: 'Evidencias\n',  border: [true, false, true, false], style: 'titles', colSpan: 2}] : [{},{text: '',border: [true, false, true, false], colSpan: 2}],
                  (sesion.evidencias!='/') ? [{},{text: sesion.evidencias, border: [true, false, true, true], style: 'answer', colSpan: 2}] : [{},{text: '',border: [true, false, true, false], colSpan: 2}],
                  
                ]
              }
            }, '\n\n'
          );
          i++;
        }
      });
      pdf.push(  {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 1, color: '#e533a2' }]}, "\n");
    }


    /* Sexta parte - Director */
    //Si sesiones es > 0 hacer el break
    pdf.push({text: ' ', pageBreak: 'before', style: 'titles'});
    if(plan.info.director!='/' && plan.info.fecha!='/'){
      pdf.push(
        {	table: { widths: ['*', '*'], heights: [50, 50, 50],
          body: [
                [[{text: 'Nombre del director(a)\n', style: 'titles' }, {text: plan.info.director, style: 'answer' }], {text: 'Firma', style: 'titles', rowSpan: 2,}],
                [{text: 'Sello', style: 'titles', rowSpan: 2, }, ''],
                ['' , [{text: 'Fecha\n', style: 'titles' }, {text: plan.info.fecha, style: 'answer' }]],
          		]
          	}
          }, '\n\n'
      )
    }
    if(plan.info.director=='/' && plan.info.fecha!='/'){
      pdf.push(
        {	table: { widths: ['*', '*'], heights: [50, 10,50],
          body: [
                [{text: 'Sello', style: 'titles', rowSpan: 3 } , {text: 'Firma', style: 'titles', rowSpan: 2 }],
                ['', ''],
          			[ '', [{text: 'Fecha\n', style: 'titles' }, {text: plan.info.fecha, style: 'answer' }]]
          		]
          	}
          }, '\n\n'
      )
    }
    if(plan.info.director!='/' && plan.info.fecha=='/'){
      pdf.push(
        {	table: { widths: ['*', '*'], heights: [50, 10,50],
          body: [
                [[{text: 'Nombre del director(a)\n', style: 'titles' }, {text: plan.info.director, style: 'answer' }], {text: 'Firma', style: 'titles', rowSpan: 3,}],
                [{text: 'Sello', style: 'titles', rowSpan: 2, }, ''],
                ['', ''],
          		]
          	}
          }, '\n\n'
      )
    }
    if(plan.info.director=='/' && plan.info.fecha=='/'){
      pdf.push(
        {	table: { widths: ['*', '*'], heights: [100],
          body: [
                [{text: 'Sello', style: 'titles'}, {text: 'Firma', style: 'titles'}],
          		]
          	}
          }, '\n\n'
      )
    }
    pdf.push( {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 1, color: '#e533a2' }]}, "\n");

    /* Septima parte - Grupos */
    if(plan.contenidos.classroom.length>0){
      var grupos = '';
      plan.contenidos.classroom.forEach((g,index) => {
        if(index== plan.contenidos.classroom.length-1) grupos = grupos + g.grado + g.grupo 
        else grupos = grupos + g.grado + g.grupo + ','
      });
      pdf.push({columns:[
        {image: await this.getBase64ImageFromURL('assets/images/icon/grupos_home.png'), width: 90, height: 90},
        {width: 20, text: ''},
        [{ text: "Grupos vinculados a la planeación\n\n", style: 'header'}, { text: grupos + "\n\n", style: 'subheader' }]
      ] } );
    }

    } // end if
    let margen: any;
    margen = [40, 50, 40, 50]
      const reporte = { 
        //pageMargins: margen,
        info: {
          title: 'Planeacion',
        }, 

        content: pdf,
        
        styles: {
          header: {
            fontSize: 14,
            bold: true, 
          },
          subheader: {
            fontSize: 13,
          },
          titles: {
            fontSize: 12,
            bold:true,
          },
          answer: {
            fontSize: 12,
          }
        },

        footer: function (currentPage, pageCount) {
          return {
              table: {
                  body: [
                      [
                          { text: "LabTak® \n Página " + currentPage.toString() + ' de ' + pageCount, 
                            alignment: 'center', color: '#00bae0', margin: [250, -20, 0, 250] }
                      ],
                  ]
              },
              layout: 'noBorders'
          };
        },
        
      }
      pdfMake.createPdf(reporte).download();

  }

  private getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "Anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

}
