import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoomMtg } from '@zoomus/websdk';
import { CapacitacionesService } from '../service/capacitaciones.service';
declare var $: any;

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
ZoomMtg.i18n.load('es-ES');
ZoomMtg.i18n.reload('es-ES');

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css']
})

export class ZoomComponent implements OnInit {
  
  private token: string = localStorage.getItem('token');
  private ak: string = localStorage.getItem('ak');
  private mn: string = localStorage.getItem('mn');
  private ps: string = localStorage.getItem('ps');
  public meeting ;

  constructor(private capacitacionService: CapacitacionesService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {

    this.route.queryParams.subscribe(
      params => {
        this.meeting = JSON.parse(params['checkout']);
        console.log(this.meeting);
      }
    )
    if(this.ak== null || this.mn == null || this.ps == null){
      let cdw = this.meeting.leaveUrl.split('/');
      this.router.navigate(['capacitacion-docente/webinar/'+ cdw[6]]);
    } else{
      let dataZoom = {
        "meetingNumber": this.mn,
        "role": 0
      }
      console.log(dataZoom);
      this.capacitacionService.postZoom(dataZoom, this.token).toPromise().then((data: any) => {
        if (data.signature) {
          console.log(data.signature);
          this.startMeeting(data.signature);
        } else {
          console.log(data);
        }
      }).catch((error) => {
        console.log(error);
      })
    }

  }

  startMeeting(signature) {
    const zoomMeeting = document.getElementById('zmmtg-root').style.display = 'block';
    const startmeet = document.getElementById('zmmtg-root').style.zIndex = "999";

    ZoomMtg.init({
      leaveUrl: this.meeting.leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          signature: signature,
          meetingNumber: this.mn,
          userName: this.meeting.name,
          apiKey: this.ak,
          userEmail: this.meeting.email,
          passWord: this.ps,
          success: (success) => {
            console.log(success);
            const footer = document.getElementById('wc-footer').style.padding = "0";
            var bot = Array.from(document.getElementsByClassName('meeting-info-icon__icon--encryption') as HTMLCollectionOf<HTMLElement>)
            bot[0].style.display= 'none';
            var bot2 = Array.from(document.getElementsByClassName('meeting-info-icon__icon-wrap') as HTMLCollectionOf<HTMLElement>)
            bot2[0].style.display= 'none';
            var displ = Array.from(document.getElementsByClassName('dropdown-menu') as HTMLCollectionOf<HTMLElement>)[0].style.display='block';
          },
          error: (error) => {
            console.log(error);
          }
        })
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

}
