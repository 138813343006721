import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sylos-gracias',
  templateUrl: './sylos-gracias.component.html',
  styleUrls: ['./sylos-gracias.component.css']
})
export class SylosGraciasComponent implements OnInit {

  public username: String;
  public urlTTT = 'https://taktaktak.com/registro-land/';
  public section = 0;

  constructor( private activatedRoute: ActivatedRoute, private router: Router, private location: Location) { 
    let url = this.location.path().split('/')[1];
    (url == 'gracias_maestros') ? this.section = 1 : (url=='gracias_ninos') ? this.section = 2 : this.section=0;

    if(this.section==1){
      setTimeout(() => {
        this.router.navigate(['inicio']);
      }, 3000);
    }else if(this.section==2){
      this.username =  this.activatedRoute.snapshot.params.username;
      this.urlTTT = this.urlTTT + this.username;
      console.log(this.username)
      console.log(this.urlTTT)
      setTimeout(() => {
        window.location.href = 'https://taktaktak.com/registro-land/'+this.username;
      }, 3000);
    }
  }

  ngOnInit() {
  }

}
