import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Navegacion } from '../models/navigate';
import { DetalleService } from '../service/detalle.service';
import { ImprimirPubService } from '../service/imprimir-pub.service';
import { PublicasService } from '../service/publicas.service';

@Component({
  selector: 'app-plan-compartida',
  templateUrl: './plan-compartida.component.html',
  styleUrls: ['./plan-compartida.component.css']
})
export class PlanCompartidaComponent implements OnInit {

  private token = localStorage.getItem('token');
  public navegacion = new Navegacion();
  public ref = 0;
  public planes = [];
  public detallereformas = [];
  public publicas = [];
  public compartidas = [];

  constructor(private planService: PublicasService, private detalleService: DetalleService, private imprimirService: ImprimirPubService) {
      this.navegacion.seccion = 16;
      this.detalleService.navegacion(this.token, this.navegacion).subscribe();
   }

  ngOnInit() {
    this.planService.misplaneacionesCompartidas(this.token).subscribe((response:any)=>{
      console.log(response);
      this.publicas = response.publicas;
      this.compartidas = response.compartidas;
    });

  }

  
  generatePdf(slug,id){
    Swal.showLoading();

    let detalleplan = [];
    let reforma, grado, asignatura;
    let autor;
    this.planService.detallePlanes(this.token, id, slug).subscribe( (response: any) => { 
      autor = response.autor[0];
      detalleplan= response.plan;
      reforma = response.plan.info.reforma;
      grado = response.plan.info.grado;
      if(reforma=='2011') {
        if(response.plan.mod_edu[0].id_mod_edu_2011 == null) asignatura = 19;
        else asignatura = response.plan.reformaonce[0].asignatura;
      }
    });
    setTimeout(() => {
      //this.imprimirService.generarPdf(detalleplan);
      if(reforma == '2017') {
        this.detalleService.getModedu(this.token).subscribe(async (response: any) => {
          this.detallereformas = response;
          this.imprimirService.generarPdf(detalleplan, this.detallereformas, autor);
        });
      } 
      if(reforma == '2011'){
        this.detalleService.getReformaxGrado(this.token, grado, asignatura).subscribe((response: any) => {
          this.detallereformas = response;
          this.imprimirService.generarPdf(detalleplan, this.detallereformas, autor); 
        });
      }
      if(reforma == 'libre')this.imprimirService.generarPdf(detalleplan, '', autor); 
    }, 3000);

    setTimeout(() => {Swal.close();}, 6000);
  }

}
