import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Navegacion } from '../models/navigate';
import { DetalleService } from '../service/detalle.service';
import { UserService } from '../service/user.service';
import { imagenesCapacitaciones } from '../service/config'; 
import { CapacitacionesService } from '../service/capacitaciones.service';

@Component({
  selector: 'app-capacitacion',
  templateUrl: './capacitacion.component.html',
  styleUrls: ['./capacitacion.component.css']
})
export class CapacitacionComponent implements OnInit {

  private token: string = localStorage.getItem('token');
  public navegacion = new Navegacion();
  private sections:any;
  private temasList:any;
  private searchData:any = {};
  private resultList:any = [];
  private proximos:any = [];
  private urlIMG = imagenesCapacitaciones.url;
  Arr = Array;

  constructor(private route: ActivatedRoute, 
              private userService: UserService, 
              private detalleService: DetalleService, 
              private capacitacionService: CapacitacionesService) {
    this.navegacion.seccion = 24;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
  }

  busqueda(){
    this.capacitacionService.postBusqueda(this.searchData,this.token).subscribe((response: any) => {
      this.resultList = response.resultados;
      console.log(this.resultList);
    });
  }

  ngOnInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    this.sections = this.userService.getSecciones();
    this.capacitacionService.getTemas(this.token).subscribe((response: any) => {
      this.temasList = response.temas;
    });
    this.capacitacionService.getProximos(this.token).subscribe((response: any) => {
      this.proximos = response.resultados;
      console.log("proximos: ",this.proximos);
    });
    this.searchData = {
      tipo:0,
      id_tema:0,
      fecha:0
    }
    
    let tipo = this.route.snapshot.paramMap.get('tipo');
    if (tipo != undefined) {this.searchData.tipo = tipo; this.busqueda();}
  }

}
