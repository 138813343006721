export class OtrosRecursos {
    public id: number;
    public nombre = '';
    public descripcion = '';
    public link = '';
    public video = '';
    public status?: boolean;
    public tags = '';
    public img?= '';
    public pdf?= '';
    public pdfaux?= '';
}

export class SearchObj {
    public searchOb: string;
}
