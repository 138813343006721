import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { imagenes } from '../service/config';
import { GameService } from '../service/game.service';
import { Sesiones } from '../models/planes';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-actividades-detalle',
  templateUrl: './actividades-detalle.component.html',
  styleUrls: ['./actividades-detalle.component.css']
})
export class ActividadesDetalleComponent implements OnInit {

  @Input () sesion: number;
  @Input () sesionenviar: Sesiones;
  @Input () games;
  @Input () recursos;
  @Input () libros;
  @Input () id_plan;
  @Output () sesiones = new EventEmitter<Sesiones>();
  @Output () eliminar = new EventEmitter<Sesiones>();
  
  private token: string = localStorage.getItem('token');

  public is_edit = [];
  public editar = [];
  
  public urlImg: string = imagenes.url;
  public list2 = [];
  public list3 = [];
  public list4 = [];
  //public sesionenviar = new Sesiones();
  public material1 = '';
  public material2 = '';
  public material3 = '';
  public eval = '';
  
  Evaluacion: Array<any> = [
    { tag: 'Rúbrica', status: false },
    { tag: 'Producciones escritas', status: false },
    { tag: 'Portafolio', status: false },
    { tag: 'Lista de cotejo', status: false },
    { tag: 'Proyectos', status: false },
    { tag: 'Puertas escritas u orales', status: false },
    { tag: 'Anecdotario', status: false },
    { tag: 'Registro de actitudes', status: false },
    { tag: 'Observación directa', status: false },
    { tag: 'Esquemas', status: false },
  ];

  constructor(private gameService: GameService) { }

  ngOnInit() {
    this.checkRec2();
    this.cargarEvaluaciones();
    if(this.sesionenviar.materiales_complementarios == '/') this.checkRec();
    else{
      this.material1 = this.sesionenviar.materiales_complementarios.split(',')[0];
      this.material2 = this.sesionenviar.materiales_complementarios.split(',')[1];
      this.material3 = this.sesionenviar.materiales_complementarios.split(',')[2];
    }
    for (let index = 0; index <8; index++) {
      this.is_edit[index] = false;
    }
    for (let index = 0; index <14; index++) {
      this.editar[index] = false;
    }
    let juegossesion = this.sesionenviar.juegos.split(',');
    let recursosesion = this.sesionenviar.otros_recursos.split(',');
    let librossesion = this.sesionenviar.libros.split(',');

    this.games.forEach(game => {
      if(juegossesion.includes(game.id_game.toString())) this.list2.push(game);
    });
    this.recursos.forEach(recurso => {
      if(recursosesion.includes(recurso.id_recurso.toString())) this.list3.push(recurso);
    });
    this.libros.forEach(libro => {
      if(librossesion.includes(libro.id_libro.toString())) this.list4.push(libro);
    });
  }

  isDisabled(value) {
    (this.is_edit[value]) ? this.is_edit[value] = false :  this.is_edit[value] = true; 
  }
  
  isEditable(value){
    (this.editar[value]) ? this.editar[value] = false :  this.editar[value] = true; 
  }

  eliminarSesion(){
    Swal.fire({
      html: '<h3> ¿Estas seguro de eliminar la sesión '+ this.sesion + '?</h3>',
      showCancelButton: true,
      confirmButtonColor: '#0098CE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText:'Cancelar'
    }).then((result) => {
        if (result.value) {
          this.sesionenviar.status=0;
          this.sesionenviar.numero= this.sesion;
          this.eliminar.emit(this.sesionenviar);
        }
    });
  }

  enviarSesion(){
    this.eval = '';
    if(this.sesionenviar.materiales_complementarios=='/') this.checkRec();
    else this.sesionenviar.materiales_complementarios = this.material1 + ',' + this.material2 + ',' + this.material3;
    this.sesionenviar.numero = this.sesion;
    this.Evaluacion.forEach(element => {
      if(element.status) this.eval = this.eval + element.tag + ',';
    });
    this.sesionenviar.instrumentos_evaluacion = this.eval;
    this.revisarDes();
    this.sesiones.emit(this.sesionenviar);
  }

  revisarDes(){
    if(this.is_edit[0])  this.sesionenviar.materiales_complementarios = '/';
    if(this.is_edit[1])  this.sesionenviar.reflexion = '/';
    if(this.is_edit[2])  this.sesionenviar.activacion = '/';
    if(this.is_edit[3])  this.sesionenviar.problematizacion = '/';
    if(this.is_edit[4])  this.sesionenviar.profundizacion = '/';
    if(this.is_edit[6])  this.sesionenviar.criterios_evaluacion = '/';
    if(this.is_edit[7])  this.sesionenviar.evidencias = '/';
  }

  changeStatus(i){
    if(this.Evaluacion[i].status) this.Evaluacion[i].status = false;
    else this.Evaluacion[i].status = true;
  }

  cargarEvaluaciones(){
    let evaluaciones = this.sesionenviar.instrumentos_evaluacion.split(',');
    this.Evaluacion.forEach(element => {
      evaluaciones.forEach(e => {
        if(element.tag == e) element.status = true;
      });
    });
  }

  checkRec(){
    let td = document.getElementById('recdid') as HTMLTableDataCellElement;
    $(td).attr('colspan', '3')
  }

  checkRec2(){
    if(this.sesionenviar.juegos=="" && this.sesionenviar.otros_recursos==""){
      let rd = document.getElementById('recursos-didacticos') as HTMLDivElement;
      $(rd).attr('style', 'height:100px')
    }
  }

  
  eliminarJuego(idGame, i){
    let j = this.sesionenviar.juegos.split(',');

    let ind = j.indexOf( idGame.toString() );
    if ( ind != -1 ) {
      j.splice( ind, 1 );
      this.sesionenviar.juegos="";
      for (let index = 0; index < j.length; index++) {
        if(this.sesionenviar.juegos=="") this.sesionenviar.juegos = j[index];
        else this.sesionenviar.juegos = this.sesionenviar.juegos + ',' + j[index];
      }
    }

    this.list2.splice(i,1);
  }

  eliminarRecursosExternos(idRecurso, i) {
    let r = this.sesionenviar.otros_recursos.split(',');

    let ind = r.indexOf( idRecurso.toString() );
    if ( ind != -1 ) {
      r.splice( ind, 1 );
      this.sesionenviar.otros_recursos="";
      for (let index = 0; index < r.length; index++) {
        if(this.sesionenviar.otros_recursos=="") this.sesionenviar.otros_recursos = r[index];
        else this.sesionenviar.otros_recursos = this.sesionenviar.otros_recursos + ',' + r[index];
      }
    }

    this.list3.splice(i,1);
  }

  eliminarLibros(idLibro, i) {
    let l = this.sesionenviar.libros.split(',');
    let ind = l.indexOf( idLibro.toString() );
    if ( ind != -1 ) {
      l.splice( ind, 1 );
      this.sesionenviar.libros="";
      for (let index = 0; index < l.length; index++) {
        if(this.sesionenviar.libros=="") this.sesionenviar.libros = l[index];
        else this.sesionenviar.libros = this.sesionenviar.libros + ',' + l[index];
      }
    }
    this.list4.splice(i,1);
  }


}
