import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DetalleService } from '../service/detalle.service';
import { UserService } from '../service/user.service';
import { imagenesBlog } from '../service/config'; 
import { CapacitacionesService } from '../service/capacitaciones.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-capacitacion-mooc',
  templateUrl: './capacitacion-mooc.component.html',
  styleUrls: ['./capacitacion-mooc.component.css']
})
export class CapacitacionMoocComponent implements OnInit {

  private token: string = localStorage.getItem('token');
  private idMooc: any;
  private mooc: any;
  private dateAvaible: boolean = true;
  private sections: any;
  Arr = Array;
  
  private urlIMG = imagenesBlog.url;

  constructor(private route: ActivatedRoute, private userService: UserService, private router: Router,
              private detalleService: DetalleService, private capacitacionService: CapacitacionesService) { }


  setDateAvability(dateMooc:any){
    let today:any = new Date();
    let dateMo: any = new Date(dateMooc);
    var diff:any  = (dateMo - today)/10000;
    if(diff < 0){
      this.dateAvaible = false;
    }else{
      this.dateAvaible = true;
    }
  }

  refreshData(){
    this.sections = this.userService.getSecciones();
  }

  ngOnInit() {
    this.idMooc = this.route.snapshot.paramMap.get('id');
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    this.refreshData();
    this.capacitacionService.getMooc(this.idMooc,this.token).subscribe((response: any) => {
      this.mooc = response.mooc;
      this.setDateAvability(this.mooc.fecha);
    });
  }

  back(){
    this.router.navigate(['capacitacion-docente/']);
  }

  inscribir(){
    if(this.sections.moocs.includes(''+this.mooc.id_seccion)){ //ya esta inscrito, redirigir al mooc
      this.router.navigate(['capacitacion-docente/mooc-insc/0/'+this.idMooc]);
    }
    else if(!this.sections.moocs.includes(''+this.mooc.id_seccion) && this.mooc.cobrar == 0){ //inscribir gratis
      Swal.showLoading();
      let data = {
        id: this.idMooc,
        titulo: this.mooc.titulo,
        cobrar: this.mooc.cobrar,
      }
      this.capacitacionService.postInscribirMoocGratis(data,this.token).subscribe((response: any) => {
        Swal.fire(" ", response.message, "success");
        localStorage.setItem('token', response.token);
        this.refreshData();
      }, error => {
          Swal.fire(" ", error.error.message, "warning");
      });
    }
    else if(!this.sections.moocs.includes(''+this.mooc.id_seccion) && this.mooc.cobrar == 1){ //inscribir con pago
      let data = {
        id: this.idMooc,
        titulo: this.mooc.titulo,
        cobrar: this.mooc.cobrar,
        precio: this.mooc.precio
      }
      // this.capacitacionService.postInscribirMoocCobro(data,this.token).subscribe((response: any) => {
      //   console.log(response);
      // });
    }
  }

}
