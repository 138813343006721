import { Component, OnInit, Input } from "@angular/core";

const textoAviso = `
<p><strong>I.- Identidad y domicilio del responsable.</strong><br>
Innova y Moderniza Tu Aprendizaje, A.C. (Inoma), con domicilio en Calle Reforma No. 9, Colonia Villa Coyoacán, C.P. 04000, Alcaldía Coyoacán, Ciudad de México, es responsable de recabar los datos personales y sensibles del titular, del manejo de los mismos y de su protección. Sus datos personales serán tratados por Inoma. Este aviso de privacidad forma parte del sitio: <a href="http://www.labtak.mx">http://www.labtak.mx</a></p>

<p><strong>II.- Uso de la Información.</strong><br>
El sitio <a href="http://www.labtak.com.mx">http://www.labtak.com.mx</a> se encuentra dirigido, pero no limitado a: Padres de Familia, Maestros de Instituciones Públicas y Privadas, Director de Instituciones Públicas y Privadas, Encargado del Aula de Medios (conocido como RAM), en lo sucesivo (“El Usuario”, “Los Usuarios”), tanto en la República Mexicana como en el Extranjero.</p>

<p>En el sitio <a href="http://www.labtak.mx">http://www.labtak.mx</a> se publicará información relevante para que los maestros puedan aprender cómo incorporar el uso de herramientas lúdico-educativas a sus planes de enseñanza.</p>

<p>En la página y a través de ella “El Usuario” podrá tener interacción digital con uno o varios Usuarios de la misma página a través del Blog.</p>

<p>“El Usuario”, en específico los Maestros, podrán generar contenido digital dentro del sitio. Dicho contenido será propiedad de Inoma (los cuales son los derechos patrimoniales), sin embargo el autor de dicho contenido será el profesor que lo haya creado (conocidos como derechos morales). Lo anterior de conformidad con los artículos 11, 12, 13 y demás relativos y aplicables de la Ley Federal del Derecho de Autor.</p>

<p>En la página <a href="http://www.labtak.mx">http://www.labtak.mx</a>, habrá documentos descargables que “El Usuario” podrá consultar en su dispositivo.</p>

<p>Inoma creará Políticas para “El Usuario” del sitio <a href="http://www.labtak.mx">http://www.labtak.mx</a> a través de los cuales definirá y tendrá la facultad de dar de baja de la página a “El o Los Usuarios” que entre otros escenarios no cumplan con buen comportamiento y lenguaje moderado en el foro dentro del sitio.</p>

<p>Dentro de la página <a href="http://www.labtak.mx">http://www.labtak.mx</a>, habrá ligas de interés a otros sitios ajenos a Inoma. El contenido en esos sitios no es propiedad de Inoma.</p>

<p>La información y datos de “El Usuario” o “Los Usuarios” podrán ser utilizados para generar reportes dirigidos a los donantes de Inoma.</p>

<p>Dentro del sitio <a href="http://www.labtak.mx">http://www.labtak.mx</a> “El Usuario” o “Los Usuarios” podrán realizar compras de la Licencia de LabTak. Dicha licencia otorga a los compradores acceso a reportes y contenido exclusivo. Para efectos del pago en línea, la operación de compra-venta y la información bancaria la realiza un tercero totalmente ajeno a Inoma.</p>

<p>Los datos obligatorios que “El Usuario” deberá proporcionar para crear su Perfil de Usuario, son:</p>
<ul>
  <li>Nombre</li>
  <li>Apellido(s)</li>
  <li>Género</li>
  <li>Edad</li>
  <li>Correo Electrónico válido</li>
  <li>Contraseña del Usuario en la Plataforma</li>
  <li>Definir si es Padre, Madre u Otro</li>
  <li>País</li>
  <li>Estado</li>
  <li>Ocupación</li>
  <li>Tipo de Docente</li>
  <li>CCT / Nombre de la Escuela</li>
</ul>

<p>Los datos que el Maestro, Director y Encargado del Aula de Medios, para el registro de usuario, deben proporcionar la siguiente información:</p>
<ul>
  <li>Nombre</li>
  <li>Apellido(s)</li>
  <li>Género</li>
  <li>Edad</li>
  <li>Correo Electrónico</li>
  <li>Contraseña del Usuario en la Plataforma</li>
  <li>Definir si el Usuario es Maestro, Director o Encargado del Aula de Medios</li>
  <li>Confirmar si el Usuario trabaja en una Escuela Mexicana</li>
  <li>Clave del Centro de Trabajo (CCT)</li>
  <li>Nivel Educativo en el que se imparte clases</li>
  <li>Tipo de Docente</li>
  <li>País</li>
  <li>Estado</li>
</ul>

<p>Para la generación de grupos se solicita:</p>
<ul>
  <li>Nombre del Alumno</li>
  <li>Usuario TTT</li>
  <li>Contraseña del usuario TTT</li>
</ul>

<p>Una vez que “El Usuario” interesado en ingresar al sitio <a href="http://www.labtak.mx">http://www.labtak.mx</a> para interactuar con las actividades establecidas en él, y que hayan previamente proporcionado los datos mencionados en los párrafos anteriores, Inoma le enviará un correo electrónico a “El Usuario” interesado en el sitio confirmando que su cuenta ha sido creada y que podrá ingresar a una cuenta personalizada dentro del sitio, con la contraseña que “El Usuario” seleccionó con anterioridad.</p>

<p>Adicionalmente, a “Los Usuarios” que hayan creado una cuenta en el sitio <a href="http://www.labtak.mx">http://www.labtak.mx</a>, Inoma les enviará regularmente boletines o “newsletters” con herramientas, novedades e información relacionada a la educación y que le puedan ayudar en su labor docente.</p>

<p>Para los datos personales proporcionados a Inoma por “Los Usuarios”, dicha información podrá ser utilizada con fines estadísticos internos y para la elaboración de bases de datos. Dichos datos personales y bases de datos no serán compartidos, vendidos o arrendados por parte de Inoma hacia terceras personas.</p>

<p><strong>III.- Revocación de Consentimiento.</strong><br>
En todo momento, Usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales. Para este objeto, es necesario que presente su petición a través de un correo electrónico a <a href="mailto:contacto@inoma.mx">contacto@inoma.mx</a>. En su petición deberá solicitar expresamente la revocación del consentimiento que nos ha otorgado y deberá adjuntar identificación oficial con fotografía (credencial para votar, pasaporte vigente o cédula profesional). Una vez que Inoma haya recibido su solicitud, en un plazo máximo de 10 (diez) días atenderemos su solicitud y le confirmaremos la revocación a través del correo electrónico del que provenga su petición.</p>

<p><strong>IV.- Medios de contacto con los titulares.</strong><br>
Los medios de contacto que utilizaremos para las finalidades descritas en el presente aviso son: a través del correo electrónico de Inoma: <a href="mailto:contacto@inoma.mx">contacto@inoma.mx</a> y <a href="mailto:labtak@inoma.mx">labtak@inoma.mx</a>.</p>

<p><strong>V.- Transferencias de Información.</strong><br>
Le informamos que sus datos personales podrán ser compartidos con las siguientes empresas distintas a nosotros, para los siguientes fines:</p>
<ul>
  <li>Destinatario de los datos personales: Servicios informáticos externos</li>
  <li>Finalidad: Soporte técnico</li>
</ul>

<p><strong>VI.- Uso de tecnología en Internet.</strong><br>
Le informamos que en nuestro sitio web utilizamos cookies, rastreadores GIF, etiquetas de píxel y Google Analytics, a través de las cuales es posible monitorear su comportamiento como usuario de Internet, así como que cada usuario no tenga que iniciar su sesión individual cada vez que entra en el sitio.</p>

<p>Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: Dirección IP o http cookie, el sistema operativo del usuario, URL, horario y tiempo de navegación, Hit, página, archivo, visita, host y navegador.</p>

<p><strong>VII.- Medios y procedimiento para ejercer los derechos ARCO (Acceso, Rectificación, Cancelación y Oposición) y para revocar el consentimiento del tratamiento de datos personales.</strong><br>
Los datos personales proporcionados por “los Maestros”, formarán parte de un archivo físico y/o electrónico que contendrá su información. “los Maestros” podrán acceder o modificar su información en cualquier momento, para tal efecto deberá enviar un correo electrónico a: <a href="mailto:contacto@inoma.mx">contacto@inoma.mx</a></p>

<p><strong>VIII.- Datos Personales Sensibles.</strong><br>
Inoma no solicita datos personales sensibles a través de su sitio web <a href="http://www.labtak.mx">http://www.labtak.mx</a></p>

<p><strong>IX.- Cambios al Aviso de Privacidad.</strong><br>
Cualquier modificación al presente aviso de privacidad, se encontrará disponible en la sección de Aviso de Privacidad en este sitio web: <a href="http://www.labtak.mx">http://www.labtak.mx</a></p>

<p><strong>X.- Fecha de Actualización.</strong><br>
Este Aviso de Privacidad se actualizó el 22 de agosto de 2022.</p>

<p>Estas medidas operan en el marco de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>

<p>Atentamente,<br>
Innova y Moderniza Tu Aprendizaje, A.C.</p>
`;

@Component({
  selector: "app-avisoprivacidad",
  templateUrl: "./avisoprivacidad.component.html",
  styleUrls: ["./avisoprivacidad.component.css"],
})

export class AvisoprivacidadComponent implements OnInit {
  // TODO: Pasar esto a BD o a un json
  @Input() textoCompleto: string = textoAviso;
  @Input() maxCaracteres: number = 1500;

  mostrarTodo: boolean = false;
  get textoVisible(): string {
    return this.mostrarTodo
      ? this.textoCompleto
      : this.textoCompleto.substring(0, this.maxCaracteres) + "...";
  }

  toggleMostrarTodo(): void {
    this.mostrarTodo = !this.mostrarTodo;
  }

  constructor() {}

  ngOnInit() {}
}
