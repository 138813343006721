import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(function () {
      // Second level
      $(".js-second-level").on('click', function (e) {
        e.preventDefault();
        $(this).next("ol").toggleClass("active");
        $(this).toggleClass("active");
      });
      //--- Open
      $("#open").click(function (e) {
        e.preventDefault();
        $(this).toggleClass("active");
        $(".menu").toggleClass("active");
      });
    }, 1800);
  }

}
