
import { Component, OnInit, Input, } from '@angular/core';
import { GameService } from '../service/game.service';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { GruposComponent } from '../grupos/grupos.component';
import { DetalleService } from '../service/detalle.service';
import { CrearPlanes } from '../models/planes';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  private token: string = localStorage.getItem('token');
  
  public planes = new CrearPlanes();

  public nombrePlan = "";
  public descripcion: string;
  private id_plan: string;
  
  public descripciones: any[] = [];

  public info;
  
  public editar = [];

  public search: string;

  @Input() params: string;

  constructor(private gameService: GameService, private router: Router, private detalleService: DetalleService) {

  }

  ngOnInit() {
    $(".modal .close").click(function () {
      $('.modal').fadeOut();
      $('.modal').removeClass('show');
      setTimeout(function () {
        // Medium
        $(".js-modal-group").on('click', function (e) {
          e.preventDefault();
          $(".modal--medium").modal("show");
        });
      }, 1000);
    });
    
    for (let index = 0; index <4; index++) {
      this.editar[index] = false;
    }

  }
  refresh() {
    location.reload();
  }

  
  isEditable(value){
    (this.editar[value]) ? this.editar[value] = false :  this.editar[value] = true; 
  }

  
  crear() {
    this.planes.busc = 1;
    this.gameService.crearPlan(this.token, this.planes).subscribe((response:any)=>{

      this.id_plan = response["message"];

      Swal.fire({
        type: 'success',
        title: 'Planeación creada',
        showConfirmButton: false,
        timer: 2000
      });
      this.router.navigate(["/buscador", this.id_plan]); 
      
    

    },error => {
      
      Swal.fire({
        type: 'error',
        title: '¡Algo falló! \n Intenta de nuevo',
        showConfirmButton: false,
        timer: 2000
      });
    });

  }



}
