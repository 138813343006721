import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DetalleService } from '../service/detalle.service';
import { Navegacion } from '../models/navigate';
import InfoPlanesLT from 'src/assets/JSON/descripcion.json';
import { GameService } from '../service/game.service';
import { CrearPlanes, NuevoPlan, ModeloEducativo, Sesiones } from '../models/planes';
import { UserService } from '../service/user.service';
declare var particlesJS: any;
declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    public newPlan = new CrearPlanes();
    public nuevoplan = new NuevoPlan();
    public modEduplan = new ModeloEducativo();
    public id_plan;

    public is_edit = [];
    public editar = [];
    public planes = [];
    public first;
    public planesLT = [];
    public letra = [];
    private param = "undefined";
    private token = localStorage.getItem('token');
    public infoplanesLT;
    public navegacion = new  Navegacion();

    public ejeambito: boolean; //0=ambito 1=eje
    Arr = Array;
    public numberSesiones = Array();
    public sesiones:number = 0;
    public numbersesion:number = 0;
    public arraySesiones = [];

    public modeloEducativo = [];
    public modeloEducativoaux = [];
    public asignaturas = [];
    public asignaturasaux = [];
    public formacion = [];
    public formacionaux = [];
    public eje = [];
    public ejeaux = [];
    public temas = [];
    public temasaux = [];
    public aprendizajesaux = [];
    public aprendizajes = [];
    public aprenselected = [];

    public rmateriasall = [];
    public rmateriasgrado = [];
    public rmaterias = [];
    public rmodeloeducativo = [];
    public raprendizajesesp = [];
    public rcompetencias = [];
    public raprendizajesespaux = [];
    public rcompetenciasaux = [];
    public rbloque = [];
    public rrefmost = [];
    public raprenselected = [];
    public rcompetselected = [];
    public rejetem = [];
    public rttx = [];
    public rpsl = [];
    public rtemas = [];
    public rlengart = [];
    public rmodselected = [];

    public rvs = [{partes: 'Selecciona la parte que corresponde la planeación', contenido: 'Selecciona el contenido que corresponde la planeación', sesion: '', objetivo: ''}];
    public rvspartes = []
    public rvscontenido = [];
    public rvscontenidoaux = [];
    public rvssesion = [];
    public rvsobjetivo = [];
    public rvssesobjetivo = [];
    public rvsvidasaludable = [];

    public idReformaOnce;
    public bl = 0;
    public activar = 0;
    public idBloque;
    public idAmbitoEje;
    public idTemas;
    public idLengArt;
    public vidasal = 0;

    public select = 0;
    public reforma = -1;
    public mostrar = 0;
    public noeditar = 0;

    public grado_bus = 0;
    public materia_bus = 0;
    
    public ccts;
    private idGrado;
    private idFormacion;
    private idAsignatura;
    private idEje;
    private idTema;
    private idModEdu;

    public grupos;
    public gruposModal;
    public gruposSelected = [];
    public todosGrupos = false;

    Grados: Array<any> = [
        { grado: 'Primero de primaria', id: 1 },
        { grado: 'Segundo de primaria', id: 2 },
        { grado: 'Tercero de primaria', id: 3 },
        { grado: 'Cuarto de primaria', id: 4 },
        { grado: 'Quinto de primaria', id: 5 },
        { grado: 'Sexto de primaria', id: 6 },
      ];
      dropdownSettings = {};

    private sections: any;

    constructor(private gameService: GameService,private detalleService: DetalleService, private router: Router, private userService: UserService) {
        this.infoplanesLT = InfoPlanesLT.info;
      // //console.log(this.infoplanesLT);
        this.navegacion.seccion = 6;
        this.detalleService.navegacion(this.token,this.navegacion).subscribe();

        this.detalleService.grupos(this.token).subscribe((response: any) => {
            this.grupos = response.grupos;
            this.gruposModal = this.grupos.slice();

            this.gruposModal.forEach(element => {
              element.status = false;
            });

        });

        this.ngOnInit();
    }

    ngOnInit() {
        let top = document.getElementById('top');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }
        
      this.sections = this.userService.getSecciones();

        for (let index = 0; index <20; index++) {
            this.is_edit[index] = false;
        }
        for (let index = 0; index <20; index++) {
            this.editar[index] = false;
        }
        
        this.detalleService.detallePerfil(this.token).subscribe((response:any)=>{
            this.ccts = response.cct_info;
        });

       this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };

       
    setTimeout(function () {
        // Medium
        $(".js-modal-group-2").click(function (e) {
          e.preventDefault();
          $(".modal--medium2").modal("show");
        });
        //--- Carousel
        $(".modal .close").click(function () {
          $('.modal').fadeOut();
          $('.modal').removeClass('show');
        });
      }, 1500);
    }

    opvincula(){
        $(".modal--medium2").modal("show");
    }

    slugify(str) {
        str = str.replace(/^\s+|\s+$/g, '');

        str = str.toLowerCase();

        var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
        var to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

        return str;
    }

    isDisabled(value) {
        (this.is_edit[value]) ? this.is_edit[value] = false :  this.is_edit[value] = true; 
    }
    isEditable(value){
        (this.editar[value]) ? this.editar[value] = false :  this.editar[value] = true; 
    }

    changeReforma:any = (e) => {
        if(this.reforma==0){
            this.select = 1; 
            this.aprenselected = [];
            this.nuevoplan.grado = null;
            this.nuevoplan.reforma= 'libre';
            this.ejeambito = true;   
            this.nuevoplan.grado = null;
        }
        else if(this.reforma==1){
            this.aprenselected = [];
            this.nuevoplan.grado = null;
            this.nuevoplan.reforma= '2011';
            this.detalleService.getMateriaGrado(this.token).subscribe((response: any) => {
                this.rmateriasgrado = response.grado_mat;
                this.rmateriasall = response.materias;
            });
        }
        else if(this.reforma==2){
            this.select = 0; 
            this.aprenselected = [];
            this.nuevoplan.grado = null;
            this.nuevoplan.reforma= '2017';
            this.nuevoplan.grado = null;
            this.detalleService.getModedu(this.token).subscribe((response: any) => {
                //console.log(response);
                this.modeloEducativo = response.modedu;
                this.formacion = response.formacion;
                this.asignaturas = response.asignaturas;
                this.eje = response.eje_amb;
                this.temas = response.temas;
                this.aprendizajes = response.aprendizajes;
            });
            this.ejeambito = true;    
        }
        else if(this.reforma==3){
            this.nuevoplan.reforma= '2020';
        }
    }

    /* REFORMA 2017 */
    changeFormacion:any = (e) => {
        this.modeloEducativoaux.splice(0);
        this.formacionaux.splice(0);
        this.formacionaux = [];
        this.asignaturasaux.splice(0);
        this.ejeaux.splice(0);
        this.temasaux.splice(0);
        this.aprendizajesaux.splice(0);
        //this.modeloEducativoaux = this.modeloEducativo.slice();
        this.idGrado = e.target.value;
        this.grado_bus = this.idGrado;
        this.modeloEducativo.forEach((modedu, index) => {
            if(this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level){
                this.modeloEducativoaux.push(modedu);
            }
        });
        this.formacionaux = this.formacion.slice();
    }

    changeAsig:any = (e) => {
        this.asignaturasaux.splice(0);
        this.ejeaux.splice(0);
        this.temasaux.splice(0);
        this.aprendizajesaux.splice(0);
        this.idFormacion = e.target.value;
        if(this.idFormacion == 0) {this.select = 1; this.aprenselected = [] }
        else{
            this.select = 0;
            this.asignaturasaux = this.asignaturas.slice();

            this.modeloEducativo.forEach(modedu => {
                if(this.idFormacion != modedu.idformacion){
                    this.asignaturasaux.forEach((asi, index,object) => {
                        if(asi.asignatura == modedu.asignatura){
                            object.splice(index,1);
                        }
                    });
                }
                else {
                    if((this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level) == false) {
                        this.asignaturasaux.forEach((asi, index,object) => {
                            if(asi.asignatura == modedu.asignatura){
                                object.splice(index,1);
                            }
                        });
                    }
                }
            });
        }
    }

    changeEje:any = (e) =>{
        this.ejeaux.splice(0);
        this.temasaux.splice(0);
        this.aprendizajesaux.splice(0);
        this.idAsignatura = e.target.value;
        this.ejeaux = this.eje.slice();

        this.modeloEducativo.forEach(modedu => {
            if(this.idAsignatura != modedu.idasignatura){
                this.ejeaux.forEach(function(eje, index, object) {
                    if(eje.descripcion == modedu.descripcion){
                        object.splice(index,1);
                    } 
                });
            }
            else {
                if((this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level) == false) {
                    this.ejeaux.forEach(function(eje, index, object) {
                        if(eje.descripcion == modedu.descripcion){
                            object.splice(index,1);
                        } 
                    });
                }
            }
        });
        if(this.ejeaux[0].ambito_eje == "0") this.ejeambito = false;
        else this.ejeambito = true;

        if(this.idAsignatura==1) this.materia_bus = 10; // Lenguaje y comunicacion
        else if(this.idAsignatura==2) this.materia_bus = 5; //matematicas
        else if(this.idAsignatura==3) this.materia_bus = 6; //Ecologia
        else if(this.idAsignatura==4) this.materia_bus = 13; //Civica y etica
        else if(this.idAsignatura==5) this.materia_bus = 3; //Fisica
        else if(this.idAsignatura==6) this.materia_bus = 2; //Historia
        else if(this.idAsignatura==7) this.materia_bus = 12; //Geografia
        else if(this.idAsignatura==8) this.materia_bus = 13; //Civica y etica
        else if(this.idAsignatura==9) this.materia_bus = 4; //Artes
        else if(this.idAsignatura==10) this.materia_bus = 8; //Ed. socioemocional
    }

    changeTema:any = (e) => {
        this.temasaux.splice(0);
        this.aprendizajesaux.splice(0);
        this.idEje = e.target.value;
        this.temasaux = this.temas.slice();
        this.modeloEducativo.forEach(modedu => {
            if(this.idEje != modedu.ideje){
                this.temasaux.forEach(function(tema, index, object) {
                    if(tema.practicas_temas == modedu.practicas_temas){
                        object.splice(index,1);
                    } 
                });
            }
            else {
                if((this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level) == false) {
                    this.temasaux.forEach((tema, index, object) => {
                        if(tema.practicas_temas == modedu.practicas_temas){
                            object.splice(index,1);
                        } 
                    });
                }
            }
        });
    }

    changeAprendizajes:any = (e) => {
        this.aprendizajesaux.splice(0);
        this.idTema = e.target.value;
        this.modeloEducativo.forEach(modedu => {
            if(this.idTema == modedu.idtemas) this.idModEdu = modedu.idModEdu;
        });

        this.aprendizajes.forEach(apren => {
            if(apren.modelo_educativo == this.idModEdu){
                if(apren.grado == this.idGrado){
                    this.aprendizajesaux.push(apren);
                }
            }
        });
    }


    /* REFORMA 2011 */
    changeMaterias:any = (e) => {
        this.rmaterias.slice();
        this.rmaterias = [];
        this.bl=0;
        this.activar=0;
        this.vidasal=0;
        this.rejetem =[];
        this.raprenselected = [];
        this.rcompetselected = [];
        this.rtemas = [];
        this.idGrado = e.target.value;
        this.grado_bus = this.idGrado;

        this.rmateriasgrado.forEach(element => {
            if(element.grado == this.idGrado){
                this.rmaterias.push(element);
            }
        });
    }

    obtenerBloque:any = (e) => {
        this.idAsignatura = e.target.value;
        this.raprenselected = [];
        this.rcompetselected = [];
        this.rejetem =[];
        this.rtemas = [];
        this.activar=0;
        
        this.detalleService.getReformaxGrado(this.token, this.idGrado, this.idAsignatura).subscribe((response: any) => {
            this.rbloque = [];
            if(this.idAsignatura!=19){
                this.rmodeloeducativo = response.reforma;
                this.raprendizajesesp = response.aprendizajes;
                this.rcompetencias = response.competencias;

                if(this.idAsignatura== 2 || this.idAsignatura == 11 || this.idAsignatura==15){
                    this.rbloque = [
                        { bloque: 'Bloque 1', id: 1 },
                        { bloque: 'Bloque 2', id: 2 },
                        { bloque: 'Bloque 3', id: 3 },
                        { bloque: 'Bloque 4', id: 4 },
                        { bloque: 'Bloque 5', id: 5 },
                    ];
                }
                else{
                    response.reforma.forEach((element, index) => {
                        let b = 'Bloque ' + element.bloque + ' - ' + element.nombre_bloque;
                        if(index==0) this.rbloque.push({bloque: b, id: element.bloque});
                        else{
                            let i = this.rbloque.length;
                            if(element.bloque != this.rbloque[i-1].id) this.rbloque.push({bloque: b, id: element.bloque});
                        }
                    });
                }
                this.bl =1;
            }
            else{ //Vida Saludable
                this.bl =0;
                this.rvspartes= response.reforma.partes;
                this.rvscontenidoaux = response.reforma.contenido;
                this.rvssesion = response.reforma.sesion;
                this.rvssesobjetivo = response.reforma.sesobj;
                this.rvsvidasaludable = response.reforma.vida_saludable;
                this.vidasal =1;
            }
        });

        if(this.idAsignatura==2) this.materia_bus = 5; //Matematicas
        else if(this.idAsignatura==11) this.materia_bus = 10; // Lenguaje y comunicacion
        else if(this.idAsignatura==12) this.materia_bus = 6; //Ecologia
        else if(this.idAsignatura==13) this.materia_bus = 3; //Ciencias Naturales
        else if(this.idAsignatura==14) this.materia_bus = 13; //Formacion civica y etica
        else if(this.idAsignatura==15) this.materia_bus = 4; //Artes
        else if(this.idAsignatura==16) this.materia_bus = 13; //Civica y etica
        else if(this.idAsignatura==17) this.materia_bus = 12; //Geografia
        else if(this.idAsignatura==18) this.materia_bus = 2; //Historia
        else if(this.idAsignatura==19) this.materia_bus = 1; //Vida Saludable
    }

    obtenerReforma:any = (e) => {

        this.idBloque= e.target.value;
        this.activar = 1;
        this.rrefmost = [];
        this.rcompetenciasaux = [];
        this.raprendizajesespaux = [];
        this.rejetem =[];
        this.rtemas = [];
        this.rlengart = [];

        if(this.idAsignatura==12 || this.idAsignatura==13 || this.idAsignatura==14 || 
            this.idAsignatura==16 || this.idAsignatura==17 || this.idAsignatura==18 ){

            this.rmodeloeducativo.forEach(md => {
                if(md.bloque == this.idBloque)
                    this.rrefmost.push(md);
            }); 
            this.rcompetencias.forEach(c  => {
                if(c.mod_edu_2011 == this.rrefmost[0].id) this.rcompetenciasaux.push(c);
            });
            this.raprendizajesesp.forEach(ae  => {
                if(ae.mod_edu_2011 == this.rrefmost[0].id) this.raprendizajesespaux.push(ae);
            });

            this.idReformaOnce = this.rrefmost[0].id; // ID DE LA REFORMA

            if(this.idAsignatura==14 || this.idAsignatura==17 ){
                this.rrefmost.forEach(r => { //Eje Tematico
                    this.rejetem.push({eje_ambito: r.eje_ambito, descripcion: r.descripcion});
                });
            }
        }

        else if(this.idAsignatura==11 ){ //ESPAÑOL
            this.rmodeloeducativo.forEach(md => {
                if(md.bloque == this.idBloque)
                    this.rrefmost.push(md);
            });
            this.rrefmost.forEach(r => { //Ambito
                this.rejetem.push({eje_ambito: r.eje_ambito, descripcion: r.descripcion});
            }); 
        }

        else if(this.idAsignatura==2){ //Mate 
            this.rmodeloeducativo.forEach(md => {
                if(md.bloque == this.idBloque)
                    this.rrefmost.push(md);
            });
            //console.log(this.rrefmost);
            this.rrefmost.forEach((r,index) => { //Eje
                if(index == 0) this.rejetem.push({eje_ambito: r.eje_ambito, descripcion: r.descripcion});
                else{
                    let i = this.rejetem.length;
                    if(r.eje_ambito != this.rejetem[i-1].eje_ambito) this.rejetem.push({eje_ambito: r.eje_ambito, descripcion: r.descripcion});
                }
            }); 
        }
        
        else if(this.idAsignatura==15){ //Ed.Art.
            this.rmodeloeducativo.forEach(md => {
                if(md.bloque == this.idBloque)
                    this.rrefmost.push(md);
            });
            //console.log(this.rrefmost);
            this.rrefmost.forEach((r,index) => { //Leng.art
                if(index == 0) this.rlengart.push({leng_artistico: r.leng_artistico, lenguaje_artistico: r.lenguaje_artistico});
                else{
                    let i = this.rlengart.length;
                    if(r.leng_artistico != this.rlengart[i-1].leng_artistico)
                        this.rlengart.push({leng_artistico: r.leng_artistico, lenguaje_artistico: r.lenguaje_artistico});
                }
            }); 
        }
    }

    changetdt:any = (e) => {
        this.idAmbitoEje = e.target.value;
        this.rttx = [];
        this.rpsl = [];
        //console.log(this.idAmbitoEje);
        if(this.idAsignatura==11){ //ESPAÑOL
            this.raprendizajesespaux = [];
            this.rcompetenciasaux = [];
            this.rrefmost.forEach((rr, index) => {
                if(rr.eje_ambito == this.idAmbitoEje) {
                    this.rttx.push({ttid: rr.ttid, tipo_texto: rr.tipo_texto});
                    this.rpsl.push({pract_social: rr.pract_social, practica_social_lenguaje: rr.practica_social_lenguaje })
                    this.rcompetencias.forEach(c  => {
                        if(c.mod_edu_2011 == rr.id) this.rcompetenciasaux.push(c);
                    });
                    this.raprendizajesesp.forEach(ae  => {
                        if(ae.mod_edu_2011 == rr.id) this.raprendizajesespaux.push(ae);
                    });
                    this.idReformaOnce = rr.id;
                }
            });
            //console.log(this.rrefmost);
        }
        else if(this.idAsignatura==14){ //FCYE
            this.rrefmost.forEach(rr => {
                if(rr.eje_ambito == this.idAmbitoEje) {
                    this.idReformaOnce = rr.id;
                }
            });
        }
        
    }

    changeTem:any = (e) => {
        this.rtemas = [];
        this.idAmbitoEje = e.target.value;

        if(this.idAsignatura==2){ //Matematicas
            this.rrefmost.forEach((rr, index) => {
                if(rr.eje_ambito == this.idAmbitoEje) { 
                    this.rtemas.push(rr);
                }
            });
        }

        else if(this.idAsignatura==15){ //Ed.Art
            this.rrefmost.forEach((rr, index) => {
                if(rr.leng_artistico == this.idLengArt && rr.eje_ambito == this.idAmbitoEje) { 
                    this.rtemas.push(rr);
                }
            });
        }
        
        //console.log(this.rtemas);
    }

    changeLeng:any = (e) => {
        this.rtemas = [];
        this.rejetem = [];
        
        this.rcompetenciasaux = [];
        this.raprendizajesespaux = [];
        
        this.idLengArt = e.target.value;

        if(this.idAsignatura==15){ //Ed.Art
            this.rrefmost.forEach((rr, index) => {
                if(rr.leng_artistico == this.idLengArt) { 
                    this.rejetem.push(rr);
                }
            });
        }
        
    }

    changeMate:any = (e) => {
        
        this.rcompetenciasaux = [];
        this.raprendizajesespaux = [];
        this.idTemas = e.target.value;

        //console.log('this.rtemas',this.rtemas);
        //console.log('this.rrefmost',this.rrefmost);

        if(this.idAsignatura==2){
            this.rrefmost.forEach((rr, index) => {
                if(rr.tema == this.idTemas) {
                    this.rcompetencias.forEach(c  => {
                        if(c.mod_edu_2011 == rr.id) this.rcompetenciasaux.push(c);
                    });
                    this.raprendizajesesp.forEach(ae  => {
                        if(ae.mod_edu_2011 == rr.id) this.raprendizajesespaux.push(ae);
                    });
                    this.idReformaOnce = rr.id;
                }
            });
        }
        else if(this.idAsignatura==15){
        
            this.rrefmost.forEach((rr, index) => {
                if(rr.tema == this.idTemas && rr.eje_ambito == this.idAmbitoEje) {
                    this.rcompetencias.forEach(c  => {
                        if(c.mod_edu_2011 == rr.id) this.rcompetenciasaux.push(c);
                    });
                    this.raprendizajesesp.forEach(ae  => {
                        if(ae.mod_edu_2011 == rr.id) this.raprendizajesespaux.push(ae);
                    });
                    this.idReformaOnce = rr.id;
                }
            });
        }
        //console.log(this.idReformaOnce);
    }

    changeContenidovs:any = (e) => {
        this.rvscontenido = [];
        this.rvs[0].partes = e.target.value;
        this.rvsvidasaludable.forEach(vs => {
            if(this.rvs[0].partes == vs.partes){
                if(this.rvscontenido.length==0) this.rvscontenido.push({contenido: vs.contenido});
            }
        });
    }

    changeObjetivosvs:any = (e) => {
        this.rvs[0].sesion = e.target.value;
        this.rvsobjetivo = [];
        this.rvssesobjetivo.forEach(element => {
            if(this.rvs[0].sesion == element.sesion) this.rvsobjetivo.push(element);
        });
    }

    selectedVidaSaludable:any = (e) => {
        this.rvs[0].objetivo = e.target.value;
        this.rvsvidasaludable.forEach(v => {
            if((v.partes == this.rvs[0].partes) && (v.contenido == this.rvs[0].contenido) 
                &&(v.sesion == this.rvs[0].sesion) && (v.objetivos == this.rvs[0].objetivo)){
                this.modEduplan.vida_saludable = v.id;
            }
        });
        this.activar=1;
    }

    crear2011(){

        this.revisarDes();
        this.modEduplan.id_modelo_educativo = null; 
        this.modEduplan.aprendizajes_esperados= null;
        this.modEduplan.asignatura= null;
        this.modEduplan.eje= null;
        this.modEduplan.formacion= null;
        this.modEduplan.tema= null;
        this.modEduplan.id_aprendizajes=null

        if(this.idAsignatura==19){
            this.modEduplan.id_mod_edu_2011= null;
            this.modEduplan.id_apren_2011 = null;
            this.modEduplan.id_comp_2011 = null;
            this.mostrar = 1;
            this.noeditar = 1;
            //console.log(this.modEduplan);
        }
        else{
            this.modEduplan.vida_saludable=null;
            this.rrefmost.forEach(element => {
                if(element.id == this.idReformaOnce) this.rmodselected.push(element);
            });
            this.modEduplan.id_mod_edu_2011 = this.idReformaOnce;

            let allapren = '';
            let allcompt = '';
            this.raprenselected.forEach(element => {
                if(allapren=='') allapren = element.idapren;
                else allapren = allapren + ',' + element.idapren;
             }); 
            this.rcompetselected.forEach(element => {
                if(allcompt=='') allcompt = element.idcomp;
                else allcompt = allcompt + ',' + element.idcomp;
             });
            this.modEduplan.id_apren_2011 = allapren;
            this.modEduplan.id_comp_2011 = allcompt;
        }

        //API
        this.gameService.crearPlan(this.token, this.newPlan).subscribe((response:any)=>{
    
            this.id_plan = response["message"];
            this.detalleService.editarNuevoPlan(this.token,this.modEduplan,this.id_plan).subscribe((response:any)=>{});
            this.detalleService.editarNuevoPlan(this.token,this.nuevoplan,this.id_plan).subscribe((response:any)=>{});
            this.mostrar = 1;
            this.noeditar = 1;
            Swal.fire({
              type: 'success',
              title: 'Planeación creada.',
              showConfirmButton: false,
              timer: 2000
            });
    
            },error => {
              
              Swal.fire({
                type: 'error',
                title: '¡Algo falló! \n Intenta de nuevo',
                showConfirmButton: false,
                timer: 2000
              });
            });
    
            let top1 = document.getElementById('top-1');
            if (top1 !== null) {
                top1.scrollIntoView();
                top1 = null;
            }
    }


    
   crear() {
    //  this.noeditar = 1;
    //  this.mostrar = 1;
    this.modEduplan.id_mod_edu_2011 = null; 
    this.modEduplan.id_apren_2011 = null;
    this.modEduplan.id_comp_2011 = null;
    this.modEduplan.vida_saludable = null;
    this.revisarDes();
    if(this.select==1) { this.modEduplan.id_modelo_educativo = null; this.modEduplan.id_aprendizajes=null; }
    else {
        this.modEduplan.id_modelo_educativo = this.idModEdu; 
        this.modEduplan.aprendizajes_esperados= null;
        this.modEduplan.asignatura= null;
        this.modEduplan.eje= null;
        this.modEduplan.formacion= null;
        this.modEduplan.tema= null;
    }
    
    this.gameService.crearPlan(this.token, this.newPlan).subscribe((response:any)=>{
    
        this.id_plan = response["message"];

        if(this.select==0){
            let allapren = '';
            this.aprenselected.forEach(element => {
                if(allapren=='') allapren = element.id;
                else allapren = allapren + ',' + element.id;
             });
            this.modEduplan.id_aprendizajes = allapren;
            this.detalleService.editarNuevoPlan(this.token,this.modEduplan,this.id_plan).subscribe((response:any)=>{});
        }
        else this.detalleService.editarNuevoPlan(this.token,this.modEduplan,this.id_plan).subscribe((response:any)=>{});
        
        this.detalleService.editarNuevoPlan(this.token,this.nuevoplan,this.id_plan).subscribe((response:any)=>{});
        this.mostrar = 1;
        this.noeditar = 1;
        Swal.fire({
          type: 'success',
          title: 'Planeación creada.',
          showConfirmButton: false,
          timer: 2000
        });

        },error => {
          Swal.fire({
            type: 'error',
            title: '¡Algo falló! \n Intenta de nuevo',
            showConfirmButton: false,
            timer: 2000
          });
        });

        let top1 = document.getElementById('top-1');
        if (top1 !== null) {
            top1.scrollIntoView();
            top1 = null;
        }
  }

  crear1(){ //Escuelas
      this.mostrar=2;
      this.nuevoplan.sesiones = this.numbersesion;
       this.revisarDes();
       this.detalleService.editarNuevoPlan(this.token,this.nuevoplan,this.id_plan).subscribe((response:any)=>{
        Swal.fire({
            type: 'success',
            title: 'Planeación guardada.',
            showConfirmButton: false,
            timer: 2000
          });
       });

       let top2 = document.getElementById('top-2');
        if (top2 !== null) {
            top2.scrollIntoView();
            top2 = null;
        }
  }

  crear2(){ // sesiones
    this.mostrar=3;
    //console.log(this.arraySesiones.length);
    //console.log(this.sesiones);
    let ns = 0;
    this.numberSesiones.forEach(n => {
        console.log(n);
        if(n.status==1) ns+=1;
    });
    
    let aceptar = 0;
    if(this.arraySesiones.filter(Boolean).length != ns) {
        Swal.fire({
            type: 'error',
            title: 'No has guardado todas las sesiones. \nIntenta de nuevo.',
            showConfirmButton: false,
            timer: 3000
            });
        aceptar++;
    }
    else{
        this.arraySesiones.forEach(ses => {
            //console.log(ses);
            this.detalleService.editarNuevoPlan(this.token,ses,this.id_plan).subscribe((response:any)=>{
                Swal.fire({
                    type: 'success',
                    title: 'Planeación guardada.',
                    showConfirmButton: false,
                    timer: 2000
                  });
            });
        });
        let top3 = document.getElementById('top-3');
        if (top3 !== null) {
            top3.scrollIntoView();
            top3 = null;
        }
    }
  }

  crear3(){ //Grupo y director
    this.mostrar=4;
      this.revisarDes();
      this.detalleService.editarNuevoPlan(this.token,this.nuevoplan,this.id_plan).subscribe((response:any)=>{
        Swal.fire({
            type: 'success',
            title: 'Planeación guardada.',
            showConfirmButton: false,
            timer: 2000
          });
      });
      
      this.gruposSelected.forEach(grupo => {
        this.detalleService.vincularGrupo(this.token, this.id_plan, grupo.id).subscribe();
      });

      this.router.navigate(["/mis-planes"]); 
  }

  revisarDes(){
      if(this.is_edit[0])  this.nuevoplan.cct = 0;
      if(this.is_edit[1])  this.nuevoplan.area = '/';
      if(this.is_edit[12])  this.nuevoplan.duracion = '/';
      if(this.is_edit[3])  this.nuevoplan.semana = '/';
      if(this.is_edit[7])  this.nuevoplan.aprendizajes_clave = '/';
      if(this.is_edit[8])  this.nuevoplan.propositos_pedagogicos = '/';
      if(this.is_edit[10])  this.nuevoplan.contexto = '/';
      if(this.is_edit[15])  this.nuevoplan.bloque = '/';
      if(this.is_edit[13])  this.nuevoplan.director = '/';
      if(this.is_edit[14])  this.nuevoplan.fecha = '/';
      if(this.is_edit[2]) this.nuevoplan.ciclo = '/';
      
      if(this.select==1) {
        if(this.is_edit[16])  this.modEduplan.formacion = '/';
        if(this.is_edit[4])  this.modEduplan.asignatura = '/';
        if(this.is_edit[5])  this.modEduplan.eje = '/';
        if(this.is_edit[6])  this.modEduplan.tema = '/';
        if(this.is_edit[9])  this.modEduplan.aprendizajes_esperados = '/';
      }
  }


  changeAll() {
    if(this.todosGrupos) this.todosGrupos = false;
    else{
      this.todosGrupos = true;
      this.gruposModal.forEach(element => {
        if(!element.status){ 
          element.status=true;
          this.gruposSelected.push(element);
          //this.vincularGrupo(element.id);
        }
      });
    }
  }

  changeStatus(index){
      if(this.gruposModal[index].status) this.gruposModal[index].status = false;
      else this.gruposModal[index].status = true;
  }

  vincularGrupos(){
      if(this.gruposSelected.length<1) {
        this.gruposModal.forEach(grupomodal => {
            if(grupomodal.status) this.gruposSelected.push(grupomodal);
        });
      }

  }

  recibirSesiones(sesion){
    let ses = new Sesiones();
    ses = sesion;
    this.arraySesiones[ses.numero-1] = ses;
  }

  irBuscador(){
      if(this.id_plan==undefined || this.id_plan==null) this.router.navigate(["/buscador"]); 
      else this.router.navigate(["/buscador", this.id_plan]); 
  }

  changeCct:any = (e) => {
    let cctselected = e.target.value;
    this.ccts.forEach(cct => {
        if(cctselected == cct.id) this.nuevoplan.institucion = cct.centro_educativo;
    });
  }

  addAprendizajes:any = (e, aprendizaje) =>{
      if(e.checked == true) this.aprenselected.push(aprendizaje);
      else {
        this.aprenselected.forEach((a,index) => {
            if(a.id == aprendizaje.id) this.aprenselected.splice(index, 1);
        });
      }
  }
  addRAprendizajes:any = (e, aprendizaje) =>{
      //console.log(aprendizaje)
    if(e.checked == true) this.raprenselected.push(aprendizaje);
    else {
      this.raprenselected.forEach((a,index) => {
          if(a.idapren == aprendizaje.idapren) this.raprenselected.splice(index, 1);
      });
    }
    }
    addCompt:any = (e, compt) =>{
        //console.log(compt)
        if(e.checked == true) this.rcompetselected.push(compt);
        else {
          this.rcompetselected.forEach((a,index) => {
              if(a.idcomp == compt.idcomp) this.rcompetselected.splice(index, 1);
          });
        }
    }

    agregarSesionnueva(){
        this.sesiones = this.sesiones + 1;
        this.numberSesiones.push({id: this.sesiones, status: 1});
      }

    eliminarsesionNueva(numero){
        this.numberSesiones.forEach(ns => {
          if(ns.id == numero) ns.status = 0;
        });

        if(this.arraySesiones.length>0){
            this.arraySesiones.forEach((as,k) => {
               if(as.numero == numero) this.arraySesiones.splice(k,1);
            });
        }
      }
  

}