import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GameService } from '../service/game.service';
import { Sesiones } from '../models/planes';
import { imagenes } from '../service/config';

declare var $: any;

@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.css']
})
export class ActividadesComponent implements OnInit {
  
  @Input () sesion: number;
  @Input () games;
  @Input () recursos;
  @Input () libros;
  @Output () sesiones = new EventEmitter<Sesiones>();
  @Output () eliminar = new EventEmitter<Number>();
  
  private token: string = localStorage.getItem('token');

  public list2 = [];
  public list3 = [];
  public list4 = [];
  public urlImg: string = imagenes.url;

  public is_edit = [];
  public sesionenviar = new Sesiones();
  public material1 = '';
  public material2 = '';
  public material3 = '';
  public eval = '';
  
  Evaluacion: Array<any> = [
    { tag: 'Rúbrica', status: false },
    { tag: 'Producciones escritas', status: false },
    { tag: 'Portafolio', status: false },
    { tag: 'Lista de cotejo', status: false },
    { tag: 'Proyectos', status: false },
    { tag: 'Puertas escritas u orales', status: false },
    { tag: 'Anecdotario', status: false },
    { tag: 'Registro de actitudes', status: false },
    { tag: 'Observación directa', status: false },
    { tag: 'Esquemas', status: false },
  ];

  constructor(private gameService: GameService) { }

  ngOnInit() {
    for (let index = 0; index <8; index++) {
      this.is_edit[index] = false;
    }

    if(this.games!=undefined && this.recursos!=undefined && this.libros!=undefined){
      if(this.games.length>0 || this.recursos.length>0 || this.libros.length>0){
        this.games.forEach(game => {
          if(this.sesionenviar.juegos=="") this.sesionenviar.juegos = game.id_game;
          else this.sesionenviar.juegos = this.sesionenviar.juegos + ',' + game.id_game;
          this.list2.push(game);
        });
        this.recursos.forEach(recurso => {
          if(this.sesionenviar.otros_recursos=="") this.sesionenviar.otros_recursos = recurso.id_recurso;
          else this.sesionenviar.otros_recursos = this.sesionenviar.otros_recursos + ',' + recurso.id_recurso;
          this.list3.push(recurso);
        });
        this.libros.forEach(libro => {
          if(this.sesionenviar.libros=="") this.sesionenviar.libros = libro.id_libro;
          else this.sesionenviar.libros = this.sesionenviar.libros + ',' + libro.id_libro;
          this.list4.push(libro);
        });
      }
      else{
        this.rec();
      }
    }
    else{
      this.rec();
    }
  }

  rec(){
    let rd = document.getElementById('recursos-didacticos') as HTMLDivElement;
    $(rd).attr('style', 'height:100px')
  }

  isDisabled(value) {
    (this.is_edit[value]) ? this.is_edit[value] = false :  this.is_edit[value] = true; 
  }

  eliminarSesion(){
    this.eliminar.emit(this.sesion);
  }

  enviarSesion(){
    this.eval = '';
    this.sesionenviar.materiales_complementarios = this.material1 + ',' + this.material2 + ',' + this.material3;
    this.sesionenviar.numero = this.sesion;
    this.Evaluacion.forEach(element => {
      if(element.status) this.eval = this.eval + element.tag + ',';
    });
    this.sesionenviar.instrumentos_evaluacion = this.eval;
    this.revisarDes();
    this.sesiones.emit(this.sesionenviar);
  }

  revisarDes(){
    if(this.is_edit[0])  this.sesionenviar.materiales_complementarios = '/';
    if(this.is_edit[1])  this.sesionenviar.reflexion = '/';
    if(this.is_edit[2])  this.sesionenviar.activacion = '/';
    if(this.is_edit[3])  this.sesionenviar.problematizacion = '/';
    if(this.is_edit[4])  this.sesionenviar.profundizacion = '/';
    if(this.is_edit[6])  this.sesionenviar.criterios_evaluacion = '/';
    if(this.is_edit[7])  this.sesionenviar.evidencias = '/';
  }

  changeStatus(i){
    if(this.Evaluacion[i].status) this.Evaluacion[i].status = false;
    else this.Evaluacion[i].status = true;
  }

  eliminarJuego(idGame, i){
    let j = this.sesionenviar.juegos.split(',');
    let ind = j.indexOf( idGame.toString() );
    if ( ind != -1 ) {
      j.splice( ind, 1 );
      this.sesionenviar.juegos="";
      for (let index = 0; index < j.length; index++) {
        if(this.sesionenviar.juegos=="") this.sesionenviar.juegos = j[index];
        else this.sesionenviar.juegos = this.sesionenviar.juegos + ',' + j[index];
      }
    }

    this.list2.splice(i,1);
  }

  eliminarRecursosExternos(idRecurso, i) {
    let r = this.sesionenviar.otros_recursos.split(',');

    let ind = r.indexOf( idRecurso );
    if ( ind != -1 ) {
      r.splice( ind, 1 );
      this.sesionenviar.otros_recursos="";
      for (let index = 0; index < r.length; index++) {
        if(this.sesionenviar.otros_recursos=="") this.sesionenviar.otros_recursos = r[index];
        else this.sesionenviar.otros_recursos = this.sesionenviar.otros_recursos + ',' + r[index];
      }
    }
    this.list3.splice(i,1);
  }

  eliminarLibros(idLibro, i) {
    let l = this.sesionenviar.libros.split(',');
    let ind = l.indexOf( idLibro );
    if ( ind != -1 ) {
      l.splice( ind, 1 );
      this.sesionenviar.libros="";
      for (let index = 0; index < l.length; index++) {
        if(this.sesionenviar.libros=="") this.sesionenviar.libros = l[index];
        else this.sesionenviar.libros = this.sesionenviar.libros + ',' + l[index];
      }
    }
    this.list4.splice(i,1);
  }
  

}
