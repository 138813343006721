import { Component, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { NuevoPlanComponent } from '../nuevo-plan/nuevo-plan.component';
import { GameService } from '../service/game.service';

@Component({
  selector: 'app-modal-buscar',
  templateUrl: './modal-buscar.component.html',
  styleUrls: ['./modal-buscar.component.css']
})
export class ModalBuscarComponent implements OnInit {

  private token: string = localStorage.getItem('token');
  private tagsLocal: any;
  private gamesM: string[];
  public tagsFinales = "";

  @ViewChild(NuevoPlanComponent, { static: false }) nuevo: NuevoPlanComponent;

  constructor(private gameService: GameService) { }

  ngOnInit() {
  }

  ngAfterViewChecked() {

  }

  accionBuscar(tags?: any) {
    setTimeout(() => {

      if (tags != undefined || tags != null) {
        this.tagsFinales = tags;
      } else {
        this.tagsLocal = JSON.parse(localStorage.getItem('buscador'));

        this.tagsLocal.forEach(tag => {
          this.tagsFinales = this.tagsFinales + " " + tag.value;
        });
      }      

      this.gameService.setTags(this.token, this.tagsFinales).subscribe(
        data => {          
          this.gamesM = data["resultado"].games;                    
        }
      );

      localStorage.removeItem("buscador");
      this.tagsFinales = "";

    }, 500);    

  }

}
