import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reglas-blogy-foro',
  templateUrl: './reglas-blogy-foro.component.html',
  styleUrls: ['./reglas-blogy-foro.component.css']
})
export class ReglasBlogyForoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
