import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ReportakService } from '../service/reportak.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { DetalleService } from '../service/detalle.service';
import { Navegacion } from '../models/navigate';
import { Chart } from 'chart.js';

import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-reportak',
  templateUrl: './reportak.component.html',
  styleUrls: ['./reportak.component.css'],
})


export class ReportakComponent implements OnInit {

  @ViewChild('screen', {static: false}) screen: ElementRef;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;
  @ViewChild('graphs', {static: false}) graphs: ElementRef;
  @ViewChild('graph2', {static: false}) graph2: ElementRef;

  private lineChart = [];
  private token: string = localStorage.getItem('token');
  private userData: any = []; //modificado gabo
  private planes: any[];
  private grupos: any[];
  private games: any[];
  private accessLink: '';
  private alumnos: any[];
  private materias: any[];
  private periodos = {mensuales:[], quincenales:[]};
  private periodo: any[] = [];
  private periodoSelected:string;
  private mensual: boolean = true;

  private modalOptions:NgbModalOptions;
  public navegacion = new Navegacion();

  private pl:string;
  private gr:string;
  public p = 0;
  public camposSelected = 0;
  public planSelected ;

  //pdf
  private alumnosChart: any[];
  private pdfReportF: boolean;

  private onPlanChange:any = (e) => {
    //console.log("onPlanChange", e);
    this.planSelected = e.target.value;
    this.planes.forEach((item, index) => {
      if(item.id == e.target.value){
        this.pl = item.name;
        if(item.grupos.length > 0){
          this.grupos = item.grupos;
          this.games = item.games;
          this.gr = this.grupos[0].access_link.substring(this.accessLink.length-2);
          //console.log("item.grupos", item.grupos);
          this.getAlumnos(this.token, this.grupos[0].access_link);
          // this.getReporte(this.token, this.grupos[0].access_link, this.alumnos, this.periodoSelected, this.games);
          // this.token, grupo, this.alumnos, this.periodo[0].date_start
        }else{
          Swal.fire(" ","Este plan no tiene grupos asignados", "error");
        }
      }
    });
  }

  private onGrupoChange:any = (e) => {
    this.getAlumnos(this.token, e.target.value);
    // this.getReporte(this.token, e.target.value, this.alumnos, this.periodoSelected, this.games);
    this.accessLink = e.target.value;
    this.gr=this.accessLink.substring(this.accessLink.length-2);
  };

  getAlumnos:any = (token, grupo) => {
    //console.log("getAlumnos", this.grupos);
    this.reporTakService.getAlumnos(token, grupo).subscribe((response: any) => {
      if(response.status == 200 && response.body.message === 'Ok'){
        //console.log(response);
        if(response.body.alumnos.length > 0){
          this.alumnos = response.body.alumnos;
          this.alumnos.forEach(alumno => { alumno.expanded = false; });
          this.getPeriodos(this.token, grupo, response.body.alumnos);
        }else{
          this.alumnos = [];
          Swal.fire(" ","Este grupo no tiene alumnos asociados", "error");
        }
      }else{
        this.alumnos = [];
        Swal.fire(" ","Este grupo no tiene alumnos asociados", "error");
      }
    });

  }

  getPeriodos:any = (token, grupo, alumnos) => {

    this.reporTakService.getPeriodos(token, grupo, alumnos).subscribe((response: any) => {
      if(response.status == 200 && response.body.message === 'Ok'){
        this.periodos = response.body.periodos;
        this.periodo = this.mensual ? this.periodos.mensuales : this.periodos.quincenales;
        //console.log("this.periodo", this.periodo);
        this.periodoSelected = this.periodo[0].date_start;
        this.getReporte(this.token, grupo, this.alumnos, this.periodo[0].date_start, this.games); // TODO primer periodo
      }else{
        this.periodos = {mensuales:[], quincenales:[]};
      }
    });

  }

  getReporte:any = (token, grupo, alumnos, periodo, games) => {
    Swal.showLoading();
    this.reporTakService.registrarActividad(token, grupo, this.planSelected, periodo, this.navegacion.seccion).subscribe();
    this.p = 0;
    this.reporTakService.getReporte(token, grupo, alumnos, periodo, games).subscribe( (response: any) => {
      Swal.close();
      //console.log("response", response);
      if(response.status == 200 && response.body.message === 'Ok'){
        this.alumnos = response.body.alumnos;
        //alumnosChart
        this.alumnosChart = response.body.alumnos;
        this.alumnosChart.forEach(alumno => { alumno.collapse = false; });
        //end alumnosChart
        this.materias = response.body.materias;
      }else{
        this.materias= [];
        Swal.fire(" ", "No se pudo obtener información con los filtros utilizados", "warning");
      }
    });

  }

  private handlePeriodo:any = (periodo) => {
    this.mensual = periodo === 'm';
    this.periodo = periodo === 'm' ? this.periodos.mensuales : this.periodos.quincenales;
    this.periodoSelected = this.periodo[0].date_start + (periodo === 'q' ? ('-' + this.periodo[0].quincena) : '');
    this.getReporte(this.token, this.accessLink, this.alumnos, this.periodo[0].date_start + (periodo === 'q' ? ('-' + this.periodo[0].quincena) : ''), this.games);
    //console.log("this.periodo", this.periodo);
  }

  private handleSelectPeriodo:any = (event) => {
    //console.log("handleSelectPeriodo", event.target.value);
    //console.log("this.alumnos", this.alumnos);
    this.periodoSelected = event.target.value;
    this.camposSelected = 1;
    this.getReporte(this.token, this.accessLink, this.alumnos, event.target.value, this.games);
  }

  getGrafica:any = ()  => {
    
    if(this.alumnos!=null){
      this.alumnos.forEach(alumno => {
        if(this.materias!=null){
          this.materias.forEach(materia => {//segunda grafica
            this.reporTakService.getGraficaAlumnos(this.token, alumno, materia, this.periodoSelected, this.games).subscribe((response: any) => {
              //console.log("getGraficaAlumnos", response);
              if(response.status == 200 && response.body.message === 'Ok'){
                this.lineChart = new Chart(materia.asignature + '-' +alumno.username, {
                  type: 'bar',
                  data: {
                    labels: response.body.data.label,
                    datasets: [{
                      backgroundColor: response.body.data.color,
                      borderColor: response.body.data.color,
                      data: response.body.data.value,
                      fill: false,
                      lineTension: 1
                    }]
                  },
                  options: {
                    responsive: true,
                    legend: {
                      position: 'left',
                      display: false
                    },
                    maintainAspectRatio: false,
                    spanGaps: false,
                    elements: {
                      line: {
                        tension: 0.000001
                      }
                    },
                    scales: {
                      yAxes: [{
                        ticks:{
                          beginAtZero:true,
                          min: 0,
                          max: 3,
                          stepSize: 1,
                          callback: ((val:any) => Number.isInteger(val) ? val : null)
                        },
                        stacked: true
                      }]
                    },
                    plugins: {
                      filler: {
                        propagate: false
                      },
                      'samples-filler-analyser': {
                        target: 'chart-analyser'
                      }
                    }
                  }
                });
              }
            });
          });
      
        }
      });
    }
    
  }

  private filterItemsOfType(status){
    if(this.grupos && this.grupos.length){
      return this.grupos.filter(x => x.status == status);
    }else{
      return false;
    }
  }


  changeStatus:any = async () => {
    await this.alumnosChart.forEach(alumno => { alumno.collapse = true; });
    this.pdfReportF = true;
  }

  async generatePdf(){
    this.navegacion.seccion = 20;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
    //console.log(this.alumnos);
    //console.log(this.materias);
    
    Swal.showLoading();
     if(this.alumnos.length>0)
     {
        if(this.materias.length>0)
        {
          await this.changeStatus();
          await this.fillCharts();
        }
        else
        {
          Swal.fire(" ","No existe informacion para generar los reportes", "warning");
        }
     }
     else
     {
        Swal.fire(" ","No existe informacion para generar los reportes", "warning");
     }
     setTimeout(() => {Swal.close();},35000);
  }

  async preparar(){
    let header:Element = document.createElement("div");
    let reportak = document.getElementById("reportakescondido").cloneNode(true);
    header.appendChild(reportak);

    //Detalles del reporte
    let col1:Element = document.createElement("div");
    col1.classList.add("col-6");
    let plan:Element = document.createElement("div");
    plan.innerHTML = "<b>Planeación: </b>"+this.pl;
    col1.appendChild(plan);
    let tipo:Element = document.createElement("div");
    tipo.innerHTML = "<b>Reporte por: </b> Alumnos";
    col1.appendChild(tipo);
    let grupo:Element = document.createElement("div");
    grupo.innerHTML = "<b>Grupo: </b>"+this.gr;
    col1.appendChild(grupo);
    let periodo:Element = document.createElement("div");
    periodo.innerHTML = "<b>Periodo: </b>"+this.periodoSelected;
    col1.appendChild(periodo);
    let tipoReporte:Element = document.createElement("div");
    tipoReporte.innerHTML = "<b>Tipo de Reporte: </b> Completo <br> <span style='font-size:11px;'>*Reporte de usuarios con actividad dentro del periodo.</span>";
    col1.appendChild(tipoReporte);
    let col2:Element = document.createElement("div"); 
    col2.classList.add("col-6");
    let docente:Element = document.createElement("div");
    docente.innerHTML = "<b>Docente: </b>"+this.userData.name+' '+this.userData.lastname;
    col2.appendChild(docente);
    let escuela:Element = document.createElement("div");
    (this.userData.centro_educativo==null) ? escuela.innerHTML = "<b>Escuela: </b>"
      : escuela.innerHTML = "<b>Escuela: </b>"+this.userData.centro_educativo;
    col2.appendChild(escuela);
    let cct:Element = document.createElement("div");
    (this.userData.cct==null) ? cct.innerHTML = "<b>CCT: </b>"
      : cct.innerHTML = "<b>CCT: </b>"+this.userData.cct;
    col2.appendChild(cct);
    let row:Element = document.createElement("div");
    row.classList.add("row");
    row.appendChild(col1);
    row.appendChild(col2);
    header.appendChild(row);

    let bloque = document.createElement("div");
    bloque.appendChild(header);

    let array_chido = [];
    
    if(this.alumnos!=null){
      this.alumnos.forEach(alumno => {
        let megablock: Element = document.createElement("div");
        let empty:number = 0;
        if(this.materias!=null){
          let chartHeader:Element = document.getElementById(alumno.username);
          let tempHeader = chartHeader.cloneNode(true);
          megablock.appendChild(tempHeader);
          for(let i:number=0; i < this.materias.length; i++){
            if(alumno.reporte[i].efficiency_level != 0){
              empty++;
              let chartMateria:Element = document.getElementById(this.materias[i].asignature+'---'+alumno.username);
              megablock.appendChild(chartMateria);
            }
          }
        }
        if(empty>0){
          let breakElement = document.createElement("div");
          breakElement.classList.add("html2pdf__page-break");
          megablock.appendChild(breakElement);
          array_chido.push(megablock);
        }
        //Solo queremos ver los que tienen graficas
        /*else{
          if(array_chido.length > 0){
            array_chido[array_chido.length - 1].appendChild(megablock);
          }else{
            array_chido.push(megablock);
          }
        }*/
      });
    }
    let indicadores = document.getElementById("indicador");
    let nodeIndicadores = indicadores.cloneNode(true);
    bloque.appendChild(nodeIndicadores);

    let array_bloques = [];
    let bloque_temp: Element = document.createElement("div");
    for(let i = 0; i < array_chido.length; i++){
      if(i%4 == 0){
        bloque_temp.appendChild(array_chido[i]);
        array_bloques.push(bloque_temp);
        bloque_temp = document.createElement("div");
      }else{
        bloque_temp.appendChild(array_chido[i]);
      }
    }
    if(array_chido.length%4 != 0){
      array_bloques.push(bloque_temp);
    }

    const options = {
      margin: 1,
      filename: 'Reportak-Alumnos.pdf',
      image: { type: 'jpeg' },
      html2canvas: {
        letterRendering: true,
        useCORS: true
      },
      jsPDF: { unit: 'cm', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    }

    let worker = html2pdf()
    .set(options)
    .from(bloque);
    worker = worker.toPdf() // worker is now a jsPDF instance
    array_bloques.forEach((element, index) => {
      worker = worker
        .get('pdf')
        .then(pdf => {
          pdf.addPage()
        })
        .from(element)
        .toContainer()
        .toCanvas()
        .toPdf()
    })

    worker = worker.get('pdf').then(function (pdf) {
      var totalPages = pdf.internal.getNumberOfPages();

      for (var i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor("#00bae0");
          pdf.setFontType("bold");
          pdf.text('ReporTak | LabTak®', pdf.internal.pageSize.getWidth()/2 - 1.5, pdf.internal.pageSize.getHeight() - 1.5);
          pdf.setFontType("normal");
          pdf.setTextColor("#00bae0");
          pdf.text('Página ' + i + ' de ' + totalPages, pdf.internal.pageSize.getWidth()/2 - 1, pdf.internal.pageSize.getHeight() - 1);
      } 
    }).save();

    this.pdfReportF = false;
  }

  done:any = () => {
    this.counter.increment();
    if(this.counter.value() == this.alumnos.length*this.materias.length){
      setTimeout(()=>this.preparar(), 2000);
      this.counter.toZero();
    }
  }

  counter = (function() {
    var privateCounter = 0;
    function changeBy(val) {
      privateCounter += val;
    }
    function reset() {
      privateCounter = 0;
    }
    return {
      increment: function() {
        changeBy(1);
      },
      decrement: function() {
        changeBy(-1);
      },
      toZero: function() {
        reset();
      },
      value: function() {
        return privateCounter;
      }
    }
  })();

  fillCharts:any = async () => {
    if(this.alumnos!=null){
      await this.alumnos.forEach(async alumno => {
        if(this.materias!=null){
          await this.materias.forEach(async materia => {
            await this.reporTakService.getGraficaAlumnos(this.token, alumno, materia, this.periodoSelected, this.games).subscribe((response: any) => {
              //console.log("getGraficaAlumnos", response);
              if(response.status == 200 && response.body.message === 'Ok'){
                this.lineChart = new Chart(materia.asignature + '--' +alumno.username, {
                  type: 'bar',
                  data: {
                    labels: response.body.data.label,
                    datasets: [{
                      backgroundColor: response.body.data.color,
                      borderColor: response.body.data.color,
                      data: response.body.data.value,
                      fill: false,
                      lineTension: 1
                    }]
                  },
                  options: {
                    animation: {
                      onComplete: this.done()
                    },
                    responsive: true,
                    legend: {
                      position: 'left',
                      display: false
                    },
                    maintainAspectRatio: false,
                    spanGaps: false,
                    elements: {
                      line: {
                        tension: 0.000001
                      }
                    },
                    scales: {
                      yAxes: [{
                        ticks:{
                          beginAtZero:true,
                          min: 0,
                          max: 3,
                          stepSize: 1,
                          callback: ((val:any) => Number.isInteger(val) ? val : null)
                        },
                        stacked: true
                      }]
                    },
                    plugins: {
                      filler: {
                        propagate: false
                      },
                      'samples-filler-analyser': {
                        target: 'chart-analyser'
                      }
                    }
                  }
                });
              }
            });
          });
        }
      });
    }
  }

  constructor(private reporTakService: ReportakService, private modalService: NgbModal, private detalleService: DetalleService){

    this.navegacion.seccion = 4;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
    this.modalOptions = {
      backdrop: 'static',
      size: 'lg',
      centered: true
    }

    this.reporTakService.getPerfil(this.token).subscribe((response: any) => {
      if(response.message == 'Ok'){
        this.userData = response.user_data;
        //console.log(this.userData);
      }
    });

    this.reporTakService.getPlanes(this.token).subscribe((response: any) => {
      if(response.message === 'OK'){
        //console.log("getPlanes", response);
        if(response.planes.length > 0){
          this.planSelected = response.planes[0].id;
          this.pl = response.planes[0].name;
          this.planes = response.planes;
          this.grupos = response.planes[0].grupos;
          this.games = response.planes[0].games;
          if( response.planes[0].grupos.length >0 ){ 
            let ig = 0;
            response.planes[0].grupos.forEach((g,index) => {
              if(g.status==1 && ig==0){
                this.accessLink = response.planes[0].grupos.length ? response.planes[0].grupos[index].access_link : '';
                this.getAlumnos(this.token, response.planes[0].grupos[index].access_link);
                this.gr = this.accessLink.substring(this.accessLink.length-2);
                ig=1;
              }
            });
          }
          else{
            Swal.fire(" ","Este plan no tiene grupos asignados", "error");
          }
        }
      }
    });

  }

  ngOnInit() {
    let top = document.getElementById('top');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }
  }




//////////////////nuevo listado PDF /////////////
  async prepararListado(){
    this.navegacion.seccion = 21;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
    
    Swal.showLoading();

    await this.changeStatus();
    let header:Element = document.createElement("div");
    let reportak = document.getElementById("reportakescondido").cloneNode(true);
    header.appendChild(reportak);

    //Detalles del reporte
    let col1:Element = document.createElement("div");
    col1.classList.add("col-6");
    let plan:Element = document.createElement("div");
    plan.innerHTML = "<b>Planeación: </b>"+this.pl;
    col1.appendChild(plan);
    let tipo:Element = document.createElement("div");
    tipo.innerHTML = "<b>Reporte por: </b> Alumnos";
    col1.appendChild(tipo);
    let grupo:Element = document.createElement("div");
    grupo.innerHTML = "<b>Grupo: </b>"+this.gr;
    col1.appendChild(grupo);
    let periodo:Element = document.createElement("div");
    periodo.innerHTML = "<b>Periodo: </b>"+this.periodoSelected;
    col1.appendChild(periodo);
    let tipoReporte:Element = document.createElement("div");
    tipoReporte.innerHTML = "<b>Tipo de Reporte: </b> Listado";
    col1.appendChild(tipoReporte);
    let col2:Element = document.createElement("div"); 
    col2.classList.add("col-6");
    let docente:Element = document.createElement("div");
    docente.innerHTML = "<b>Docente: </b>"+this.userData.name+' '+this.userData.lastname;
    col2.appendChild(docente);
    let escuela:Element = document.createElement("div");
    (this.userData.centro_educativo==null) ? escuela.innerHTML = "<b>Escuela: </b>"
      : escuela.innerHTML = "<b>Escuela: </b>"+this.userData.centro_educativo;
    col2.appendChild(escuela);
    let cct:Element = document.createElement("div");
    (this.userData.cct==null) ? cct.innerHTML = "<b>CCT: </b>"
      : cct.innerHTML = "<b>CCT: </b>"+this.userData.cct;
    col2.appendChild(cct);
    let row:Element = document.createElement("div");
    row.classList.add("row");
    row.appendChild(col1);
    row.appendChild(col2);
    header.appendChild(row);

    let bloque = document.createElement("div");
    bloque.appendChild(header);

    let array_chido = [];
    
    if(this.alumnos!=null){
      this.alumnos.forEach(alumno => {
        let megablock: Element = document.createElement("div");
        let empty:number = 0;
        if(this.materias!=null || 1){
          console.log(alumno.username);
          let chartHeader:Element = document.getElementById(alumno.username);
          let tempHeader = chartHeader.cloneNode(true);
          megablock.appendChild(tempHeader);
          array_chido.push(megablock);
        }
      }); 
    }
    let indicadores = document.getElementById("indicador");
    let nodeIndicadores = indicadores.cloneNode(true);
    bloque.appendChild(nodeIndicadores);

    let array_bloques = [], count:number=1;
    let bloque_temp: Element = document.createElement("div");
    for(let i = 0; i < array_chido.length; i++){
      array_chido[i].style.cssText='border:2px solid white;height:200px !important;'; 
      if(i < 2){
        bloque.appendChild(array_chido[i]);
      }else{
        bloque_temp.appendChild(array_chido[i]);
        count++;
        if(count==6){
          array_bloques.push(bloque_temp);
          bloque_temp = document.createElement("div");
          count = 1; 
        }
      }
    }
    array_bloques.push(bloque_temp);

    const options = {
      margin: 1,
      filename: 'ReportakListado-Alumnos.pdf',
      image: { type: 'jpeg' },
      html2canvas: {
        letterRendering: true,
        useCORS: true
      },
      jsPDF: { unit: 'cm', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    }
    
    let worker = html2pdf()
    .set(options)
    .from(bloque);
    worker = worker.toPdf() // worker is now a jsPDF instance
    array_bloques.forEach((element, index) => {
      worker = worker
        .get('pdf')
        .then(pdf => {
          pdf.addPage()
        })
        .from(element)
        .toContainer()
        .toCanvas()
        .toPdf()
    })

    worker = worker.get('pdf').then(function (pdf) {
      var totalPages = pdf.internal.getNumberOfPages();

      for (var i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor("#00bae0");
          pdf.setFontType("bold");
          pdf.text('ReporTak | LabTak®', pdf.internal.pageSize.getWidth()/2 - 1.5, pdf.internal.pageSize.getHeight() - 1.5);
          pdf.setFontType("normal");
          pdf.setTextColor("#00bae0");
          pdf.text('Página ' + i + ' de ' + totalPages, pdf.internal.pageSize.getWidth()/2 - 1, pdf.internal.pageSize.getHeight() - 1);
      } 
    }).save();
    this.pdfReportF = false;
    setTimeout(() => {Swal.close();},15000);
  }

}
