import { Component, OnInit } from '@angular/core';
import { DetalleService } from '../service/detalle.service';
import { OtrosRecursos } from '../models/recursos';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService } from '../service/game.service';
import { Navegacion } from '../models/navigate';
import { Resources } from '../models/navigation';
import {Location} from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var dragula: any;
@Component({
  selector: 'app-item-contenido',
  templateUrl: './item-contenido.component.html',
  styleUrls: ['./item-contenido.component.css']
})
export class ItemContenidoComponent implements OnInit {

  public nombrePlan: string;
  public descripcion: string;
  private id_plan: string;
  public asignatura: string;
  public nivel: string;

  public videoyt = false;
  public videourl = 'https://www.youtube.com/embed/';
  urlSafe: SafeResourceUrl;

  public otrosrecursos = new OtrosRecursos();
  public navegacion = new Navegacion(); 
  public navigateGame = new Resources();
  private token: string = localStorage.getItem('token');
  constructor(private _location: Location, private otrosrecursosService: DetalleService, private activatedRoute: ActivatedRoute, private router: Router, private gameService: GameService, public sanitizer: DomSanitizer) {
    this.navegacion.seccion = 9;
    this.otrosrecursosService.navegacion(this.token, this.navegacion).subscribe();
    this.activatedRoute.params.subscribe(params => {
      this.navigateGame.place = 4;
      this.navigateGame.recurso = params.id;
      this.otrosrecursosService.navegacionRecurso(this.token, this.navigateGame).subscribe();
      this.otrosrecursosService.verDetalleRecurso(this.token, params.id).subscribe((response: any) => {
        console.log(response);
        this.otrosrecursos.id = response.message.id;
        this.otrosrecursos.nombre = response.message.nombre;
        this.otrosrecursos.link = response.message.link;
        this.otrosrecursos.descripcion = response.message.descripcion;
        this.otrosrecursos.status = response.message.status;
        this.otrosrecursos.tags = response.message.tags;
        this.otrosrecursos.img = response.message.image;
        this.otrosrecursos.pdf = response.message.pdf;
        this.otrosrecursos.video = response.message.video;
        console.log(this.otrosrecursos.pdf);
        if(this.otrosrecursos.video!='') this.incluirvideo(this.otrosrecursos.video);
        if (this.otrosrecursos.pdf == 'Si') {
          // this.PDF();
        }
        const regex = /\s*,\s*/;
        // this.tags = this.otrosrecursos.tags.split(regex);
        // this.tags = this.tags.filter((element) => {
        //   return element !== '';
        // });
        console.log(this.otrosrecursos);

        this.asignaturas(this.otrosrecursos.tags);
        this.niveles(this.otrosrecursos.tags);
        this.transformarLink(this.otrosrecursos.link);
                

        // this.linkSource = sanitized.bypassSecurityTrustUrl(this.otrosrecursos.pdf);
      }, error => {
        console.log(error);
      });
    });
  }
  transformarLink(links){
    if(links.includes('<a')){
      let link = links.replace(/<a/gi, '<a target="_blank"');
      this.otrosrecursos.link = link;
    }
    else if(links.includes('http') && links.includes('<p>')){
      let link = links.split('<p>');
      let urllink = link[1].replace('</p>', ' ');
      this.otrosrecursos.link = '<p>' + '<a target="_blank" href="' + urllink + '" >' + urllink + '</a> </p>';
    }
  }
  incluirvideo(video){
    if(video.includes('youtu')){
      let link1 = video.split('h?v=');
      let link = link1[1].split('">');

      this.videourl = 'https://www.youtube.com/embed/' + link[0];
      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.videourl);
      this.videoyt = true;
    }
  }

  asignaturas(tags){
    this.asignatura='';
    let materias = ['biología', 'ecología', 'física', 'geografía', 'química', 'artes visuales', 'música', 'lenguaje y comunicación', 'formación cívica y ética', 'historia', 'matemáticas', 'desarrollo socioemocional', 'desarrollo cognitivo'];
    for (let index = 0; index < materias.length; index++) {
      if(tags.includes(materias[index])){
        let mat = materias[index].charAt(0).toUpperCase() + materias[index].slice(1);
        this.asignatura=this.asignatura + mat;
      }
    }
  }
  niveles(tags){
    this.nivel='';
    if(tags.includes('primaria baja')){
      this.nivel= this.nivel + 'Primaria baja';
    }
    if(tags.includes('primaria media')){
      if(this.nivel != '') this.nivel = this.nivel + ', ';
      this.nivel= this.nivel + 'Primaria media';
    }
    if(tags.includes('primaria alta')){
      if(this.nivel != '') this.nivel = this.nivel + ', ';
      this.nivel= this.nivel + 'Primaria alta';
    }
  }

  ngOnInit() {

  }

  // recoger() {
  //   this.gameService.crearPlan(this.token, this.nombrePlan, this.descripcion).subscribe(
  //     data => {
  //       // console.log(data);
  //       if (data["message"] != null || data["message"] != "") {

  //         this.id_plan = data["message"];

  //         this.gameService.vincularRecursos(this.token, this.id_plan, localStorage.getItem("contenido")).subscribe();

  //         localStorage.removeItem('contenido');
  //         this.router.navigate(["/mis-planes"]);
  //       }
  //     }
  //   );
  // }

  agregarPorMas(event) {
    document.getElementById('js-drop-documents').append(event["path"][2]);

    var URLactual = window.location;

    var Aux = URLactual.toString();

    var contenido = Aux.replace(Aux.substring(30, 0), "");

    localStorage.setItem("contenido", contenido);
  }

  PDF() {
    console.log(this.otrosrecursos.id);
     this.otrosrecursosService.obtenerPDF(this.token, this.otrosrecursos.id).subscribe( (response:any)=>{
       console.log(response);
       this.otrosrecursos.pdfaux = response;
     });
    this.otrosrecursosService.obtenerPDF(this.token, this.otrosrecursos.id).subscribe(
      data => {
        let pdf = data.toString();
        let pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(pdf) + "'></iframe>");
      }
    )
    
  }
  back() {
    this._location.back();
  }
}
