import { Component, OnInit } from '@angular/core';
import { Navegacion } from '../models/navigate';
import { DetalleService } from '../service/detalle.service';

import { Router } from '@angular/router';
import { imagenesCapacitaciones, certificados } from '../service/config';
import { CapacitacionesService } from '../service/capacitaciones.service';

import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';

@Component({
  selector: 'app-capacitacion-listado',
  templateUrl: './capacitacion-listado.component.html',
  styleUrls: ['./capacitacion-listado.component.css']
})
export class CapacitacionListadoComponent implements OnInit {

  public url = certificados.url;

  private token = localStorage.getItem('token');
  public navegacion = new Navegacion();
  public cap: boolean = false;
  private urlIMG = imagenesCapacitaciones.url;
  public p = 0;
  Arr = Array;

  public webinars = [];
  public moocs = [];
  public name_user;

  constructor(private detalleService: DetalleService, private capacitacionService: CapacitacionesService, private router: Router) {
    this.navegacion.seccion = 28;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();

    this.capacitacionService.getMisCapacitaciones(this.token).subscribe((response: any) => {
      this.webinars = response.webinars;
      this.moocs = response.moocs; 
    });

    this.detalleService.detallePerfil(this.token).subscribe((response: any) => {
      this.name_user = response.user_data.name + ' ' + response.user_data.lastname;
    });
  }

  ngOnInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  handleMostrar: any = (opc) => {
    this.p = 0;
    if (opc == 1) this.cap = false;
    else if (opc == 0) this.cap = true;
  }

  async certificado(tipo, nombre) { 
    //Falta definir los archivos de cada uno de los certificados, adjuntar la ubicacion y colores de cada uno
    (tipo==0) ? 'Webinar' : 'Mooc';
    const certf = '../assets/certs/' + 'certificado.pdf'

    fetch(certf, { mode: 'no-cors' }).then(res => {
      res.arrayBuffer().then( async buffer => { 
        const uint8 = new Uint8Array(buffer);
        const pdfDoc = PDFDocument.load(uint8);
        const helveticaFont = await (await pdfDoc).embedFont(StandardFonts.Helvetica)

        const pages = (await pdfDoc).getPages()
        const firstPage = pages[0]
        const { width, height } = firstPage.getSize();

        const name = this.name_user;
        const textSize = 30;
        const textWidth = helveticaFont.widthOfTextAtSize(name, textSize+20);
        const textWidth2 = helveticaFont.widthOfTextAtSize(nombre, textSize);

        firstPage.drawText(nombre, {
          x: width / 2 - textWidth2 / 2,
          y: height / 2 + 100,
          size: textSize,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(name, {
          x: width / 2 - textWidth / 2,
          y: height / 2,
          size: textSize + 20,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        });
        const pdfBytes = await (await pdfDoc).save();
        
        this.downloadFile(pdfBytes);
      })
    })
  }

  downloadFile(data) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }

  nav(id:any, type){
    this.p=0;
    (type==1) ? this.router.navigate(['capacitacion-docente/webinar-live/1/'+id])
        : this.router.navigate(['capacitacion-docente/mooc-insc/1/'+id])
  }

}
