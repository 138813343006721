import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { NgFormSelectorWarning } from '@angular/forms';
import { prototype } from 'events';

export class Planes {
    public id= '';
    public name= '';
    public descripcion= '';
    public date_created= '';
    public slug= '';
    public color= '';
}
export class PlanesLT {
   
    public name= '';
    public descripcion = '';
    public is_template = 1;
    public template_id:number;
    public peticion = '0';
}
export class UpdatePlanesLT{
    public name ="";
    public peticion= "1";
    public lt = 1;
}

export class CrearPlanes {
    public name = '';
    public descripcion = '';
    public color = '#ffffff';
    public competencias = '';
    public objetivos = '';
    public secuencia = '';
    public duracion = '';
    public recursos = '';
    public peticion = "0";
    public busc = 0;
    public master = 0;
}

export class NuevoPlan{
    public reforma = "";
    public institucion = '';
    public nombre_docente = '';
    public area = '';
    public cct = null;
    public duracion = '';
    public semana = '';
    public grado = null;
    public aprendizajes_clave = '';
    public propositos_pedagogicos = '';
    public contexto = '';
    public bloque = '';
    public director = '';
    public fecha = '';
    public ciclo = '';
    public sesiones = 0;
    public peticion = "0";
}

export class ModeloEducativo{
    public id_modelo_educativo = null;
    public id_aprendizajes:string;
    public formacion:string;
    public asignatura:string;
    public eje:string;
    public tema:string;
    public aprendizajes_esperados:string;
    public id_mod_edu_2011 = null;
    public id_apren_2011:string;
    public id_comp_2011:string;
    public vida_saludable:string;
    public peticion = "1";
}

export class Sesiones{
    public numero = 0;
    public descripcion_inicio='';
    public duracion_inicio='';
    public descripcion_desarrollo='';
    public duracion_desarrollo='';
    public descripcion_cierre='';
    public duracion_cierre='';
    public materiales_complementarios='';
    public reflexion='';
    public activacion='';
    public problematizacion='';
    public profundizacion='';
    public criterios_evaluacion='';
    public evidencias='';
    public instrumentos_evaluacion='';
    public juegos = '';
    public otros_recursos = '';
    public libros = "";
    public peticion = "2";
    public status = 1;
}

export class JuegosRecursosSesion{
    public juegos = "";
    public otros_recursos = "";
    public libros = "";
    public id = 0 ;
    public peticion = "3";
}

export class UpdateNuevosPlanes{
    public name = '';
    public descripcion = '';
    public nuevoPlan = new NuevoPlan();
    public modeloEducativo = new ModeloEducativo();
    public sesiones = [ new Sesiones()];
    public peticion = "4";
}
