import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { blogComment } from '../models/blogComments';
import Swal from 'sweetalert2';
import { timer } from 'rxjs';

import { imagenesBlog } from '../service/config';
import { BlogService } from '../service/blog.service';
import { DetalleService } from '../service/detalle.service';

@Component({
  selector: 'app-articulo',
  templateUrl: './articulo.component.html',
  styleUrls: ['./articulo.component.css']
})
export class ArticuloComponent implements OnInit{

  public IDarticulo:any;
  private IDemail:any;
  private resArt:any = [];
  public Comentario = new blogComment();
  private textcomment: string;
  private token = localStorage.getItem('token');
  private showClock:boolean;
  private showBlock:boolean;
  private closeLogin:boolean;
  private nontoken:boolean; private RoI:String;private GoTo="";

  private urlIMG = imagenesBlog.url;

  private _second = 1000; private _minute = this._second * 60;
  private end: any; private now: any;
  private minutes: any; private seconds: any;
  private source = timer(0, 1000); private clock:any; 
  private time: Array<String>;
  private timer = localStorage.getItem('timer');

  constructor(private route: ActivatedRoute, private blogService: BlogService, private detalleService: DetalleService) { }

  eliminarComment(ID:number){
    let b = {"ID":ID,"visible":false}
    Swal.fire({
      title: '¿Seguro quieres eliminar tu comentario?',
      text: "no podras deshacer esto!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalo'
    }).then((result) => {
      if (result.value) {
        this.blogService.postUpdateCommentBlog(b).subscribe((response: any) => {
          this.refresh();
        });
        Swal.fire(
          'Eliminado!',
          'Tu comentario se ha eliminado.',
          'success'
        )
      }
    })
  }

  async publicar(idres:number){
    if(this.token==null || this.token==undefined || this.token==""){
      Swal.fire("","Registrate en la pagina para comentar","warning");
    }
    else{
      this.Comentario.IDarticulo = this.resArt.article.ID;
      if(!idres){
        this.Comentario.IDrespuesta = null;
        if(this.textcomment){
          this.Comentario.texto = this.textcomment;
        }
        else{
          this.Comentario.texto = "#no#";
        }
      }
      else{
        this.Comentario.IDrespuesta = idres;
        await Swal.fire({
          input: 'textarea',
          inputPlaceholder: 'Escriba aqui su respuesta',
          showCancelButton: true
        }).then((text) => {
          if (text.value) {
            this.Comentario.texto = text.value;
          } else{
            this.Comentario.texto = "#no#";
          }
        })
      } 
      this.Comentario.IDusuario = this.IDemail; //arreglar cuando pongamos sesion
      this.Comentario.fecha = new Date().toISOString();
      this.Comentario.visible = true;

      if(this.Comentario.texto != "#no#"){
        console.log(this.Comentario); //POST
        this.blogService.postCommentBlog(this.Comentario).subscribe((response: any) => {
        this.refresh();
        this.textcomment="";
        Swal.fire("","Comentario publicado","success");
        });
      }
    }
  }

  showDate(){
    let distance = this.end - this.now;
    this.minutes = Math.floor(distance / this._minute);
    this.seconds = Math.floor((distance % this._minute) / this._second);
    localStorage.setItem('timer',this.minutes+","+this.seconds+"");
    if(this.minutes < 0)
    {
      this.clock.unsubscribe();
      this.showClock = false;
      let top = document.getElementById('top');
      top.scrollIntoView();
      this.showBlock = true;
      this.closeLogin = false;
      localStorage.setItem('timer','0,0');
    }
  }

  refresh(){
    this.blogService.getArticuloSelec(this.IDarticulo).subscribe((response: any) => {
      this.resArt = response;
      this.resArt.article.foto = this.urlIMG + this.resArt.article.foto;      
      this.resArt.article.imagen = this.urlIMG + this.resArt.article.imagen;
      console.log(this.resArt);
    });
  }

  ngOnInit() {
    this.IDarticulo = this.route.snapshot.paramMap.get('id');
    this.refresh();
    this.showBlock = false;
    this.closeLogin = false;
    this.RoI = 'I';
    this.GoTo = "B";
    if(this.token==null || this.token==undefined || this.token==""){
      this.nontoken = true;
      if(this.timer==null || this.timer==undefined || this.timer=="" || this.timer=="NaN,NaN"){
        localStorage.setItem('timer','5,0');
        this.timer = localStorage.getItem('timer');
      }
      this.time = this.timer.split(',');
      this.showClock=true;
      this.now = new Date();
      this.end = new Date(this.now.getFullYear(),this.now.getMonth(),this.now.getDate(),this.now.getHours(),Number(this.time[0]) + Number(this.now.getMinutes()),Number(this.time[1])+Number(this.now.getSeconds()));
      
      this.clock = this.source.subscribe(t => {
        this.now = new Date();
        this.showDate();
      }); 
      this.detalleService.navegacionBlogOther(this.token, {articulo_id: this.IDarticulo}).subscribe();
    }else{
      this.showClock=false;
      this.nontoken = false;
      this.IDemail = localStorage.getItem('email');
      this.detalleService.navegacionBlog(this.token, {articulo_id: this.IDarticulo}).subscribe();
    }

    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
}