import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';

@Injectable({
  providedIn: 'root'
})

export class ForoService{

  public url = config.url;
  constructor(public http: HttpClient) { }

  getForo(): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-foro', { headers: headers });
  }

  getEntradas(ID:string): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-foro/'+ID, { headers: headers });
  }

  postEntrada(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-foro/postEntrada', body, { headers: headers });
  }

  postComment(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-foro/postComment', body, { headers: headers });
  }

  getSearch(tag:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-foro/search/'+tag, { headers: headers });
  }

  postUpdateComment(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-foro/postUpdateComment', body, { headers: headers });
  }

  postUpdateEntrada(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-foro/postUpdateEntrada', body, { headers: headers });
  }
}