import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { User } from '../models/user';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Password } from '../models/perfil';
import Swal from 'sweetalert2';
declare var $:any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService]

})
export class LoginComponent implements OnInit {

  public user: User;
  public user_lic: User;
  public emailRecover = '';
  refresh: string = "false";
  mensaje?= "";
  isCorrect: boolean = true;
  showPassword: boolean = false;
  isDisabled: boolean = false;

  colombia = 0;
  FIFYA = 0;
  piloto = 0;
  sek = 0;
  techint = 0;
  proed = 0;
  taktaktak = 0;
  emgm = 0;
  
  campania: {campanias: string};

  constructor(private userService: UserService,  private activatedRoute: ActivatedRoute, private router: Router) {
    localStorage.clear();
    this.user = new User('', '');
    this.user_lic = new User('', '');
  }


  ngOnInit() {

    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    this.campania = {
      campanias: this.activatedRoute.snapshot.params.campanias,
    };
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.campania.campanias = params.campanias;
        if(this.campania.campanias== 'colombia'){
          this.colombia = 1;
        }
        else if(this.campania.campanias== 'FIFYA'){
          this.FIFYA = 1;
        }
        else if(this.campania.campanias== 'piloto'){
          this.piloto = 1;
        }
        else if(this.campania.campanias== 'SEK'){
          this.sek = 1;
        }
        else if(this.campania.campanias == 'techint'){
          this.techint = 1;
        }
        else if(this.campania.campanias == 'proed'){
          this.proed = 1;
        }
        else if(this.campania.campanias == 'taktaktak'){
          this.taktaktak = 1;
        }
        else if(this.campania.campanias == 'emgm'){
          this.emgm = 1;
        }
      }
    );
    
    localStorage.setItem('refresh', this.refresh);

  }

  onSubmit(form: any) {
    Swal.showLoading();
    this.isDisabled = true;
    this.user.colombia = this.colombia;
    this.user.fya = this.FIFYA;
    this.user.piloto = this.piloto;
    this.user.sek = this.sek;
    this.user.techint = this.techint;
    this.user.proed = this.proed;
    this.user.taktaktak = this.taktaktak;
    this.user.emgm = this.emgm;
    this.user.activar_licencia = 0;

    this.userService.login(this.user).subscribe((response: any) => {

      if (localStorage.getItem('token')) {
        localStorage.clear();
        if(this.colombia==1){ this.router.navigate(['login/colombia']);}
        else if(this.FIFYA==1){ this.router.navigate(['login/FIFYA']); }
        else if(this.piloto==1){ this.router.navigate(['login/piloto']); }
        else if (this.sek == 1) { this.router.navigate(['login/SEK']); }
        else if (this.techint == 1) { this.router.navigate(['login/techint']); }
        else if (this.proed == 1) { this.router.navigate(['login/proed']); }
        else if (this.taktaktak == 1) { this.router.navigate(['login/taktaktak']); }
        else if (this.emgm == 1) { this.router.navigate(['login/emgm']); }
        else this.router.navigate(['login']);
      }
      localStorage.setItem('token', response.token);
      let fechaLogin = new Date();
      let fechaAux = fechaLogin.toString();
      localStorage.setItem('fechaLogin', fechaAux);
      localStorage.setItem('email', this.user.email);
      Swal.close();
      this.router.navigate(['inicio']);

    }, error => {
      this.isDisabled = false;
      Swal.fire({
        type: 'error',
        title: 'Datos incorrectos.',
        showConfirmButton: false,
        timer: 1500
      });
      if(this.colombia==1) this.router.navigate(['login/colombia']);
      else if(this.FIFYA==1) this.router.navigate(['login/FIFYA']);
      else if(this.piloto==1) this.router.navigate(['login/piloto']);
      else if(this.sek==1) this.router.navigate(['login/SEK']);
      else if(this.techint==1) this.router.navigate(['login/techint']);
      else if(this.proed==1) this.router.navigate(['login/proed']);
      else if (this.taktaktak == 1) this.router.navigate(['login/taktaktak']);
      else if (this.emgm == 1) this.router.navigate(['login/emgm']);
      else this.router.navigate(['login']);
      if (error.statusText === "Unauthorized") {
        this.mensaje = "Datos incorrectos";
        this.isCorrect = false;
        this.user.email = "";
        this.user.password = "";
      }
    });
  }

  onSubmitLic(form: any) {
    this.isDisabled = true;
    Swal.showLoading();
    this.user_lic.colombia = this.colombia;
    this.user_lic.fya = this.FIFYA;
    this.user_lic.piloto = this.piloto;
    this.user_lic.sek = this.sek;
    this.user_lic.techint = this.techint;
    this.user_lic.proed = this.proed;
    this.user_lic.taktaktak = this.taktaktak;
    this.user_lic.emgm = this.emgm;
    this.user_lic.activar_licencia = (this.user_lic.codigo != "") ? 1: 0;

    if(this.user_lic.activar_licencia == 1){
      this.userService.login(this.user_lic).subscribe((response: any) => {

        if (localStorage.getItem('token')) {
          localStorage.clear();
          if(this.colombia==1){ this.router.navigate(['login/colombia']);}
          else if(this.FIFYA==1){ this.router.navigate(['login/FIFYA']); }
          else if(this.piloto==1){ this.router.navigate(['login/piloto']); }
          else if(this.sek==1){ this.router.navigate(['login/SEK']); }
          else if(this.techint==1) { this.router.navigate(['login/techint']); }
          else if(this.proed==1) { this.router.navigate(['login/proed']); }
          else if (this.taktaktak == 1) { this.router.navigate(['login/taktaktak']); }
          else if (this.emgm == 1) { this.router.navigate(['login/emgm']); }
          else this.router.navigate(['login']);
        }
        localStorage.setItem('token', response.token);
        let fechaLogin = new Date();
        let fechaAux = fechaLogin.toString();
        localStorage.setItem('fechaLogin', fechaAux);
        localStorage.setItem('email', this.user.email);
        Swal.close()
        this.router.navigate(['inicio']);
        
      }, error => {
        Swal.close();
        this.isDisabled = false;
        Swal.fire({
          type: 'error',
          title: 'Datos incorrectos.',
          showConfirmButton: false,
          timer: 1500
        });
        if(this.colombia==1) this.router.navigate(['login/colombia']);
        else if(this.FIFYA==1) this.router.navigate(['login/FIFYA']);
        else if(this.piloto==1) this.router.navigate(['login/piloto']);
        else if(this.sek==1) this.router.navigate(['login/SEK']);
        else if(this.techint==1) this.router.navigate(['login/techint']);
        else if(this.proed==1) this.router.navigate(['login/proed']);
        else if (this.taktaktak == 1) this.router.navigate(['login/taktaktak']); 
        else if (this.emgm == 1) this.router.navigate(['login/emgm']); 
        else this.router.navigate(['login']);
        if (error.statusText === "Unauthorized") {
          this.mensaje = "Datos incorrectos";
          this.isCorrect = false;
          this.user.email = "";
          this.user.password = "";
        }
      });
    }
  }

  recuperar(form:any){
    this.userService.recuperarContraseñaEmail({email: this.emailRecover}).subscribe((response:any)=>{
      localStorage.clear();
      this.emailRecover='';
      
      $('#modalEditar').modal('hide');
    
      Swal.fire({
        type: 'success',
        title: 'Revisa tu correo electrónico para reestablecer tu contraseña.',
        showConfirmButton: false,
        timer: 3000
      });
    } , error => {
        Swal.fire({
          type: 'error',
          title: 'El correo que ingresaste no esta registrado en Labtak.',
          showConfirmButton: false,
          timer: 4000
        });
      });
  }

}
