export class Plan {
    public name= " ";
    public descripcion= "";
    public recursos = "";
    public objetivos = "";
    public competencias = "";
    public secuencia = "";
    public duracion = "";
    public peticion= "1" ;

}

export class PublicarPlan {
    public id_plan:number;
    public tags:string;
}

export class CompartirPlan {
    public id_plan:number;
    public correos = [];
}
export class PlanLibre{
    public grado:number;
    public asignatura:string;
    public tags:string = '';
}
export class PlanRefDo{
    public grado:number;
    public materia:number;
    public bloque:number;
    public tags:string= '';
}
export class PlanRefDs{
    public grado:number;
    public campo:number;
    public asignatura:number;
    public tags:string= '';
}