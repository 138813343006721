import { Component, OnInit } from '@angular/core';
import { Navegacion } from '../models/navigate';
import { DetalleService } from '../service/detalle.service';
import { ForoService } from '../service/foro.service';

@Component({
  selector: 'app-foro',
  templateUrl: './foro.component.html',
  styleUrls: ['./foro.component.css']
})
export class ForoComponent implements OnInit {

  private foro:any;
  private foroRespaldo:any;
  private searchTxt:any;

  private token: string = localStorage.getItem('token');
  public navegacion = new Navegacion();

  constructor(private detalleService: DetalleService, private foroService: ForoService) {
    this.navegacion.seccion = 25;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
  }

  search(){
    if(this.searchTxt=="")
    {
      this.foro = this.foroRespaldo;
    }
    else
    {
      this.foroService.getSearch(this.searchTxt).subscribe((response: any) => {
        this.foro = response;
        console.log(this.foro);
      });
    }
  }

  SelectTema(IDC:number){
    let cd:string = "container-"+Number(IDC).toString();
    var element = document.getElementById(cd);
    if(element.classList.contains("unselected")){
      this.refreshTemas();
      element.classList.remove("unselected");
      element.classList.add("selected");
    } 
    else{
      this.refreshTemas();
    }
    this.goTo("cont-"+Number(IDC).toString());
  }

  refreshTemas(){
    for(let i=0; i<this.foro.length; i++){
      var element = document.getElementById('container-'+this.foro[i].ID);
      element.classList.remove("selected");
      element.classList.add("unselected");
    }
  }

  goTo(id:string){
    let top = document.getElementById(id);
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  ngOnInit() {
    this.foroService.getForo().subscribe((response: any) => {
      this.foro = response;
      this.foroRespaldo=this.foro;
      console.log("Foro:",this.foro); 
    });
    this.goTo("top");
  }
}
