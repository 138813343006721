import { Component, OnInit } from '@angular/core';
import { TiendaService } from '../service/tienda.service';
import { Router } from '@angular/router';
declare var Conekta: any;
import Swal from 'sweetalert2';
import { DetalleService} from '../service/detalle.service';

@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.css']
})
export class TiendaComponent implements OnInit {
  
  public sections: any = [];
  public plan_selected: String;
  public orden: any = [];
  public orden_show: any = [];
  public orden_data: any = [];
  public botones_pago: any = ['p1','p2','p3'];
  private token = localStorage.getItem('token');
  private nontoken:boolean;
  private formCompra:boolean; private validacionForm ="";
  private cardParams:any;
  private pais_id:Number;
  private showBlock:boolean; private RoI="";private GoTo="";

  adquirir(){
    this.orden.paquete = this.plan_selected;
    if(this.plan_selected == 'basico'){
      this.orden_show.paquete = 'Paquete básico';
    }
    if(this.plan_selected == 'premium'){
      this.orden_show.paquete = 'Paquete premium';
    }
    this.reset_data();
  }

  setFreemium(){
    document.getElementById('freemium').setAttribute("class", "b_paquetes_a");
    document.getElementById('basico').setAttribute("class", "b_paquetes_i");
    document.getElementById('premium').setAttribute("class", "b_paquetes_i");
    this.plan_selected = 'freemium';
  }

  setBasic(){
    document.getElementById('freemium').setAttribute("class", "b_paquetes_i");
    document.getElementById('basico').setAttribute("class", "b_paquetes_a");
    document.getElementById('premium').setAttribute("class", "b_paquetes_i");
    this.plan_selected = 'basico';
  }

  setPremium(){
    document.getElementById('freemium').setAttribute("class", "b_paquetes_i");
    document.getElementById('basico').setAttribute("class", "b_paquetes_i");
    document.getElementById('premium').setAttribute("class", "b_paquetes_a");
    this.plan_selected = 'premium';
  }

  setVigencia(event){
    let vigencia: number = parseInt(event.target.attributes.name.value);
    if([12,24].includes(vigencia)){
      this.orden.vigencia = vigencia;
      this.orden_show.vigencia = vigencia + " meses";
      if(vigencia == 12){
        document.getElementById('v1').setAttribute("class", "b_active");
        document.getElementById('v2').setAttribute("class", "b_inactive");
      }else{
        document.getElementById('v1').setAttribute("class", "b_inactive");
        document.getElementById('v2').setAttribute("class", "b_active");
      }
    }
    this.reset_data();
  }

  setPago(event){
    let pago: string = event.target.attributes.name.value;
    let id: string = event.target.attributes.id.value;
    if(['Tarjeta','Oxxo','Transferencia'].includes(pago)){
      this.orden.tipo_pago = pago;
      this.orden_show.tipo_pago = pago;
      this.botones_pago.forEach(boton => {
        document.getElementById(boton).setAttribute("class", "b_inactive");
      });
      document.getElementById(id).setAttribute("class", "b_active");
    }
    this.reset_data();
  }

  removePago(){
    this.orden.tipo_pago = '';
    this.orden_show.tipo_pago = '';
    this.reset_data();
  }

  removeVigencia(){
    this.orden.vigencia = '';
    this.orden_show.vigencia = '';
    this.reset_data();
  }

  removePaquete(){
    this.orden.paquete = '';
    this.orden_show.paquete = '';   
    this.reset_data();
  }

  comprar(){
    if(this.orden.tipo_pago!='' && this.orden.paquete!='' && this.orden.tipo_pago!=''){
      Swal.showLoading();
      let inicio = new Date();
      let expiracion = new Date(inicio.getFullYear(),this.orden.vigencia + Number(inicio.getMonth()),inicio.getDate(),inicio.getHours(),inicio.getMinutes(),inicio.getSeconds());
      
      this.orden.fecha_inicio =''+inicio.getFullYear()+'-'+(1+Number(inicio.getMonth()))+'-'+inicio.getDate();
      this.orden.fecha_expiracion = ''+expiracion.getFullYear()+'-'+(1+Number(expiracion.getMonth()))+'-'+expiracion.getDate();
      this.orden.id_secciones = (this.orden.paquete == 'basico') ? 2 : (this.orden.paquete == 'premium') ? 3 : ''

      console.log(this.orden);

      this.tiendaService.postOrden(this.token, this.orden).subscribe((response: any) => {
        if(response.message == 'Ok'){ 
          this.orden.id_license = response.id_license;
          console.log(response);
          Swal.close();
        } 
      }, error => {
        Swal.fire({
          type: 'error',
          title: error.error.message,
          showConfirmButton: false,
          timer: 4000
        });
      });
      this.formCompra = true;
    }

  }
  ValidateSetCompra(){
    this.validacionForm="";
    if(this.orden_data.nombre.length==0){
      this.validacionForm+="*Falta escribir el nombre. <br>"
    }
    if(!this.orden_data.correo.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
      this.validacionForm+="*Correo no valido. <br>"
    }
    if(this.orden_data.telefono.length<10){
      this.validacionForm+="*El número telefónico debe ser de 10 dígitos. <br>"
    }
    if(this.pais_id==24 && (this.orden_data.RFC.length<10 || this.orden_data.RFC.length>13)){
      this.validacionForm+="*RFC no valido. <br>"
    }
    if(this.orden_show.tipo_pago == "Tarjeta"){
      if(this.cardParams.card.name.length==0){      
        this.validacionForm+="*Falta escribir el nombre del titular. <br>"
      }
      if(!this.cardParams.card.number.match(/^([0-9])*$/)){
        this.validacionForm+="*Número de tarjeta no valido. <br>"
      }
      if(!this.cardParams.card.cvc.match(/^([0-9])*$/) || (this.cardParams.card.cvc.length<3 || this.cardParams.card.cvc.length>4)){
        this.validacionForm+="*CVC no valido. <br>"
      }
      if(!this.cardParams.card.exp_month.match(/^([0-9])*$/) || this.cardParams.card.exp_month.length!=2 || this.cardParams.card.exp_month>12){
        this.validacionForm+="*Mes no valido. <br>"
      }
      if(!this.cardParams.card.exp_year.match(/^([0-9])*$/) || this.cardParams.card.exp_year.length!=4){
        this.validacionForm+="*Año no valido. <br>"
      }
    }
    if(this.validacionForm==""){
      this.setCompra();
    }
  }

  setCompra(){
    Swal.showLoading();
    var successHandler = function(token) {
      console.log(token);
      localStorage.setItem('conekta_id', token.id);
    };
     
    var errorHandler = function(err) {
      console.log(err);
      Swal.fire('Error con la tarjeta',err.message_to_purchaser,'error'); 
    };

    this.orden_data.RFC = (this.pais_id != 24) ? " " : this.orden_data.RFC;
    this.orden.datos_us = this.orden_data;
     
    if(this.orden.tipo_pago=="Tarjeta"){
      Conekta.Token.create(this.cardParams, successHandler, errorHandler);
    }

    setTimeout(() => {
      this.enviar();
    }, 2000);
  }

  enviar(){
    let tok_id;
    if(this.orden.tipo_pago=="Tarjeta"){
      tok_id = localStorage.getItem('conekta_id');
      localStorage.removeItem('conekta_id');
      this.orden.conektaId = tok_id;
    }
    console.log(this.orden);
    //  console.log(this.orden_data);
    //  console.log(this.cardParams);
    
    this.tiendaService.postPago(this.token, this.orden).subscribe((response: any) => {
      if(response.message == 'Ok'){ 
        console.log(response);
        localStorage.removeItem('token');
        localStorage.setItem('token', response.token);
        Swal.fire({
          title: 'Compra realizada',
          showConfirmButton: false,
          timer: 2000,
          type: 'success'
        });
        setTimeout(()=>{ 
          //una vez validado los datos con Conekta y regresado e JSON con la info, redigir a recibo
          this.router.navigate(['recibo-de-pago'], { queryParams: { checkout: JSON.stringify(response.pago)}});
        }, 2000);
      } 
    }, error => {
      Swal.fire({
        type: 'error',
        title: error.error.message,
        showConfirmButton: false,
        timer: 4000
      });
    });
  }

  constructor(private tiendaService: TiendaService, private router: Router,private detalleService:DetalleService) { 
    Conekta.setPublicKey("key_FrdDyz2MvcYZByyxzyA31iA");
    Conekta.setLanguage("es");
  }

  reset_data(){
    localStorage.removeItem('conekta_id');
    this.formCompra = false;
    this.cardParams = {
      "card": {
        "number": "",
        "name": "",
        "exp_year": "",
        "exp_month": "",
        "cvc": "",
        "address": {
            "street1": "",
            "street2": "",
            "city": "",
            "state": "",
            "zip": "",
            "country": ""
         }
      }
    };
    this.orden_data = {
      nombre: "",
      correo: "",
      telefono: "",
      factura: false,
      RFC: ""
    };
  }

  ngOnInit() {
    let top = document.getElementById('top');
    this.GoTo="T";
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    
    this.showBlock = false;
    this.orden = {
      id_license: 0,
      id_secciones: 1,
      paquete: '',
      vigencia: '',
      tipo_pago: '',
      fecha_inicio: '',
      fecha_expiracion: '',
      cantidad: 100,
      datos_us: {},
      conektaId: ''
    };
    this.orden_show = {
      paquete: '',
      vigencia: '',
      tipo_pago: ''
    };

    this.formCompra = false;
    if(this.token==null || this.token==undefined || this.token==""){
      this.nontoken = true;
    }else{
      this.nontoken = false;
      this.tiendaService.getLicencia(this.token).subscribe((response:any)=>{
        this.pais_id = response.pais_id;
        if(response.licencias && response.licencias.length>0){
          Swal.fire({
            html: '<h3> Ya cuentas con una compra pendiente de pago, ¿deseas volver a crear la orden de pago?</h3>',
            showCancelButton: true,
            confirmButtonColor: '#0098CE',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText:'Cancelar'
          }).then((result) => {
              if (result.value) {
                let year = new Date().getFullYear();
                this.orden = response.licencias[0];
                this.orden.id_license = response.licencias[0].id;
                this.orden.paquete = (response.licencias[0].licencia == 'Paquete basico') ? 'basico' : 'premium';
                this.orden.vigencia = (year+1 == response.licencias[0].fecha_expiracion.substring(0,4)) ? 12 : 24;
                this.orden_show.paquete = (this.orden.paquete=='basico') ? 'Paquete básico' : 'Paquete premium';
                this.orden_show.vigencia = this.orden.vigencia + " meses";
                this.orden_show.tipo_pago = this.orden.tipo_pago;
                this.orden_data.nombre = response.dat_fact[0].razon_social;
                this.orden_data.correo = response.dat_fact[0].email;
                this.orden_data.telefono = response.dat_fact[0].telefono;
                this.orden_data.RFC = response.dat_fact[0].rfc;
                this.formCompra = true;
              }
          });
        }
      });
    }
    this.reset_data();
    this.plan_selected = 'freemium';
    this.sections = [
      { 
        title: 'Videojuegos TAK-TAK-TAK', 
        description: `Accede al contenido descriptivo de cada uno de los  videojuegos de TAKTAKTAK. <br> 
        Encontrarás: <br> 
        - fichas pedagógicas de cada videojuego <br> 
        - habilidades y competencias que apoya <br> 
        - propuestas de rutinas didácticas para utilizarlo en clase <br> 
        - video tutorial para conocer el videojuego sin necesidad de jugarlo.`,
        plan: 'freemium'
      },
      { 
        title: 'Blog', 
        description: `Participa en nuestra comunidad y aprovecha los contenidos y noticias sobre diferentes temas relacionados con la educación y tu práctica docente. `,
        plan: 'freemium'
      },
      { 
        title: 'Buscador de recursos digitales', 
        description: `Encuentra recursos digitales de calidad para tu planeación didáctica. Seleccionados y organizados por nivel escolar, por conceptos que manejan y asignaturas que apoyan. <br> 
        Desde videos, audios, y artículos, hasta vínculos a otro sitios de actividades y contenidos gratuitos.`,
        plan: 'basico'
      },
      { 
        title: 'Formato de planeación libre', 
        description: 'Realiza tu planeación didáctica utilizando los formatos digitales de LabTak. Listos para descargarse como archivo PDF o para imprimirse.',
        plan: 'basico'
      },
      { 
        title: 'Formato de planeación SEP', 
        description: 'Utiliza el formato digital alineado al programa curricular de la SEP Reforma 2011 y SEP Reforma 2017',
        plan: 'basico'
      },
      { 
        title: 'Módulos TAK-TAK-TAK', 
        description: `Aprovecha los Módulos TAKTAKTAK de actividades que apoyan los diferentes campos de formación para desarrollar habilidades de: <br> 
        - Pensamiento crítico y resolución de problemas <br> 
        - Creatividad <br> 
        - Investigación <br> 
        - Innovación y tecnología  <br> 
        - Educación socioemocional. <br> 
        Los módulos contienen 8 actividades que se realizan en 2 sesiones, con duración de 50 min. cada una. Se combinan actividades presenciales con los videjuegos de TAK-TAK-TAK. <br> 
        Son actividades creativas y lúdicas donde el videojuego es el detonador de aprendizajes.`,
        plan: 'basico'
      },
      { 
        title: 'Planeaciones por sesión SEP', 
        description: `Utiliza  las planeaciones diseñadas por el equipo educativo LabTak con actividades puntuales para una sesión de clase de 45min de duración. <br> 
        Actividades alineadas al programa de educación SEP que apoyan diferentes aprendizajes clave en las asignaturas de: <br> 
        - Matemáticas <br> 
        - Español <br> 
        - Ciencias <br> 
        - Educación artística <br> 
        - Educación socioemocional <br> 
        entre otras.`,
        plan: 'premium'
      },
      { 
        title: 'Planeaciones por sesión especializadas', 
        description: `Aprovecha la oportunidad de enseñar utilizando  los Módulos de actividades desarrollados con materiales y recursos educativos de instituciones culturales como museos y organizaciones educativas aliadas: <br> 
          Música en México <br> 
          APAC <br> 
          Museo Carrillo Gil <br> 
          entre otras.`,
        plan: 'premium'
      },
      { 
        title: 'Reportes de uso de videojuegos TakTakTak (ReporTak)', 
        description:  `Enriquece tu labor docente utilizando los Reportes sobre la actividad de tus alumnos al jugar con TAKTAKTAK. <br> 
          Ofrecen información relativa a los videojuegos utilizados como tiempos, sesiones, y  niveles alcanzados. Así como la vinculación de la actividad de los videojuegos con las asignaturas que apoyan. <br> 
          Puedes descargar los reportes como archivos PDF o para impresión. `,
        plan: 'basico'
      },
      { 
        title: 'Creación de grupos', 
        description:  `Crea las listas de tus alumnos para el mejor control de nombres y usuarios que utilizan los videojuegos de TAK-TAK-TAK. `,
        plan: 'basico'
      },
      { 
        title: 'Foro', 
        description:  `Colabora en los Foros para intercambiar ideas, opiniones, contenidos y experiencias con otros maestros, padres de familia y personas dedicadas a la educación.`,
        plan: 'basico'
      },
      { 
        title: 'Webinars', 
        description:  `Participa en conferencias, webinars, conversatorios sobre diferentes temas educativos y de tecnología aprovechando la presencia de especialistas y expertos invitados.`,
        plan: 'premium'
      },
      { 
        title: 'Capacitación docente básica', 
        description:  `Complementa tu formación docente con los cursos de capacitación que ofrecemos en diferentes temas sobre educación y tecnología.`,
        plan: 'premium'
      },
      { 
        title: 'Cursos especializados en educación y tecnología', 
        description:  `  <br>  `,
        plan: 'premium'
      }
    ]
  }

}
