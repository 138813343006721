import { Component, OnInit } from '@angular/core';
import { ReportakService } from '../service/reportak.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Chart } from 'chart.js';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as html2pdf from 'html2pdf.js';
import { Navegacion } from '../models/navigate';
import { DetalleService } from '../service/detalle.service';

@Component({
  selector: 'app-reportak-videojuegos',
  templateUrl: './reportak-videojuegos.component.html',
  styleUrls: ['./reportak-videojuegos.component.css']
})
export class ReportakVideojuegosComponent implements OnInit {

  private token: string = localStorage.getItem('token');
  private userData: any = [];
  private planes: any[];
  private grupos: any[];
  private games: any[];
  private accessLink: '';
  private alumnos = [];
  private periodos = {mensuales:[], quincenales:[]};
  private periodo: any[] = [];
  private periodoSelected:string;
  private mensual: boolean = true;
  private alumnoSelected: any;
  private alumnoSelectedNombre = [];
  private reporteVideojuegos: any[];

  public navegacion = new Navegacion();

  private modalOptions:NgbModalOptions;

  private chart = [];
  private chart1 = [];

  public p = 0;
  public camposSelected = 0;
  public planSelected ;

  //pdf
  private reporteVideojuegosChart: any[];
  private pdfReportF: boolean;
  private gameCharts: number;
  private pl: any;
  private gr: any;

  private onPlanChange:any = (e) => {
    //console.log("onPlanChange", e);
    this.planes.forEach((item, index) => {
      if(item.id == e.target.value){
        this.pl = item.name;
        this.planSelected = item.id;
        if(item.grupos.length > 0){
          this.grupos = item.grupos;
          this.games = item.games;
          this.gr = this.grupos[0].access_link.substring(this.accessLink.length-2);
          this.getAlumnos(this.token, this.grupos[0].access_link);
        }else{
          Swal.fire(" ", "Este plan no tiene grupos asignados", "error");
        }
      }
    });
  }

  private getAlumnos:any = (token, grupo) => {
    
    this.reporTakService.getAlumnos(token, grupo).subscribe((response: any) => {
      if(response.status == 200 && response.body.message === 'Ok'){
        if(response.body.alumnos.length){
          this.alumnos = response.body.alumnos;
          this.alumnoSelected = response.body.alumnos[0];
          this.alumnos.forEach(al => {
            if(al.player_id == this.alumnoSelected.player_id) this.alumnoSelectedNombre = al;
          });
          this.getPeriodos(this.token, grupo, response.body.alumnos);
        }else{
          this.alumnos = [];
          Swal.fire(" ", "Este grupo no tiene alumnos asociados", "error");
        }
      }else{
        this.alumnos = [];
      }
    });

  }

  private getPeriodos:any = (token, grupo, alumnos) => {

    this.reporTakService.getPeriodos(token, grupo, alumnos).subscribe((response: any) => {
      if(response.status == 200 && response.body.message === 'Ok'){
        this.periodos = response.body.periodos;
        this.periodo = this.mensual ? this.periodos.mensuales : this.periodos.quincenales;
        this.periodoSelected = this.periodo[0].date_start;
        this.alumnoSelected = this.alumnos[0];
        this.getReporte(this.token, this.alumnoSelected, this.periodo[0].date_start);
      }
      else{
        this.periodos = {mensuales:[], quincenales:[]};
      }
    });

  }

  private getReporte:any = (token, alumno, periodo) => {
    Swal.showLoading();
    this.p = 0;
    this.reporTakService.registrarActividad(token, this.accessLink, this.planSelected, periodo, this.navegacion.seccion).subscribe();
    this.reporTakService.getReporteVideojuegos(token, alumno, periodo, this.games).subscribe((response: any) => {
      //console.log("getReporteVideojuegos", response);
      if(response.status == 200 && response.body.message === 'Ok'){
        this.reporteVideojuegos = response.body.data.materias;
        this.reporteVideojuegos.forEach(reporte => { 
          reporte.expanded = false;
          let time = 0;
          reporte.reporte.forEach(t => {
            time = time + t.tiempo;
          });
          reporte.tiempoTotal = time.toFixed(0);
        });
        //pdf
        this.reporteVideojuegosChart = response.body.data.materias;
        this.reporteVideojuegosChart.forEach(report => { 
          report.collapse = false;
          let time = 0;
          report.reporte.forEach(t => {
            time = time + t.tiempo;
          });
          report.tiempoTotal = time.toFixed(0);
        });
        //console.log("REPORTE", this.reporteVideojuegos);
        if(this.reporteVideojuegos.length==0){
          Swal.fire(" ","El usuario no cuenta con actividad en el periodo seleccionado.", "warning");
        }
        else Swal.close();
      }else{
        Swal.fire(" ","No se pudo obtener información con los filtros utilizados", "warning");
        this.reporteVideojuegos = [];
      }
      //Swal.close();
    });

  }

  private onGrupoChange:any = (e) => {
    this.getAlumnos(this.token, e.target.value);
    this.gr=this.accessLink.substring(this.accessLink.length-2);
  };

  private onAlumnoChange:any = (e) => {
    this.alumnos.forEach(al => {
      if(al.player_id == e.target.value) {
        this.alumnoSelectedNombre = al; 
        this.alumnoSelected = {player_id: e.target.value, fullname: al.fullname, fullname_ts: al.fullname_ts};
      }
    });
    this.getReporte(this.token, this.alumnoSelected, this.periodoSelected);
  };

  private handlePeriodo:any = (periodo) => {
    this.mensual = periodo === 'm';
    this.periodo = periodo === 'm' ? this.periodos.mensuales : this.periodos.quincenales;
    this.periodoSelected = this.periodo[0].date_start + (periodo === 'q' ? ('-' + this.periodo[0].quincena) : '');
    this.getReporte(this.token, this.alumnoSelected, this.periodo[0].date_start + (periodo === 'q' ? ('-' + this.periodo[0].quincena) : ''));
  }

  private handleSelectPeriodo:any = (event) => {
    //console.log("handleSelectPeriodo", event.target.value);
    this.periodoSelected = event.target.value;
    this.getReporte(this.token, this.alumnoSelected, event.target.value);
  }

  getGraficaAsignature: any = (asignature_id) => {
    this.reporTakService.getGraficaAsignatura(this.token, this.alumnoSelected.player_id, this.periodoSelected, asignature_id, this.games).subscribe((response: any) => {
      //console.log("getGraficaAsignatura", response);
      if(response.status == 200 && response.body.message === 'Ok'){
        this.chart = new Chart(asignature_id, {
          type: 'doughnut',
          data: {
            datasets: [{
              data: response.body.data.values,
              backgroundColor: response.body.data.colors
            }],
            labels: response.body.data.labels
          },
          options: { 
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'left',
              display: true
            },
            title: {
              display: false,
              text: 'Chart.js Doughnut Chart'
            },
            animation: {
              animateScale: true,
              animateRotate: true
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItems, data) {
                  return  data.labels[tooltipItems.index] + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]+ '%';
                }
              }
            }
          },
        });
      }
    });
  }

  getGraficaJuego:any = (juego, nombre) => {

    this.reporTakService.getGraficaIAJuego(this.token, this.alumnoSelected.player_id, this.periodoSelected, juego).subscribe((response: any) => {
      //console.log("getGraficaIAJuego", response);
      if(response.status == 200 && response.body.message === 'Ok'){
        this.chart1 = new Chart(nombre, {
          type: 'bar',
          data: {
            datasets: [{
              data: response.body.data.values,
              backgroundColor: response.body.data.colors
            }],
            labels: response.body.data.labels
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'left',
              display: false
            },
            scales: {
              yAxes: [{
                ticks:{
                  beginAtZero:true,
                  min: 0,
                  max: 3,
                  stepSize: 1,
                  callback: ((val:any) => Number.isInteger(val) ? val : null)
                },
                stacked: true
              }]
            },
            title: {
              display: false,
              text: 'Chart.js Doughnut Chart'
            },
            animation: {
              animateScale: true,
              animateRotate: true
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItems, data) {
                  return  "IA: " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
                }
              }
            }
          },
        });
      }
    });
  }

  private filterItemsOfType(status){
    if(this.grupos && this.grupos.length){
      return this.grupos.filter(x => x.status == status);
    }else{
      return false;
    }
  }


  changeStatus:any = async () => {
    let count:number = 0;
    await this.reporteVideojuegosChart.forEach(reporte => { 
      reporte.collapse = true; 
      reporte.reporte.forEach(juego => {
        juego.collapse = true;
        count++;
      });
    });
    this.gameCharts = count;
    this.pdfReportF = true;
  }

  async generatePdf(){
    this.navegacion.seccion = 22;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
  if(this.reporteVideojuegosChart.length>0)
   {
      Swal.showLoading();

      await this.changeStatus();
      this.reporteVideojuegosChart.forEach(reporte => {
        this.fillCharts(reporte.asignature_id+'-');
        reporte.reporte.forEach(juego => {
          this.fillGameCharts(juego.game_id, reporte.asignature_id+'-'+juego.title+'-');
        }); 
      });

      
     setTimeout(() => {Swal.close();},20000);
   }
  else
    {
      Swal.fire(" ","No existe información para generar los reportes", "warning");
    }
  }

  async preparar(){
    //console.log(this.pl);
    //console.log(this.gr);
    //console.log(this.alumnoSelected);
    let header:Element = document.createElement("div");
    let reportak = document.getElementById("reportakescondido").cloneNode(true);
    header.appendChild(reportak);

    //Detalles del reporte
    let col1:Element = document.createElement("div");
    col1.classList.add("col-6");
    let plan:Element = document.createElement("div");
    plan.innerHTML = "<b>Planeación: </b>"+this.pl;
    col1.appendChild(plan);
    let tipo:Element = document.createElement("div");
    tipo.innerHTML = "<b>Reporte por: </b> Videojuegos";
    col1.appendChild(tipo);
    let grupo:Element = document.createElement("div");
    grupo.innerHTML = "<b>Grupo: </b>"+this.gr;
    col1.appendChild(grupo);
    let periodo:Element = document.createElement("div");
    periodo.innerHTML = "<b>Periodo: </b>"+this.periodoSelected;
    col1.appendChild(periodo);
    let alumno:Element = document.createElement("div");
    alumno.innerHTML = "<b>Alumno: </b>"+ this.alumnoSelected.fullname;
    col1.appendChild(alumno);
    let col2:Element = document.createElement("div");
    col2.classList.add("col-6");
    let docente:Element = document.createElement("div");
    docente.innerHTML = "<b>Docente: </b>"+this.userData.name+' '+this.userData.lastname;
    col2.appendChild(docente);
    let escuela:Element = document.createElement("div");
    (this.userData.centro_educativo==null) ? escuela.innerHTML = "<b>Escuela: </b>"
      : escuela.innerHTML = "<b>Escuela: </b>"+this.userData.centro_educativo;
    col2.appendChild(escuela);
    let cct:Element = document.createElement("div");
    (this.userData.cct==null) ? cct.innerHTML = "<b>CCT: </b>"
      : cct.innerHTML = "<b>CCT: </b>"+this.userData.cct;
    col2.appendChild(cct);
    let row:Element = document.createElement("div");
    row.classList.add("row");
    row.appendChild(col1);
    row.appendChild(col2);
    header.appendChild(row);

    let bloque = document.createElement("div");
    bloque.appendChild(header);

    let array_bloques = [];

    this.reporteVideojuegosChart.forEach(reporte => {
      let megablock: Element = document.createElement("div");
      let asignaturaHeader = document.getElementById(reporte.asignature_id+'h');
      let nodeTemp = asignaturaHeader.cloneNode(true);
      megablock.appendChild(nodeTemp);
      let asignaturaChart = document.getElementById(reporte.asignature_id+'--');
      megablock.appendChild(asignaturaChart);
      //Videojuegos
      let bloquetemp:Element = document.createElement("div");
      reporte.reporte.forEach(juego => {
        //let juegoChartIndi = document.getElementById(reporte.asignature_id+'-'+juego.title+'indic').cloneNode(true);
        //bloquetemp.appendChild(juegoChartIndi);
        let juegonombrediv:Element = document.createElement("div");
        juegonombrediv.innerHTML = "<b>Videojuego: </b>"+juego.title;
        bloquetemp.appendChild(juegonombrediv);
        let juegoChart:Element = document.getElementById(reporte.asignature_id+'-'+juego.title);
        bloquetemp.appendChild(juegoChart);
      });
      megablock.appendChild(bloquetemp);
      let breakElement = document.createElement("div");
      breakElement.classList.add("html2pdf__page-break");
      megablock.appendChild(breakElement);
      array_bloques.push(megablock);
    });
    let indicadores = document.getElementById("indicador");
    let nodeIndicadores = indicadores.cloneNode(true);
    bloque.appendChild(nodeIndicadores);

    const options = {
      margin: [1.5,2,1.5,2],
      filename: 'Reportak-Videojuegos.pdf',
      image: { type: 'jpeg' },
      html2canvas: {
        dpi: 300,
        letterRendering: true,
        useCORS: true
      },
      jsPDF: { unit: 'cm', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    }

    let worker = html2pdf()
    .set(options)
    .from(bloque);
    worker = worker.toPdf() // worker is now a jsPDF instance
    array_bloques.forEach((element, index) => {
      worker = worker
        .get('pdf')
        .then(pdf => {
          pdf.addPage()
        })
        .from(element)
        .toContainer()
        .toCanvas()
        .toPdf()
    })

    worker = worker.get('pdf').then(function (pdf) {
      var totalPages = pdf.internal.getNumberOfPages();

      for (var i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor("#00bae0");
        pdf.setFontType("bold");
        pdf.text('ReporTak | LabTak®', pdf.internal.pageSize.getWidth()/2 - 1.5, pdf.internal.pageSize.getHeight() - 1.5);
        pdf.setFontType("normal");
        pdf.setTextColor("#00bae0");
        pdf.text('Página ' + i + ' de ' + totalPages, pdf.internal.pageSize.getWidth()/2 - 1, pdf.internal.pageSize.getHeight() - 1);
      } 
    }).save();

    this.pdfReportF = false;
  }

  done:any = () => {
    this.counter.increment();
    if(this.counter.value() == this.reporteVideojuegosChart.length){
      this.doneAll();
    }
  }

  doneGames:any = () => {
    this.counter.incrementGames();
    if(this.counter.valueGames() == this.gameCharts){
      this.doneAll();
    }
  }

  doneAll:any = () => {
    if(this.counter.value() == this.reporteVideojuegosChart.length &&
      this.counter.valueGames() == this.gameCharts
    ){
      setTimeout(()=>this.preparar(), 5000);
    }
  }

  counter = (function() {
    var privateCounter = 0;
    var privateCounterGames = 0;
    function changeBy(val) {
      privateCounter += val;
    }
    function changeByGames(val) {
      privateCounterGames += val;
    }
    return {
      increment: function() {
        changeBy(1);
      },
      incrementGames: function() {
        changeByGames(1);
      },
      value: function() {
        return privateCounter;
      },
      valueGames: function() {
        return privateCounterGames;
      }
    }
  })();

  fillCharts: any = (asignature_id) => {
    this.reporTakService.getGraficaAsignatura(this.token, this.alumnoSelected.player_id, this.periodoSelected, asignature_id, this.games).subscribe((response: any) => {
      //console.log("getGraficaAsignatura", response);
      if(response.status == 200 && response.body.message === 'Ok'){
        this.chart = new Chart(asignature_id, {
          type: 'doughnut',
          data: {
            datasets: [{
              data: response.body.data.values,
              backgroundColor: response.body.data.colors
            }],
            labels: response.body.data.labels
          },
          options: { 
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'left',
              display: true
            },
            title: {
              display: false,
              text: 'Chart.js Doughnut Chart'
            },
            animation: {
              animateScale: true,
              animateRotate: true,
              onComplete: this.done()
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItems, data) {
                  return  data.labels[tooltipItems.index] + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]+ '%';
                }
              }
            }
          },
        });
      }
    });
  }

  fillGameCharts:any = (juego, nombre) => {

    this.reporTakService.getGraficaIAJuego(this.token, this.alumnoSelected.player_id, this.periodoSelected, juego).subscribe((response: any) => {
      console.log("getGraficaIAJuego", response);
      if(response.status == 200 && response.body.message === 'Ok'){
        this.chart1 = new Chart(nombre, {
          type: 'bar',
          data: {
            datasets: [{
              data: response.body.data.values,
              backgroundColor: response.body.data.colors
            }],
            labels: response.body.data.labels
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'left',
              display: false
            },
            scales: {
              yAxes: [{
                ticks:{
                  beginAtZero:true,
                  min: 0,
                  max: 3,
                  stepSize: 1,
                  callback: ((val:any) => Number.isInteger(val) ? val : null)
                },
                stacked: true
              }]
            },
            title: {
              display: false,
              text: 'Chart.js Doughnut Chart'
            },
            animation: {
              animateScale: true,
              animateRotate: true,
              onComplete: this.doneGames()
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItems, data) {
                  return  "IA: " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
                }
              }
            }
          },
        });
      }
    });
  }

  constructor(private reporTakService: ReportakService, private modalService: NgbModal, private router: Router,   private activatedRoute: ActivatedRoute, private detalleService: DetalleService){
    this.navegacion.seccion = 18;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
    
    this.modalOptions = {
      backdrop: 'static',
      size: 'lg',
      centered: true
    }

    this.reporTakService.getPerfil(this.token).subscribe((response: any) => {
      if(response.message == 'Ok'){
        this.userData = response.user_data;
      }
    });

    this.reporTakService.getPlanes(this.token).subscribe((response: any) => {
      if(response.message === 'OK'){
        //console.log("getPlanes", response);
        if(response.planes.length > 0){
          this.planes = response.planes;
          this.grupos = response.planes[0].grupos;
          this.games = response.planes[0].games;
          if( response.planes[0].grupos.length >0 ){ 
            let ig = 0;
            response.planes[0].grupos.forEach((g,index) => {
              if(g.status==1 && ig==0){
                this.accessLink = response.planes[0].grupos.length ? response.planes[0].grupos[index].access_link : '';
                this.gr=this.accessLink.substring(this.accessLink.length-2);
                this.pl = response.planes[0].name;
                this.planSelected = response.planes[0].id;
                this.getAlumnos(this.token, response.planes[0].grupos[index].access_link);
                ig=1;
              }
            });
          }
          else{
            Swal.fire(" ","Este plan no tiene grupos asignados", "error");
          }
        }
      }
    });

  }

  ngOnInit() {
    let top = document.getElementById('top');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }
    // this.activatedRoute.params.subscribe(
    //   (params: Params) => {
    //     this.planSelected = params.plan;
    //     this.periodoSelected = params.periodo;
    //     this.accessLink = params.grupo; 
    // });

    // if(this.planSelected!= '' && this.periodoSelected!='' && this.accessLink!= '' )
    //   this.camposSelected = 1;
  }

}
