import { Component, OnInit, HostBinding, ElementRef } from '@angular/core';
import { imagenes } from '../service/config';
import { GameService } from '../service/game.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DetalleService } from '../service/detalle.service';
import { OtrosRecursos } from '../models/recursos';
import { Game, GameAbility, GamePrograma, GameRecurso, GameRuta, GameAsignatura, GameConceptos, GameEtapas } from '../models/game'
import { DropEvent } from 'ng-drag-drop';
import {CrearPlanes, JuegosRecursosSesion} from '../models/planes';
import InfoGames from 'src/assets/JSON/infoGames.json';
import { Navegacion } from '../models/navigate';
import {Games, Resources, End} from '../models/navigation';
import {Search,Provisional} from '../models/search';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { UserService } from '../service/user.service';

declare var dragula: any;

declare var $: any;

@Component({
  selector: 'app-nuevo-plan',
  templateUrl: './nuevo-plan.component.html',
  styleUrls: ['./nuevo-plan.component.css']
})


export class NuevoPlanComponent implements OnInit  {

  private token: string = localStorage.getItem('token');
  private tags: string;
  public urlImg: string = imagenes.url;
  public games = [];
  public gamesaux;
  public tagsFinales = "";
  public recursos = [];
  public recursosaux;
  public libros = [];
  public librosaux;
  public hometags;
  public grupos;
  public gamesHTML2: any[] = [];
  public gamesHTML2Aux: any[] = [];
  public docsHTML2: any[] = [];
  public docsHTML2Aux: any[] = [];
  public tagsFijos: string;

  public selected = 1;
  public nombrePlan: string;
  public descripcion: string;
  private id_plan: string;
  private slug_plan: string;
  public misplanes = [];
  
  public sesionesinfo ;
  public juegosRecursos = new JuegosRecursosSesion();


  public otrosrecursos = new OtrosRecursos();
  public game = new Game();
  public gameAbility = new GameAbility();
  public gamePrograma = new GamePrograma();
  public gameRecurso = new GameRecurso();
  public gameRuta = new GameRuta();
  public gameAsignatura = new GameAsignatura();
  public gameConceptos = new GameConceptos();
  public gameEtapas = new GameEtapas();
  public planes = new CrearPlanes();
  public idDescripcion;
  public idHabilidad;
  public idOtrosRecursos;
  public idProgramas;
  public rutasTotales;

  public descripciones: any[] = [];
  public habilidades: any[] = [];
  public rutas: any[] = [];
  public programas: any[] = [];
  public otros_recursos: any[] = [];

  public auxList2 = [];
  public list2 = [];
  public auxList3 = [];
  public list3 = [];
  public auxList4 = [];
  public list4 = [];

  public dataGame;
  public gameSelected;
  public navegacion = new Navegacion();
  public navigateGame = new Games();
  public navigateResource = new Resources();
  public endNavigate = new End();
  public busqueda = new Search();
  public provisional = new Provisional();
  public words:any[] =[];
  public sugerencias;

  public resultado_busqueda = "";

  public asignaturarec;  
  public asignatura: string;
  public nivel: string;
  // loadAPI: Promise<any>;
  public selected_asignatura = 0;
  public selected_grado = 0;

  public planselected = [];
  public idplanselected;

  public id;
  public slug;
  public info;
  public idRuta;
  public gamess;
  public articulos;
  public recursoss;
  public gruposs;
  public libross;
  public classrooms;
  public letra;
  public grupoplanes;
  public gruposModal;

  public videoyt = false;
  public videourl = 'https://www.youtube.com/embed/';
  urlSafe: SafeResourceUrl;

  public titlegames = false;
  public titlerecursos = false;

  p: number = 1;
  c: number = 1;
  l: number = 1;
  
  private sections: any;

  BuscaTagsNivel: Array<any> = [
    { tag: 'Todos', value: 0},
    { tag: 'Primero de primaria',  value: 1 },
    { tag: 'Segundo de primaria',  value: 2 },
    { tag: 'Tercero de primaria',  value: 3 },
    { tag: 'Cuarto de primaria',  value: 4 },
    { tag: 'Quinto de primaria',  value: 5 },
    { tag: 'Sexto de primaria',  value: 6 },
  ];
  BuscaTagsAsig: Array<any> = [
    { tag: 'Todos', value: 0},
    { tag: 'Química',  value: 1 },
    { tag: 'Historia',  value: 2 },
    { tag: 'Biología',  value: 3 },
    { tag: 'Artes visuales',  value: 4 },
    { tag: 'Matemáticas',  value: 5 },
    { tag: 'Ecología',  value: 6 },
    { tag: 'Música',  value: 7 },
    { tag: 'Desarrollo socioemocional',  value: 8 },
    { tag: 'Física',  value: 9 },
    { tag: 'Lenguaje y comunicación',  value: 10 },
    { tag: 'Desarrollo cognitivo',  value: 11 },
    { tag: 'Geografía',  value: 12 },
    { tag: 'Formación cívica y ética',  value: 13 },
  ];
  BuscaTagsTipo: Array<any> = [
    { tag: 'Todos', value: 0},
    { tag: 'Video',  value: 1 },
    { tag: 'Videojuegos',  value: 2 },
    { tag: 'PDF',  value: 3 },
    { tag: 'Imagen',  value: 4 },
    { tag: 'Página web',  value: 5 },
  ];

  constructor(private gameService: GameService, private route: ActivatedRoute, private userService: UserService,
    private router: Router, private detalleService: DetalleService, public sanitizer: DomSanitizer) {
      
      this.navegacion.seccion = 13;
      this.detalleService.navegacion(this.token, this.navegacion).subscribe();
  }

  agregarPorMas(event) {
    this.list2.push(event);
    this.games.splice(this.games.indexOf(event), 1);
    this.auxList2 = this.games;
    localStorage.setItem("games", JSON.stringify(this.auxList2));
  }

  agregarPorMasCont(event) {
    this.list3.push(event);
    this.recursos.splice(this.recursos.indexOf(event), 1);
    this.auxList3 = this.recursos;
    localStorage.setItem("documents", JSON.stringify(this.auxList3));
  }

  agregarLibro(event) {
    this.list4.push(event);
    this.libros.splice(this.libros.indexOf(event), 1);
    this.auxList4 = this.libros;
    //localStorage.setItem("books", JSON.stringify(this.auxList3));
  }

  ngOnInit() {

    this.selected_grado = this.route.snapshot.params.nivel; 
    this.selected_asignatura = this.route.snapshot.params.materia; 
    this.sections = this.userService.getSecciones();

    this.gameService.mostrarRecursos(this.token).subscribe(
      data => {
        this.recursosaux = data["message"];
        
      }
    ); 

    this.detalleService.mostrarPlanes(this.token).subscribe((response: any) => {
      this.misplanes = response.planes;

      if(this.route.snapshot.params.id != undefined){
        let i;
        this.id_plan = this.route.snapshot.params.id;
  
        for (let index = 0; index < this.misplanes.length; index++) {
            if(this.id_plan == this.misplanes[index].id){
              this.planselected = this.misplanes[index];
              i = index;
            }
        }
        
        this.slug_plan = this.misplanes[i].slug;
        this.detalleService.detallePlanes(this.token, this.id_plan, this.misplanes[i].slug).subscribe((response: any) => {
          this.gamess = response.plan.games;
          this.articulos = response.plan.contenidos.articulos;
          this.recursoss = response.plan.contenidos.recursos;
          this.libross = response.plan.contenidos.libros;
          this.otros_recursos = response.plan.contenidos.otros_recursos;
          this.habilidades = response.plan.contenidos.habilidades;
          this.descripciones = response.plan.contenidos.descripciones;
          this.classrooms = response.plan.contenidos.classroom;
          this.sesionesinfo = response.plan.sesiones;
          this.info = response.plan.info;
          if (this.info.is_template == undefined) {
            this.info.is_template = "1";
          }
          this.rutas = response.plan.contenidos.rutas;
          this.programas = response.plan.contenidos.programas;
          let primer_valor = 0
          for (let x of this.info.name) {
            if (primer_valor == 0) {
              this.letra = x;
              primer_valor = 1;
            }
          }
          this.list2 = this.gamess;
          this.list3 = this.recursoss;
          this.list4 = this.libross;
        });
      }

    });

    this.gameService.verJuegos(this.token).subscribe(
      data => {
        this.gamesaux = data["games"];
      }
    );

    this.gameService.mostrarLibros(this.token).subscribe((response: any) => {
        this.librosaux = response.message;
    });
    
    setTimeout(function () {
      // Search
      $(".js-modal-search").click(function (e) {
        e.preventDefault();
        $(".modal-search").modal("show");
      });
    }, 1500);

  }


  back() {
    this.selected = 1;
  }

  detailGame(slug) {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    this.gameSelected = undefined;
    this.selected = 2;
    this.gameService.verDetalle(this.token, slug).subscribe((response: any) => {
      this.game = response.ficha.game;
      this.navigateGame.game = this.game.id;
      this.navigateGame.place=2;
      this.detalleService.navegacionJuego(this.token,this.navigateGame).subscribe();
      this.dataGame = InfoGames.info;
      this.dataGame.forEach(element => {
        if(element.name == this.game.slug){
          this.gameSelected= element;
        }
      });
      this.gameAsignatura = response.ficha.asignatura;
      this.gameConceptos = response.ficha.conceptos;
      this.idDescripcion = response.ficha.descripcion.id;
      if (response.ficha.habilidades != null) {
        this.gameAbility = response.ficha.habilidades;
        const aprendizaje = JSON.parse(response.ficha.habilidades.aprendizaje);
        const modelodb = JSON.parse(response.ficha.habilidades.me);
        const imt = JSON.parse(response.ficha.habilidades.imt);
        const fms = JSON.parse(response.ficha.habilidades.fms);
        const ed = JSON.parse(response.ficha.habilidades.ed);
        this.gameAbility.modelos = {
          autoconocimiento: {
            conciencia: modelodb.autoconocimiento.conciencia,
            autoestima: modelodb.autoconocimiento.autoestima,
            aprecio: modelodb.autoconocimiento.aprecio,
            bienestar: modelodb.autoconocimiento.bienestar
          },
          autorregulacion: {
            meta: modelodb.autorregulacion.meta,
            expresion: modelodb.autorregulacion.expresion,
            regulacion: modelodb.autorregulacion.regulacion,
            generacion: modelodb.autorregulacion.generacion,
            perseverancia: modelodb.autorregulacion.perseverancia
          },
          autonomia: {
            iniciativa: modelodb.autonomia.iniciativa,
            soluciones: modelodb.autonomia.soluciones,
            eficacia: modelodb.autonomia.eficacia
          },
          empatia: {
            bienestar: modelodb.empatia.bienestar,
            perspectiva: modelodb.empatia.perspectiva,
            prejuicios: modelodb.empatia.prejuicios,
            sensibilidad: modelodb.empatia.sensibilidad,
            cuidado: modelodb.empatia.cuidado
          },
          colaboracion: {
            comunidad: modelodb.colaboracion.comunidad,
            responsabilidad: modelodb.colaboracion.responsabilidad,
            inclusion: modelodb.colaboracion.inclusion,
            resolucion: modelodb.colaboracion.resolucion,
            interdependencia: modelodb.colaboracion.interdependencia
          },
          ramas: {
            colaboracion: modelodb.ramas.colaboracion,
            empatia: modelodb.ramas.empatia,
            autonomia: modelodb.ramas.autonomia,
            autorregulacion: modelodb.ramas.autorregulacion,
            autoconocimiento: modelodb.ramas.autoconocimiento
          },
          modelo: modelodb.modelo
        };
        this.gameAbility.habilidades = {
          aprendizaje: {
            pensamiento: aprendizaje.pensamiento,
            comunicacion: aprendizaje.comunicacion,
            creatividad: aprendizaje.creatividad,
            colaboracion: aprendizaje.colaboracion
          },
          imt: {
            teclado: {
              nombre: imt.teclado.nombre,
              porcentaje: imt.teclado.porcentaje = imt.teclado.porcentaje
            },
            mouse: {
              nombre: imt.mouse.nombre,
              porcentaje: imt.mouse.porcentaje = imt.mouse.porcentaje
            },
            tableta: {
              nombre: imt.tableta.nombre,
              porcentaje: imt.tableta.porcentaje = imt.tableta.porcentaje
            }
          },
          fms: {
            atencion: fms.atencion,
            memoria: fms.memoria,
            lenguaje: fms.lenguaje,
            visoespacial: fms.visoespacial,
            sensoriomotoras: fms.sensoriomotoras,
            ejecutivas: fms.ejecutivas
          },
          ed: {
            visual: ed.visual,
            trabajo: ed.trabajo,
            informacion: ed.informacion,
            verbal: ed.verbal,
            auditiva: ed.auditiva
          },
          tags: response.ficha.habilidades.tags
        };
      }
      if (response.ficha.otros != null) {
        this.gameRecurso = response.ficha.otros;
      }
      if (response.ficha.programas != null) {
        this.gamePrograma = response.ficha.programas;
      }

      this.rutasTotales = response.ficha.rutas;
      if (response.ficha.habilidades.id != null){
        this.idHabilidad = response.ficha.habilidades.id;
      }
      if (response.ficha.otros.id != null){
        this.idOtrosRecursos = response.ficha.otros.id;
      }
      if (response.ficha.programas.id == null){
        this.idProgramas = response.ficha.programas.id;
      }
      this.ngOnInit();
    }, error => {
    });

  }

  detailResource(id) {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    this.detalleService.verDetalleRecurso(this.token, id).subscribe((response: any) => {
      this.navigateResource.place =2;
      this.navigateResource.recurso = response.message.id;
      this.detalleService.navegacionRecurso(this.token,this.navigateResource).subscribe();
      this.videoyt = false;
      this.otrosrecursos.id = response.message.id;
      this.otrosrecursos.nombre = response.message.nombre;
      this.otrosrecursos.link = response.message.link;
      this.otrosrecursos.descripcion = response.message.descripcion;
      this.otrosrecursos.status = response.message.status;
      this.otrosrecursos.tags = response.message.tags;
      this.otrosrecursos.img = response.message.image;
      this.otrosrecursos.pdf = response.message.pdf;
      this.otrosrecursos.video = response.message.video;
      if(this.otrosrecursos.video!='') this.incluirvideo(this.otrosrecursos.video);
      if (this.otrosrecursos.pdf == 'Si') {
        // this.PDF();
      }
      const regex = /\s*,\s*/;
      this.asignaturasCont(this.otrosrecursos.tags);
      this.niveles(this.otrosrecursos.tags);
      this.transformarLink(this.otrosrecursos.link);
    }, error => {
    });

    this.selected = 3;
  }

  PDF() {
    this.detalleService.obtenerPDF(this.token, this.otrosrecursos.id).subscribe(
      data => {
        let pdf = data.toString();
        let pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(pdf) + "'></iframe>");
      }
    );
  }

  // remove(data){
  //   let aux:any[]=[];
  //   this.busqueda.word= undefined;
  //   let i =0;
  //   for(let x in  this.words){
  //     if(this.words[x]!= data){
  //       aux[i] = this.words[x];
  //       this.busqueda.word = this.busqueda.word +","+ this.words[x];
  //       i++;
  //     }
  //   }
  //   this.words=aux;
  //   if(aux.length == 0){
  //     this.busqueda.word= undefined;
  //   }
  // }


  search(){
    this.words.forEach(element => {      // console.log(element)
    this.tagsFinales=this.tagsFinales +" "+this.slugify(element);
      if(element!=''){
        this.resultado_busqueda = this.resultado_busqueda + element + ' | '
      }
    });
    this.gameService.setTags(this.token, this.tagsFinales).subscribe(
      data => {
        console.log(data);
        this.games = data["resultado"].games;
        localStorage.setItem("games", JSON.stringify(this.games));
        this.recursos = data["resultado"].recursos;
        localStorage.setItem("documents", JSON.stringify(this.recursos));
        this.libros = data["resultado"].libros;
        this.recursos = this.asignaturas(this.recursos);
        if(this.games.length == 0 && this.recursos.length == 0 && this.libros.length == 0 && this.selected == 1){
          Swal.fire({
            type: 'warning',
            title: 'No hay coincidencias para tu búsqueda, intenta con otras variables. ',
            showConfirmButton: false,
            timer: 4000
          });
        }
      }
    );
    // console.log(this.tagsFinales)
    this.tagsFinales="";
  }

  word(){
    this.p = 1;
    this.c = 1;
    this.busqueda.word= "";
    this.resultado_busqueda = "";
    this.words = [];
    this.games.splice(0);
    this.recursos.splice(0);
    this.libros.splice(0);
    if(this.selected_asignatura==0 && this.selected_grado==0 && this.provisional.word== "") {
      this.searchAllGames();
      this.searchAllrecursos();
      this.searchAllLibros();
    }
    else{
      if(this.selected_grado!=0){
        this.BuscaTagsNivel.forEach(niv => {
          if(niv.value == this.selected_grado) this.busqueda.word= this.busqueda.word + ","+ niv.tag;
        });
      }
      if(this.selected_asignatura!=0){
        this.BuscaTagsAsig.forEach(niv => {
          if(niv.value == this.selected_asignatura) this.busqueda.word= this.busqueda.word + ","+ niv.tag;
        });
      }
      let newWord= this.provisional.word;
      this.busqueda.word= this.busqueda.word + ","+ newWord;
      this.words = this.busqueda.word.split(",");
      this.provisional.word= "";
      //this.selected_asignatura=0;
      //this.selected_grado=0;
      this.search();
    }
  }

  
  recoger(select) {

    var planJuegosFiltrado = this.list2.slice();
    var planRecursosFiltrado = this.list3.slice();
    var planLibrosFiltrado = this.list4.slice();

    planJuegosFiltrado.forEach(plan => {
      this.gameService.vincularJuego(this.token, this.id_plan, plan.id).subscribe();
    });

    planRecursosFiltrado.forEach(plan => {
      this.gameService.vincularRecurso(this.token, this.id_plan, plan.id).subscribe();
    });

    planLibrosFiltrado.forEach(plan => {
      this.gameService.vincularLibros(this.token, this.id_plan, plan.id).subscribe();
    });

    if(this.sesionesinfo.length>0){
      this.sesionesinfo.forEach(ses => {
        this.juegosRecursos.juegos = ses.juegos;
        this.juegosRecursos.otros_recursos = ses.otros_recursos;
        this.juegosRecursos.libros = ses.libros;
        this.juegosRecursos.id = ses.id;
        let j = this.juegosRecursos.juegos.split(',');
        let r = this.juegosRecursos.otros_recursos.split(',');
        let l = this.juegosRecursos.libros.split(',');

        planJuegosFiltrado.forEach(planj => {
          if(planj.id != undefined){
            if(j.includes(planj.id)==false){
              if(this.juegosRecursos.juegos=="") this.juegosRecursos.juegos = planj.id;
              else this.juegosRecursos.juegos = this.juegosRecursos.juegos + ',' + planj.id;
            }
          }
        });
  
        planRecursosFiltrado.forEach(planr => {
          if(planr.id != undefined){
            if(r.includes(planr.id)==false){
              if(this.juegosRecursos.otros_recursos=="") this.juegosRecursos.otros_recursos = planr.id;
              else this.juegosRecursos.otros_recursos = this.juegosRecursos.otros_recursos + ',' + planr.id;
            }
          }
        });

        planLibrosFiltrado.forEach(planl => {
          if(planl.id != undefined){
            if(l.includes(planl.id)==false){
              if(this.juegosRecursos.libros=="") this.juegosRecursos.libros = planl.id;
              else this.juegosRecursos.libros = this.juegosRecursos.libros + ',' + planl.id;
            }
          }
        });
  
        this.detalleService.editarJuegoRecSesiones(this.token, this.juegosRecursos ,this.id_plan).subscribe();
      });
    }

    Swal.fire({
      type: 'success',
      title: 'Cambios guardados',
      showConfirmButton: false,
      timer: 2500
    });

    if(select==1) this.router.navigate(["/detalleplan", this.slug_plan, this.id_plan]); 
    localStorage.removeItem("documents");
    localStorage.removeItem("games");
    localStorage.removeItem("books");
  }

  slugify(str) {
    str = str.replace(/^\s+|\s+$/g, '');

    str = str.toLowerCase();

    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    str = str
      .replace(/´/g, "-")
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");

    return str;
  }
  kill() {/*
    $('#exampleModalCenter').modal('hide');
    $('#exampleModalCenter').empty();*/
  }

  // borrar(){
  //   this.words = undefined;
  //   this.busqueda.word = '';
  // }


  searchAllGames(){
    this.games = this.gamesaux.slice();
  }

  searchAllrecursos(){
    this.recursos = this.recursosaux.slice();
    this.asignaturas(this.recursos);
  }

  searchAllLibros(){
    this.libros = this.librosaux.slice();
  }

  asignaturas(tags){
    this.asignaturarec='';
    let materias = ['biología', 'ecología', 'física', 'geografía', 'química', 'artes visuales', 'música', 'lenguaje y comunicación', 'formación cívica y ética', 'historia', 'matemáticas', 'desarrollo socioemocional', 'desarrollo cognitivo'];
    tags.forEach(rec => {
      this.asignaturarec='';
      for (let index = 0; index < materias.length; index++) {
        if(rec.tags.includes(materias[index])){
          let mat = materias[index].charAt(0).toUpperCase() + materias[index].slice(1);
          this.asignaturarec=this.asignaturarec + ' ' + mat;
        }
      }
      rec.asignatura = this.asignaturarec;
    });

    tags.forEach(rec => {
      if(rec.descripcion.length>103) rec.descripcion = rec.descripcion.substring(0,104) + "...</p>";
    });

    return tags; 
  }

  asignaturasCont(tags){
    this.asignatura='';
    let materias = ['biología', 'ecología', 'física', 'geografía', 'química', 'artes visuales', 'música', 'lenguaje y comunicación', 'formación cívica y ética', 'historia', 'matemáticas', 'desarrollo socioemocional', 'desarrollo cognitivo'];
    for (let index = 0; index < materias.length; index++) {
      if(tags.includes(materias[index])){
        let mat = materias[index].charAt(0).toUpperCase() + materias[index].slice(1);
        this.asignatura=this.asignatura + ' ' + mat;
      }
    }
  }

  niveles(tags){
    this.nivel='';
    if(tags.includes('primaria baja')){
      this.nivel= this.nivel + 'Primaria baja';
    }
    if(tags.includes('primaria media')){
      if(this.nivel != '') this.nivel = this.nivel + ', ';
      this.nivel= this.nivel + 'Primaria media';
    }
    if(tags.includes('primaria alta')){
      if(this.nivel != '') this.nivel = this.nivel + ', ';
      this.nivel= this.nivel + 'Primaria alta';
    }
  }

  changePlan:any = (e) => {
    if(this.id_plan!= undefined || this.id_plan!=null ){
      Swal.fire({
        title: '',
        html: '<h3>¿Estas seguro de cambiar de planeación?<br>Se eliminaran todos los cambios no guardados.</h3>',
        showCancelButton: true,
        confirmButtonColor: '#0098CE',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText:'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.cambiarPlan(e);
        }
      });
    }
    else{
      this.cambiarPlan(e);
    }
    
  }

  cambiarPlan(e){
    this.sesionesinfo = undefined;
    let p = e.target.value;
    this.planselected = this.misplanes[p];
    this.id_plan = this.misplanes[p].id;
    this.slug_plan = this.misplanes[p].slug;
    this.detalleService.detallePlanes(this.token, this.misplanes[p].id, this.misplanes[p].slug).subscribe((response: any) => {
      console.log(response);
      this.gamess = response.plan.games;
      this.articulos = response.plan.contenidos.articulos;
      this.recursoss = response.plan.contenidos.recursos;
      this.libross = response.plan.contenidos.libros;
      this.otros_recursos = response.plan.contenidos.otros_recursos;
      this.habilidades = response.plan.contenidos.habilidades;
      this.descripciones = response.plan.contenidos.descripciones;
      this.classrooms = response.plan.contenidos.classroom;
      this.sesionesinfo = response.plan.sesiones;
      this.info = response.plan.info;
      if (this.info.is_template == undefined) {
        this.info.is_template = "1";
      }
      this.rutas = response.plan.contenidos.rutas;
      this.programas = response.plan.contenidos.programas;
      let primer_valor = 0
      for (let x of this.info.name) {
        if (primer_valor == 0) {
          this.letra = x;
          primer_valor = 1;
        }
      }
      this.list2 = this.gamess;
      this.list3 = this.recursoss;
      this.list4 = this.libross;
    });
  }

  eliminarJuego(idGame, index){
    Swal.fire({
      title: '',
      html: '<h3>¿Estas seguro de eliminar el juego?<br>Se eliminará de todas las sesiones del plan.</h3>',
      showCancelButton: true,
      confirmButtonColor: '#0098CE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText:'Cancelar'
    }).then((result) => {
        if (result.value) {
          if(this.gamess[index].id_game == idGame){
            this.detalleService.deleteGame(this.token,this.id_plan,idGame).subscribe((response:any)=>{
                this.list2.splice(index,1);

                if(this.sesionesinfo.length>0){
                  this.sesionesinfo.forEach(ses => {
                    this.juegosRecursos.juegos = ses.juegos;
                    this.juegosRecursos.otros_recursos = ses.otros_recursos;
                    this.juegosRecursos.id = ses.id;

                    let j = this.juegosRecursos.juegos.split(',');
                    let ind = j.indexOf( idGame.toString());
                    if ( ind != -1 ) {
                      j.splice( ind, 1 );
                      this.juegosRecursos.juegos="";
                      for (let index = 0; index < j.length; index++) {
                        if(this.juegosRecursos.juegos=="") this.juegosRecursos.juegos = j[index];
                        else this.juegosRecursos.juegos = this.juegosRecursos.juegos + ',' + j[index];
                      }
                    }

                    ses.juegos= this.juegosRecursos.juegos;
              
                    console.log('sesionjuegos', this.juegosRecursos.juegos);
                    console.log('sesionrec', this.juegosRecursos.otros_recursos);
                    console.log('id', this.juegosRecursos.id);
                    this.detalleService.editarJuegoRecSesiones(this.token, this.juegosRecursos ,this.id_plan).subscribe();
                  });
                }
            });
          }else {
            this.list2.splice(index,1);
            this.gamesaux.forEach(element => {
              if(element.id == idGame) this.games.push(element);
            });
            this.auxList2 = this.games;
          }

          Swal.fire({
            type: 'success',
            title: 'Juego eliminado',
            showConfirmButton: false,
            timer: 2500
          });
        }
    });
    
  }

  eliminarRecursosExternos(idRecurso, index) {
    Swal.fire({
      title: '',
      html: '<h3>¿Estas seguro de eliminar el recurso?<br>Se eliminará de todas las sesiones del plan.</h3>',
      showCancelButton: true,
      confirmButtonColor: '#0098CE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText:'Cancelar'
    }).then((result) => {
        if (result.value) {
          if(this.recursoss[index].id_recurso == idRecurso){
            this.detalleService.deleteRecursosExternos(this.token, this.id_plan, idRecurso).subscribe((response:any)=>{
              this.list3.splice(index,1);

              if(this.sesionesinfo.length>0){
                this.sesionesinfo.forEach(ses => {
                  this.juegosRecursos.juegos = ses.juegos;
                  this.juegosRecursos.otros_recursos = ses.otros_recursos;
                  this.juegosRecursos.id = ses.id;

                  let j = this.juegosRecursos.otros_recursos.split(',');
                  let ind = j.indexOf( idRecurso.toString());
                  if ( ind != -1 ) {
                    j.splice( ind, 1 );
                    this.juegosRecursos.otros_recursos="";
                    for (let index = 0; index < j.length; index++) {
                      if(this.juegosRecursos.otros_recursos=="") this.juegosRecursos.otros_recursos = j[index];
                      else this.juegosRecursos.otros_recursos = this.juegosRecursos.otros_recursos + ',' + j[index];
                    }
                  }

                  ses.otros_recursos= this.juegosRecursos.otros_recursos;
            
                  console.log('sesionjuegos', this.juegosRecursos.juegos);
                  console.log('sesionrec', this.juegosRecursos.otros_recursos);
                  console.log('id', this.juegosRecursos.id);
                  this.detalleService.editarJuegoRecSesiones(this.token, this.juegosRecursos ,this.id_plan).subscribe();
                });
              }
            });
          }else {
            this.list3.splice(index,1);
            this.recursosaux.forEach(element => {
              if(element.id == idRecurso) this.recursos.push(element);
            });
            this.auxList3 = this.recursos;
          }

          Swal.fire({
            type: 'success',
            title: 'Recurso eliminado',
            showConfirmButton: false,
            timer: 2500
          });
        } 
    });
  }

  eliminarLibros(idLibro, index) {
    Swal.fire({
      title: '',
      html: '<h3>¿Estas seguro de eliminar el tema del libro?<br>Se eliminará de todas las sesiones del plan.</h3>',
      showCancelButton: true,
      confirmButtonColor: '#0098CE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText:'Cancelar'
    }).then((result) => {
        if (result.value) {
          if(this.libross[index].id_libro == idLibro){
            this.detalleService.deleteLibro(this.token, this.id_plan, idLibro).subscribe((response:any)=>{
              this.list4.splice(index,1);

              if(this.sesionesinfo.length>0){
                this.sesionesinfo.forEach(ses => {
                  this.juegosRecursos.juegos = ses.juegos;
                  this.juegosRecursos.otros_recursos = ses.otros_recursos;
                  this.juegosRecursos.libros = ses.libros;
                  this.juegosRecursos.id = ses.id;

                  let j = this.juegosRecursos.libros.split(',');
                  let ind = j.indexOf( idLibro.toString());
                  if ( ind != -1 ) {
                    j.splice( ind, 1 );
                    this.juegosRecursos.libros="";
                    for (let index = 0; index < j.length; index++) {
                      if(this.juegosRecursos.libros=="") this.juegosRecursos.libros = j[index];
                      else this.juegosRecursos.libros = this.juegosRecursos.libros + ',' + j[index];
                    }
                  }

                  ses.libros= this.juegosRecursos.libros;
            
                  this.detalleService.editarJuegoRecSesiones(this.token, this.juegosRecursos ,this.id_plan).subscribe();
                });
              }
            });
          }else {
            this.list4.splice(index,1);
            this.librosaux.forEach(element => {
              if(element.id == idLibro) this.libros.push(element);
            });
            this.auxList4 = this.libros;
          }

          Swal.fire({
            type: 'success',
            title: 'Libro eliminado',
            showConfirmButton: false,
            timer: 2500
          });
        } 
    });
  }
  
  transformarLink(links){
    if(links.includes('<a')){
      let link = links.replace(/<a/gi, '<a target="_blank"');
      this.otrosrecursos.link = link;
    }
    else if(links.includes('http') && links.includes('<p>')){
      let link = links.split('<p>');
      let urllink = link[1].replace('</p>', ' ');
      this.otrosrecursos.link = '<p>' + '<a target="_blank" href="' + urllink + '" >' + urllink + '</a> </p>';
    }
  }
  incluirvideo(video){
    if(video.includes('youtu')){
      let link1 = video.split('h?v=');
      let link = link1[1].split('">');

      this.videourl = 'https://www.youtube.com/embed/' + link[0];
      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.videourl);
      this.videoyt = true;
    }
  }

  ngAfterContentInit () {
    if(this.selected_grado!= undefined && this.selected_asignatura!= undefined){
      let top1 = document.getElementById('top-prebu');
      if (top1 !== null) {
        top1.scrollIntoView();
        top1 = null;
      }
      this.provisional.word= "";
      this.busqueda.word = "";
      this.word();
    }
    else{
      let top = document.getElementById('top');
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }
      this.selected_asignatura = 0;
      this.selected_grado = 0;
    }
  }

  newplan(){
    $(".modal-search").modal("show");
  }


}

