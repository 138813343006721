export class Games {
    public place;
    public game; 
}
export class Resources {
    public place;
    public recurso;
}
export class End {
    public end = 1;
}