import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';

@Injectable({
  providedIn: 'root'
})

export class BlogService{

  public url = config.url;
  constructor(public http: HttpClient) { }

  getArticulos(): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog', { headers: headers });
  }

  getArticuloSelec(id:string): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/'+id, { headers: headers });
  }

  getSearch(tag:string): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/search/'+tag, { headers: headers });
  }

  postCommentBlog(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/postComment', body, { headers: headers });
  }

  postUpdateCommentBlog(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/postUpdateComment', body, { headers: headers });
  }

}