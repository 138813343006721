import { Component, Input, OnInit } from '@angular/core';
import {PublicasService} from '../service/publicas.service';
import { CompartirPlan, PublicarPlan } from '../models/plan';
import Swal from 'sweetalert2';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-modal-compartir',
  templateUrl: './modal-compartir.component.html',
  styleUrls: ['./modal-compartir.component.css']
})
export class ModalCompartirComponent implements OnInit {
  
  private token = localStorage.getItem('token');
  modal : NgbModalRef;

  @Input() id_plan;
  @Input() nombre = '';
  public selected = 0;
  public tags = '';
  public correos = '';
  public mostrar = 0;
  public aceptar = false;

  constructor(private planService: PublicasService, private modalService: NgbModal ) {
  }

  ngOnInit() {
  }

  compartir(form: any) {
    if(this.aceptar && this.selected!=0){
      this.mostrar=0;
      if(this.selected==1) this.plancompartida();
      else if(this.selected==2) this.planpublica();
    }
    else{
      this.mostrar = 1;
    }
  }

  planpublica(){
    var publicarPlan = new PublicarPlan();
    publicarPlan.id_plan = this.id_plan;
    publicarPlan.tags = this.tags;

    this.planService.publicarPlaneacion(this.token, publicarPlan).subscribe((response: any) => {
      Swal.fire({
        type: 'success',
        title: 'Planeación publicada correctamente.',
        html: 'La planeación conserva tu autoría. <br> Si modificas tu planeación, el contenido compartido se modificará.',
        showConfirmButton: true,
        timer: 15000
      });
      
    }, error => {
      Swal.fire({
        type: 'error',
        title: error.error.message,
        showConfirmButton: false,
        timer: 1500
      });
    });

    $(".modal-compartir").modal("hide");
  }

  plancompartida(){
    var planComparte = new CompartirPlan();
    planComparte.id_plan = this.id_plan;
    let expresionRegular = /\s*,\s*/;
    planComparte.correos = this.correos.split(expresionRegular);

    this.planService.compartirPlaneacion(this.token, planComparte).subscribe((response: any) => {
      Swal.fire({
        type: 'success',
        title: 'Planeación compartida correctamente.',
        html: 'La planeación conserva tu autoría. <br> Si modificas tu planeación, el contenido compartido se modificará.',
        showConfirmButton: true,
        timer: 15000
      });
      
    }, error => {
      console.log(error);
      Swal.fire({
        type: 'error',
        title: error.error.message,
        showConfirmButton: false,
        timer: 2500
      });
    });

    $(".modal-compartir").modal("hide");
  }

}
