import { Component, OnInit } from '@angular/core';
import { DetalleService } from '../service/detalle.service';
import { Router } from '@angular/router';
import { Navegacion } from '../models/navigate';
import InfoPlanesLT from 'src/assets/JSON/descripcion.json';
import { ImprimirService } from '../service/imprimir.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-planes',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.css']
})
export class PlanesComponent implements OnInit {
  public planes = [];
  public first;
  public planesLT = [];
  public letra = [];
  public infoplanesLT;
  public detallereformas = [];
  private token = localStorage.getItem('token');
  public navegacion = new Navegacion();
  constructor(private detalleService: DetalleService, private imprimirService: ImprimirService, private router: Router) {
    this.infoplanesLT = InfoPlanesLT.info;
    this.navegacion.seccion =10;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
    localStorage.removeItem('PlanId');
    localStorage.removeItem('PlanSlug');
    this.detalleService.mostrarPlanes(this.token).subscribe((response: any) => {
      console.log(response);
      this.planes = response.planes;
      this.planesLT = response.planesLT;
      this.first = this.planes.length;
    });
  }

  ngOnInit() {
    $(".modal .close").click(function () {
      $('.modal').fadeOut();
      $('.modal').removeClass('show');
      console.log('close modal');
    });
  }

  nuevoplan() {
    let newSearch = "nullo";
    localStorage.setItem('home', 'Si');
    this.router.navigate(['nuevo-plan', newSearch]);
  }

  generatePdf(slug,id){
    Swal.showLoading();

    let detalleplan = [];
    let reforma, grado, asignatura;
    this.detalleService.detallePlanes(this.token, id, slug).subscribe( (response: any) => { 
      detalleplan= response.plan;
      reforma = response.plan.info.reforma;
      grado = response.plan.info.grado;
      if(reforma=='2011') {
        if(response.plan.mod_edu[0].id_mod_edu_2011 == null) asignatura = 19;
        else asignatura = response.plan.reformaonce[0].asignatura;
      }
    });
    setTimeout(() => {
      //this.imprimirService.generarPdf(detalleplan);
      if(reforma == '2017') {
        this.detalleService.getModedu(this.token).subscribe(async (response: any) => {
          this.detallereformas = response;
          this.imprimirService.generarPdf(detalleplan, this.detallereformas);
        });
      } 
      if(reforma == '2011'){
        this.detalleService.getReformaxGrado(this.token, grado, asignatura).subscribe((response: any) => {
          this.detallereformas = response;
          this.imprimirService.generarPdf(detalleplan, this.detallereformas); 
        });
      }
      if(reforma == 'libre')this.imprimirService.generarPdf(detalleplan, ''); 
    }, 3000);

    setTimeout(() => {Swal.close();}, 6000);
  }
}