import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { imagenes } from '../service/config';
import { GameService } from '../service/game.service';
import { Sesiones } from '../models/planes';
declare var $: any;

@Component({
  selector: 'app-actividades-publicas',
  templateUrl: './actividades-publicas.component.html',
  styleUrls: ['./actividades-publicas.component.css']
})
export class ActividadesPublicasComponent implements OnInit {

  @Input () sesion: number;
  @Input () sesionenviar: Sesiones;
  @Input () games;
  @Input () recursos;
  @Input () libros;
  @Input () id_plan;
  
  private token: string = localStorage.getItem('token');

  public is_edit = [];
  public editar = [];
  
  public urlImg: string = imagenes.url;
  public list2 = [];
  public list3 = [];
  public list4 = [];
  //public sesionenviar = new Sesiones();
  public material1 = '';
  public material2 = '';
  public material3 = '';
  public eval = '';
  
  Evaluacion: Array<any> = [
    { tag: 'Rúbrica', status: false },
    { tag: 'Producciones escritas', status: false },
    { tag: 'Portafolio', status: false },
    { tag: 'Lista de cotejo', status: false },
    { tag: 'Proyectos', status: false },
    { tag: 'Puertas escritas u orales', status: false },
    { tag: 'Anecdotario', status: false },
    { tag: 'Registro de actitudes', status: false },
    { tag: 'Observación directa', status: false },
    { tag: 'Esquemas', status: false },
  ];

  constructor(private gameService: GameService) { }

  ngOnInit() {
    this.checkRec2();
    this.cargarEvaluaciones();
    if(this.sesionenviar.materiales_complementarios == '/') this.checkRec();
    else{
      this.material1 = this.sesionenviar.materiales_complementarios.split(',')[0];
      this.material2 = this.sesionenviar.materiales_complementarios.split(',')[1];
      this.material3 = this.sesionenviar.materiales_complementarios.split(',')[2];
    }
    for (let index = 0; index <8; index++) {
      this.is_edit[index] = false;
    }
    for (let index = 0; index <14; index++) {
      this.editar[index] = false;
    }
    let juegossesion = this.sesionenviar.juegos.split(',');
    let recursosesion = this.sesionenviar.otros_recursos.split(',');
    let librossesion = this.sesionenviar.libros.split(',');

    this.games.forEach(game => {
      if(juegossesion.includes(game.id_game.toString())) this.list2.push(game);
    });
    this.recursos.forEach(recurso => {
      if(recursosesion.includes(recurso.id_recurso.toString())) this.list3.push(recurso);
    });
    this.libros.forEach(libro => {
      if(librossesion.includes(libro.id_libro.toString())) this.list4.push(libro);
    });
  }

  cargarEvaluaciones(){
    let evaluaciones = this.sesionenviar.instrumentos_evaluacion.split(',');
    this.Evaluacion.forEach(element => {
      evaluaciones.forEach(e => {
        if(element.tag == e) element.status = true;
      });
    });
  }

  checkRec(){
    let td = document.getElementById('recdid') as HTMLTableDataCellElement;
    $(td).attr('colspan', '3')
  }

  checkRec2(){
    if(this.sesionenviar.juegos=="" && this.sesionenviar.otros_recursos==""){
      let rd = document.getElementById('recursos-didacticos') as HTMLDivElement;
      $(rd).attr('style', 'height:100px')
    }
  }

}
