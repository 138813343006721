import { Component, OnInit, Input } from '@angular/core';
import {DetalleService} from '../service/detalle.service';
import {ActivatedRoute,Router} from '@angular/router';
import { PlanesLT, UpdatePlanesLT, CrearPlanes, NuevoPlan, ModeloEducativo, JuegosRecursosSesion, UpdateNuevosPlanes, Sesiones} from '../models/planes';
import { Plan } from '../models/plan';
import Swal from 'sweetalert2';
import { Navegacion } from '../models/navigate';
import { Location } from '@angular/common';
import { PublicasService } from '../service/publicas.service';
import { ImprimirPubService } from '../service/imprimir-pub.service';
declare var $: any;

@Component({
  selector: 'app-plan-publicas-detalle',
  templateUrl: './plan-publicas-detalle.component.html',
  styleUrls: ['./plan-publicas-detalle.component.css']
})
export class PlanPublicasDetalleComponent implements OnInit {

  private token = localStorage.getItem('token');
  public navegacion = new Navegacion();
  
  public inicioPlan = new CrearPlanes();
  public nuevoplan = new NuevoPlan();
  public modEduplan = new ModeloEducativo();
  public updatePlan = new UpdateNuevosPlanes();
  
  public modeloEducativo = [];
  public modeloEducativoselected = [];
  public asignaturas = [];
  public formacion = [];
  public eje = [];
  public temas = [];
  public aprendizajes = [];
  public aprenselected = [];
  public aprenselectedid = [];

    public rmaterias = [];
    public rmodeloeducativo = [];
    public raprendizajesesp = [];
    public rcompetencias = [];
    public rbloque = [];
    public raprenselected = [];
    public rcompetselected = [];
    public rmodselected = [];

    public rvs = [{partes: 'Selecciona la parte que corresponde la planeación', contenido: 'Selecciona el contenido que corresponde la planeación', sesion: '', objetivo: ''}];
    public rvspartes = []
    public rvscontenido = [];
    public rvssesion = [];
    public rvsobjetivo = [];
    public rvssesobjetivo = [];
    public rvsvidasaludable = [];

    public idReformaOnce;
    public bl = 0;
    public activar = 0;
    public idBloque;
    public idAmbitoEje;
    public idTemas;
    public idLengArt;
    public vidasal = 0;

  public select = 0;
  public otro = 2;
  public sesiones;
  public sesionesinfo ;
  public ejeambito: boolean; //0=ambito 1=eje
  
  private idGrado;
  private idAsignatura;
  private idModEdu;

  public id  ;
  public slug;
  public info;
  public idRuta;
  public games;
  public recursos;
  public libros;
  public grupoplanes;
  public parametros;
  public infoplanesLT;
  public planEdit = new Plan();

  public juegosRecursos = new JuegosRecursosSesion();
  
  public arraySesiones = [];

  public todosGrupos = false; 
  public reforma = -1;
  public reformatxt ='';
  public busqueda = 0;
  public grado_bus = 0;
  public materia_bus = 0;
  public cambiarmod = 0;
  public fromBusqueda = 0;

  public detalleplan = [];
  public detallereformas = [];

  public comp_pub = 0; // 0 es pública, 1 es compartida
  public id_plan_pub = 0;
  public autor:any;

  Grados: Array<any> = [
    { grado: 'Primero de primaria', id: 1 },
    { grado: 'Segundo de primaria', id: 2 },
    { grado: 'Tercero de primaria', id: 3 },
    { grado: 'Cuarto de primaria', id: 4 },
    { grado: 'Quinto de primaria', id: 5 },
    { grado: 'Sexto de primaria', id: 6 },
  ];

  constructor(private detalleService: DetalleService, private imprimirService: ImprimirPubService, private planService: PublicasService,
    private activatedRoute: ActivatedRoute,private router:Router, private _location: Location) {
      if(this.comp_pub==1) this.navegacion.seccion = 17;
      else this.navegacion.seccion = 15; 
      this.detalleService.navegacion(this.token, this.navegacion).subscribe();
   }

  ngOnInit() {

    if(this.router.url.includes('planeaciones-compartidas')) this.comp_pub = 1; 
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    this.activatedRoute.params.subscribe(async params => {
      this.parametros = params;
      this.id = params.id;
      this.idRuta = params.id;
      this.slug=params.slug;
      localStorage.setItem('PlanId', this.id);
      localStorage.setItem('PlanSlug', this.slug);

      /* OBTENER EL DETALLE DEL PLAN */
      
      await this.planService.detallePlanes(this.token, this.id, params.slug).subscribe( (response: any) => { 
        console.log(response);
        this.id_plan_pub = (response.id_plan_pub.length>0) ? response.id_plan_pub[0].id : 0;
        this.autor = response.autor[0];
        this.detalleplan= response.plan;
        this.games = response.plan.games ;
        this.recursos = response.plan.contenidos.recursos;
        this.libros = response.plan.contenidos.libros;
        this.info = response.plan.info;

        this.inicioPlan.name = this.info.name;
        this.inicioPlan.descripcion = this.info.descripcion;
        this.inicioPlan.busc = this.info.busc;
        this.busqueda = this.inicioPlan.busc;
        this.nuevoplan.ciclo = this.info.ciclo;

        this.nuevoplan.aprendizajes_clave = this.info.aprendizajes_clave;
        this.nuevoplan.area = this.info.area;
        this.nuevoplan.bloque = this.info.bloque;
        this.nuevoplan.cct = this.info.cct;
        this.nuevoplan.contexto = this.info.contexto;
        this.nuevoplan.director = this.info.director;
        this.nuevoplan.duracion = this.info.duracion;
        this.nuevoplan.fecha = this.info.fecha;
        this.nuevoplan.grado = this.info.grado;
        this.grado_bus = this.info.grado;
        this.nuevoplan.institucion = this.info.institucion;
        this.nuevoplan.nombre_docente = this.info.nombre_docente;
        this.nuevoplan.propositos_pedagogicos = this.info.propositos_pedagogicos;
        this.nuevoplan.semana = this.info.semana;
        this.nuevoplan.reforma = this.info.reforma;
        this.nuevoplan.sesiones = this.info.sesiones;

        if(this.nuevoplan.reforma=='' && this.busqueda==1) {this.otro=2; this.reforma=-1; this.fromBusqueda=1;}
        else if(this.nuevoplan.reforma=='' && this.busqueda==0) {this.otro=2; this.reforma=4}
        else if(this.nuevoplan.reforma=='libre') {this.otro=1; this.reforma=0; this.reformatxt = 'Planeación libre';}
        else if(this.nuevoplan.reforma=='2017') {this.otro=0; this.reforma=2; this.reformatxt = 'México - Reforma educativa 2017';}
        else if(this.nuevoplan.reforma=='2011') {this.otro=3; this.reforma=1; this.reformatxt = 'México - Reforma educativa 2011';}

        this.sesiones = response.plan.sesiones.length;
        this.sesionesinfo = response.plan.sesiones;

        if(this.otro==0){ //Modelo 2017
          this.idModEdu = response.plan.mod_edu[0].id_modelo_educativo;
          this.aprenselectedid = (response.plan.mod_edu[0].id_aprendizajes!=null)
          ? response.plan.mod_edu[0].id_aprendizajes.split(',') : '';
          this.idGrado = this.info.grado;

          this.detalleService.getModedu(this.token).subscribe(async (response: any) => {
            this.detallereformas = response;
            this.modeloEducativo = await response.modedu;
            this.formacion = response.formacion;
            this.asignaturas = response.asignaturas;
            this.eje = response.eje_amb;
            this.temas = response.temas;
            this.aprendizajes = response.aprendizajes;
            
            if(this.idModEdu!=undefined || this.idModEdu!=null){
              this.modeloEducativo.forEach(me => {
                if(this.idModEdu == me.idModEdu) {
                  this.modeloEducativoselected = me;
                  console.log('modeloEducativoselected',this.modeloEducativoselected);
                  this.idAsignatura = me.idasignatura;
                  if(me.eje_ambito == 1)  this.ejeambito = true;
                  else this.ejeambito = false;
                }
              });
            }
            
            if(this.aprenselectedid.length>0){
              this.aprendizajes.forEach(apren => {
                this.aprenselectedid.forEach(aprenid => {
                  if(apren.id == aprenid) this.aprenselected.push(apren);
                });
              });
            }
          });

        }
        else if(this.otro==1){//Modelo libre
          this.select = 1;
          this.ejeambito = true;
          this.modEduplan.formacion = response.plan.mod_edu[0].formacion;
          this.modEduplan.asignatura = response.plan.mod_edu[0].asignatura;
          this.modEduplan.eje = response.plan.mod_edu[0].eje;
          this.modEduplan.tema = response.plan.mod_edu[0].tema;
          this.modEduplan.aprendizajes_esperados = response.plan.mod_edu[0].aprendizajes_esperados;
        }
        else if(this.otro==3){//Reforma 2011 Obtener
          this.idGrado = this.nuevoplan.grado;

          if(response.plan.mod_edu[0].id_mod_edu_2011 == null){
            this.modEduplan.vida_saludable = response.plan.mod_edu[0].vida_saludable;
            this.idAsignatura = 19;
          }
          else{
            this.modEduplan.id_mod_edu_2011 = response.plan.mod_edu[0].id_mod_edu_2011;
            this.modEduplan.id_apren_2011 = response.plan.mod_edu[0].id_apren_2011;
            this.modEduplan.id_comp_2011 = response.plan.mod_edu[0].id_comp_2011;
            this.idAsignatura = response.plan.reformaonce[0].asignatura;
            this.idReformaOnce = this.modEduplan.id_mod_edu_2011;
          } 
          this.detalleService.getReformaxGrado(this.token, this.idGrado, this.idAsignatura).subscribe((response: any) => {
            this.detallereformas = response;
            this.rbloque = [];
            if(this.idAsignatura!=19){
                this.rmodeloeducativo = response.reforma;
                this.raprendizajesesp = response.aprendizajes;
                this.rcompetencias = response.competencias;

                if(this.idAsignatura== 2 || this.idAsignatura == 11 || this.idAsignatura==15){
                    this.rbloque = [
                        { bloque: 'Bloque 1', id: 1 },
                        { bloque: 'Bloque 2', id: 2 },
                        { bloque: 'Bloque 3', id: 3 },
                        { bloque: 'Bloque 4', id: 4 },
                        { bloque: 'Bloque 5', id: 5 },
                    ];
                }
                else{
                    response.reforma.forEach((element, index) => {
                        let b = 'Bloque ' + element.bloque + ' - ' + element.nombre_bloque;
                        if(index==0) this.rbloque.push({bloque: b, id: element.bloque});
                        else{
                            let i = this.rbloque.length;
                            if(element.bloque != this.rbloque[i-1].id) this.rbloque.push({bloque: b, id: element.bloque});
                        }
                    });
                }

                this.rmodeloeducativo.forEach(r => {
                  if(r.id == this.idReformaOnce) {
                    this.rmodselected.push(r); 
                    this.idBloque = r.bloque
                  }
                });

                let arraycomp = (this.modEduplan.id_comp_2011!='') ? this.modEduplan.id_comp_2011.split(',') : [];
                let arrayapre = (this.modEduplan.id_apren_2011 != '') ? this.modEduplan.id_apren_2011.split(',') : [];

                this.rcompetencias.forEach(c => {
                  if(c.mod_edu_2011 == this.idReformaOnce) {
                    arraycomp.forEach(ac => {
                      if(c.idcomp == ac) this.rcompetselected.push(c);
                    });
                  }
                });
                this.raprendizajesesp.forEach(a => {
                  if(a.mod_edu_2011 == this.idReformaOnce) {
                    arrayapre.forEach(ac => {
                      if(a.idapren == ac)  this.raprenselected.push(a);
                    });
                  }
                });
          
                this.bl =1;
                this.activar=1;
            }
            else{
              this.rvspartes = response.reforma.partes;
              this.rvscontenido = response.reforma.contenido;
              this.rvssesion = response.reforma.sesion;
              this.rvssesobjetivo = response.reforma.sesobj;
              this.rvsvidasaludable = response.reforma.vida_saludable;

              this.rvsvidasaludable.forEach(vs => {
                if(this.modEduplan.vida_saludable == vs.id){
                  this.rvs[0].partes = vs.partes;
                  this.rvs[0].contenido = vs.contenido;
                  this.rvs[0].objetivo = vs.objetivos;
                  this.rvs[0].sesion = vs.sesion;
                }
              });
              this.vidasal = 1;
            }
          });
        }
        else this.idModEdu = null;
      });
  
    });

  }


  generatePdf(){
    Swal.showLoading();
    setTimeout(() => {
      if(this.nuevoplan.reforma == '2017')this.imprimirService.generarPdf(this.detalleplan, this.detallereformas, this.autor); 
      if(this.nuevoplan.reforma == '2011')this.imprimirService.generarPdf(this.detalleplan, this.detallereformas, this.autor); 
      if(this.nuevoplan.reforma == 'libre')this.imprimirService.generarPdf(this.detalleplan, '', this.autor); 
    }, 1000);

    setTimeout(() => {Swal.close();}, 6000);
  }

  back() {
    this._location.back();
  }

  agregaraMisPlaneaciones(){
    this.planService.agregarPlanPublica(this.token, {id_publica: this.id_plan_pub, id_plan: this.id}).subscribe((response: any) => {
      Swal.fire({
        type: 'success',
        title: 'Planeación agregada a Planeaciones compartidas.',
        showConfirmButton: true,
        timer: 15000
      });
      
    }, error => {
      console.log(error);
      Swal.fire({
        type: 'error',
        title: error.error.message,
        showConfirmButton: false,
        timer: 2500
      });
    });
  }

}
