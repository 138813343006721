import { Component, OnInit, ViewChild } from '@angular/core';
import { GameService } from '../service/game.service';
import { DetalleService } from '../service/detalle.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalComponent } from '../modal/modal.component';
import { Navegacion } from '../models/navigate';
import Swal from 'sweetalert2';
import {NewGroup} from '../models/grupos';
import { CrearPlanes } from '../models/planes';
import { UserService } from '../service/user.service';
declare var $: any;

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css']
})
export class GruposComponent implements OnInit {

  private token: string = localStorage.getItem('token');
  public grupos;
  public numGrupos;
  public auxGrupos;
  private nombreGrupo: string = "Nombre del grupo";
  private gradoGrupo = 1;
  private grupoGrupo = "A";
  private gruposObserver: Observable<any[]>;
  public newGroup = new NewGroup();
  public navegacion = new Navegacion();

  public planes = new CrearPlanes();
  public planesAll = [];
  public nombrePlan = "";
  public descripcion: string;
  private id_plan: string;
  public gamesaux;
  
  private sections: any;

  @ViewChild(ModalComponent, { static: false }) modal: ModalComponent;

  constructor(private gameService: GameService, private router: Router, private detallService: DetalleService, private userService: UserService) {
    this.navegacion.seccion = 3;
    this.detallService.navegacion(this.token, this.navegacion).subscribe();
    this.detallService.grupos(this.token).subscribe((response: any) => {
      this.grupos = response.grupos;
      //console.log(this.grupos);
      if(this.grupos.length>0){
        this.numGrupos = this.grupos.length;
        this.numGrupos = this.numGrupos - 1;
      }
      else {this.numGrupos = 0; 
        this.auxGrupos = 0;}
    }, error =>{
        this.numGrupos=0;
        this.auxGrupos = 0;
    });
  }

  ngOnInit() {
    this.newGroup.color = "#f1727c";
    
    this.sections = this.userService.getSecciones();

    this.detallService.mostrarPlanes(this.token).subscribe((response: any) => {
      this.planesAll = response.planes;
      this.planesAll.forEach(element => {
        if(element.is_master == 1) this.id_plan = element.id;
      });
      //console.log(this.id_plan);
    });
    // $(".js-plan-slide").slick({
    //   infinite: false,
    //   dots: false,
    //   slidesToShow: 2,
    //   slidesToScroll: 2,
    //   prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-caret-left"></i></button>',
    //   nextArrow: '<button type="button" class="slick-next"><i class="fas fa-caret-right"></i></button>',
    // });
    setTimeout(function () {
      // Medium
      $(".js-modal-group").click(function (e) {
        e.preventDefault();
        $(".modal--medium").modal("show");
      });
      //--- Carousel

    }, 1000);
    // Medium
    $(".js-modal-group").click(function (e) {
      e.preventDefault();
      $(".modal--medium").modal("show");
    });
    //--- Carousel
    this.getGrupos();
    if ($('.modal--medium').is(':hidden')) {
      console.log("Esta oculto");
    } else {
      console.log("Esta visible");
    }
  }

  getGrupos() {
    this.gruposObserver = this.gameService.getGrupos(this.token);
  }

  crearGrupo(form:any){
    console.log(form.value);
    console.log(form.value.name.length);
    var expresionRegular =/[^a-zA-Z0-9]/;
    let gp = form.value.grupo.split(expresionRegular);
    if(form.value.name.length >= 16){
      Swal.fire({
        type: 'error',
        title: 'El nombre contiene más de 15 caracteres',
        showConfirmButton: true
      });
    }else{
      if(form.value.grupo.length >= 5 || gp.length != 1 || form.value.grupo==""){
        if(form.value.grupo.length >= 5){
          Swal.fire({
            type: 'error',
            title: 'El grupo contiene más de 4 caracteres',
            showConfirmButton: true
          });
        }
        else if(gp.length != 1 || form.value.grupo==""){
          Swal.fire({
            type: 'error',
            title: 'El grupo no puede contener espacios, caracteres especiales o estar vacío.',
            showConfirmButton: true
          });
        }
      }
      else{
        this.newGroup.letra="A";
      //console.log(this.newGroup);
      this.gameService.crearNewGrupo(this.token,this.newGroup).subscribe((response:any)=>{
        //console.log(response);
        this.auxGrupos = 1;
        this.detallService.grupos(this.token).subscribe((response: any) => {
          //console.log(response);
          this.grupos = response.grupos;
          this.numGrupos = this.grupos.length;
          this.numGrupos = this.numGrupos - 1;
          $("#exampleModal").modal('hide');

      if(!this.sections.flag_planeacion && !this.sections.buscador && this.sections.reportak){  
            //console.log(this.grupos.length);
            //console.log(this.grupos);
            if(this.grupos.length==1 && this.planesAll.length==0){
              this.crearPlaneacionGeneral(this.grupos);
            }
            else{
              this.grupos.forEach(grupo => {
                this.detallService.vincularGrupo(this.token, this.id_plan, grupo.id).subscribe();
              });
            }
          }

          this.ngOnInit();
        });
        
      });
      }
    }
  }

  cambiarStatus(id) {
    console.log(id)
    this.gameService.deleteGrupo(this.token, id).subscribe(
      (response:any)=> {
          this.detallService.grupos(this.token).subscribe((response: any) => {
            this.grupos = response.grupos;
            console.log(this.grupos);
            this.numGrupos = this.grupos.length;
            this.numGrupos = this.numGrupos - 1;

          });
      }, error =>{
        Swal.fire({
          type: 'error',
          title: 'El grupo contiene alumnos',
          showConfirmButton: true
        });
      } 
    );

    setTimeout(function () {
      $(".js-modal-group").on('click', function (e) {
        e.preventDefault();
        $(".modal--medium").modal("show");
      });
    }, 1000);

  }

  cambio(event){   

    var auxColores = document.getElementsByClassName("colors");
    var colores = auxColores[0].children;    

    [].forEach.call(colores, function (a) {             
      a.classList.remove("seleccionado");
    });
  
    event["srcElement"]["parentElement"].classList.add("seleccionado");    
    this.newGroup.color = event["srcElement"]["innerHTML"];
  }

  crearPlaneacionGeneral(grp){

    this.planes.busc = 0;
    this.planes.master = 1;
    this.planes.name = 'JUEGOS INOMA';
    this.planes.descripcion = 'Esta planeación contiene todos los juegos de inoma.'
    this.gameService.crearPlan(this.token, this.planes).subscribe((response:any)=>{  //crear planeacion
      //console.log(response);
      this.id_plan = response["message"];
      grp.forEach(grupo => {
        this.detallService.vincularGrupo(this.token, this.id_plan, grupo.id).subscribe();
      });
    });

    this.gameService.verJuegos(this.token).subscribe(
      data => {
        this.gamesaux = data["games"];
        this.gamesaux.forEach(plan => {
          this.gameService.vincularJuego(this.token, this.id_plan, plan.id).subscribe();
        });
      }
    );

    
  }

}