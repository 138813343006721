import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import jwt_decode from "jwt-decode";

import { config } from './config';


@Injectable({
    providedIn: 'root'
})
export class TttService {
    public url = config.url;

    constructor(public http: HttpClient) { }

    getQuestions(){
        // Cabezeras
      let headers: HttpHeaders = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json');
      // Peticion
      let url = this.url + `ttt/catQuestions`;
      return this.http.get(url, { headers: headers });
    }

    checkUsername(data){
          // Cabezeras
      let headers: HttpHeaders = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json');
      // Peticion
      let url = this.url + `ttt/registeredUsernameTak`;
      return this.http.post(url, data, { headers: headers });
    }

    newRegister(data){
          // Cabezeras
      let headers: HttpHeaders = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json');
      // Peticion
      let url = this.url + `ttt/newRegister`;
      return this.http.post(url, data, { headers: headers });
    }
    
    login(data){
          // Cabezeras
      let headers: HttpHeaders = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json');
      // Peticion
      let url = this.url + `ttt/loginTTT`;
      return this.http.post(url, data, { headers: headers });
    }

    recover(data){
          // Cabezeras
      let headers: HttpHeaders = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json');
      // Peticion
      let url = this.url + `ttt/recoverTTT`;
      return this.http.post(url, data, { headers: headers });
    }

    recoverPass(data){
          // Cabezeras
      let headers: HttpHeaders = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json');
      // Peticion
      let url = this.url + `ttt/recoverPassTTT`;
      return this.http.post(url, data, { headers: headers });
    }

}