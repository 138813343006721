import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Navegacion } from '../models/navigate';
import { PlanLibre, PlanRefDo, PlanRefDs } from '../models/plan';
import { DetalleService } from '../service/detalle.service';
import { PublicasService } from '../service/publicas.service';

@Component({
  selector: 'app-plan-publicas',
  templateUrl: './plan-publicas.component.html',
  styleUrls: ['./plan-publicas.component.css']
})
export class PlanPublicasComponent implements OnInit {
  
  private token = localStorage.getItem('token');

  public navegacion = new Navegacion();
  public planLibre = new PlanLibre();
  public planRefDo = new PlanRefDo();
  public planRefDs = new PlanRefDs();

  public ref = 0;
  public planes = [];

  public asigLibres = [];

  //2011
  public rmateriasgrado = [];
  public rmaterias = [];
  public rbloque = [];
  public rmodeloeducativo = [];
  public vidasal = 0;
  public idGrado;
  public idAsignatura;
  //2017
  public modeloEducativo = [];
  public modeloEducativoaux = [];
  public asignaturas = [];
  public asignaturasaux = [];
  public formacion = [];
  public formacionaux = [];
  public idFormacion;

  
  Grados: Array<any> = [
      { grado: 'Primero de primaria', id: 1 },
      { grado: 'Segundo de primaria', id: 2 },
      { grado: 'Tercero de primaria', id: 3 },
      { grado: 'Cuarto de primaria', id: 4 },
      { grado: 'Quinto de primaria', id: 5 },
      { grado: 'Sexto de primaria', id: 6 },
  ];

  constructor(private planService: PublicasService, private detalleService: DetalleService, private activatedRoute: ActivatedRoute,private router:Router) {
      this.navegacion.seccion = 14;
      this.detalleService.navegacion(this.token, this.navegacion).subscribe();
   }

  ngOnInit() {
    this.getTodo();
  }

  handleMostrar:any = (reforma) => {
    this.planes = [];
    if(reforma==0) {this.ref=0; this.getTodo();}
    else if(reforma==1) {this.ref=1; this.getLibres();}
    else if(reforma==2) {this.ref=2; this.getOnce();}
    else if(reforma==3) {this.ref=3; this.getSiete();}
  }

  getTodo(){
    this.planService.planeacionesPublicas(this.token).subscribe((response:any)=>{
      this.planes = response.publicas;
    });
  }

  getLibres(){
    if(this.asigLibres.length==0){
      this.planService.getAsignaturasLibre(this.token).subscribe((response:any)=>{
        this.asigLibres = response.asignaturas;
      });
    }
  }
  getOnce(){
    if(this.rmateriasgrado.length==0){
      this.detalleService.getMateriaGrado(this.token).subscribe((response: any) => {
        this.rmateriasgrado = response.grado_mat;
      });
    }
  }
  getSiete(){
    if(this.modeloEducativo.length==0){
      this.detalleService.getModeduPlan(this.token).subscribe((response: any) => {
        this.modeloEducativo = response.modedu;
        this.formacion = response.formacion;
        this.asignaturas = response.asignaturas;
      });
    }
  }

  buscar(){
    this.planes = [];
    if(this.ref==1){ //libre
      this.planService.getplanesLibre(this.token, this.planLibre).subscribe((response:any)=>{
        this.planes = response.planes_libres;
      }, error => {
        Swal.fire({
          type: 'error',
          title: error.error.message,
          showConfirmButton: false,
          timer: 4000
        });
      });
    }
    else if(this.ref==2){ //once
      this.planService.getplanesOnce(this.token, this.planRefDo).subscribe((response:any)=>{
        this.planes = response.planes_once;
      }, error => {
        Swal.fire({
          type: 'error',
          title: error.error.message,
          showConfirmButton: false,
          timer: 4000
        });
      });
    }
    else if(this.ref==3){ //siete
      this.planService.getplanesSiete(this.token, this.planRefDs).subscribe((response:any)=>{
        this.planes = response.planes_once;
      }, error => {
        Swal.fire({
          type: 'error',
          title: error.error.message,
          showConfirmButton: false,
          timer: 4000
        });
      });
    }
  }

  /* REFORMA 2011 */
  changeMaterias:any = (e) => {
    this.rmaterias.slice();
    this.rmaterias = [];
    this.vidasal=0;
    this.idGrado = e.target.value;
    this.rmateriasgrado.forEach(element => {
      if(element.grado == this.idGrado) this.rmaterias.push(element);
    });
  }

  obtenerBloque:any = (e) => {
    this.idAsignatura = e.target.value;
    if(this.idAsignatura==19){ this.vidasal=1; this.planRefDo.bloque = undefined}
    else{
      this.vidasal=0;
      this.detalleService.getReformaxGrado(this.token, this.idGrado, this.idAsignatura).subscribe((response: any) => {
      this.rbloque = [];
      if(this.idAsignatura!=19){
        this.rmodeloeducativo = response.reforma;

        if(this.idAsignatura== 2 || this.idAsignatura == 11 || this.idAsignatura==15){
          this.rbloque = [
            { bloque: 'Bloque 1', id: 1 },
            { bloque: 'Bloque 2', id: 2 },
            { bloque: 'Bloque 3', id: 3 },
            { bloque: 'Bloque 4', id: 4 },
            { bloque: 'Bloque 5', id: 5 },
          ];
        }
        else{
          response.reforma.forEach((element, index) => {
            let b = 'Bloque ' + element.bloque + ' - ' + element.nombre_bloque;
            if(index==0) this.rbloque.push({bloque: b, id: element.bloque});
            else{
              let i = this.rbloque.length;
              if(element.bloque != this.rbloque[i-1].id) this.rbloque.push({bloque: b, id: element.bloque});
            }
          });
        }
      }
    });
    }
  }

  /* REFORMA 2017 */
    changeFormacion:any = (e) => {
        this.modeloEducativoaux.splice(0);
        this.formacionaux.splice(0);
        this.formacionaux = [];
        this.asignaturasaux.splice(0);
        this.idGrado = e.target.value;
        this.modeloEducativo.forEach((modedu, index) => {
            if(this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level){
                this.modeloEducativoaux.push(modedu);
            }
        });
        this.formacionaux = this.formacion.slice();
    }

    changeAsig:any = (e) => {
      this.asignaturasaux.splice(0);
      this.idFormacion = e.target.value;
      if(this.idFormacion != 0) {
        this.asignaturasaux = this.asignaturas.slice();
        this.modeloEducativo.forEach(modedu => {
          if(this.idFormacion != modedu.idformacion){
            this.asignaturasaux.forEach((asi, index,object) => {
              if(asi.asignatura == modedu.asignatura){
                  object.splice(index,1);
              }
            });
          }
          else {
            if((this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level) == false) {
              this.asignaturasaux.forEach((asi, index,object) => {
                if(asi.asignatura == modedu.asignatura){
                  object.splice(index,1);
                }
              });
            }
          }
        });
      }
    }

}
