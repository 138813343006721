import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private auth: UserService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      if(this.auth.isLogged()){
        const section = next.data.section;
        if(this.auth.hasAccess(section)){
          return true;
        }else{
          this.router.navigate(['/inicio']);
          return false;
        }
        return true;
      }else {
        this.router.navigate(['/home']);
        return false;
      }
  }
}
