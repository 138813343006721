import { Component, OnInit } from '@angular/core';
declare var particlesJS: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    particlesJS(
      // Footer
      "js-particles--footer", {
          "particles": {
              "number": {
                  "value":80, "density": {
                      "enable": true, "value_area": 800
                  }
              }
              , "color": {
                  "value": "#c0890f"
              }
              , "shape": {
                  "type":"circle", "stroke": {
                      "width": 0, "color": "#000000"
                  }
                  , "polygon": {
                      "nb_sides": 5
                  }
                  , "image": {
                      "src": "img/github.svg", "width": 100, "height": 100
                  }
              }
              , "opacity": {
                  "value":0.5, "random":false, "anim": {
                      "enable": false, "speed": 1, "opacity_min": 0.1, "sync": false
                  }
              }
              , "size": {
                  "value":11.83721462448409, "random":true, "anim": {
                      "enable": false, "speed": 40, "size_min": 0.1, "sync": false
                  }
              }
              , "line_linked": {
                  "enable": true, "distance": 160.3412060865523, "color": "#c0890f", "opacity": 0.4, "width": 3.630079151508454
              }
              , "move": {
                  "enable":true, "speed":6, "direction":"none", "random":false, "straight":false, "out_mode":"out", "bounce":false, "attract": {
                      "enable": false, "rotateX": 600, "rotateY": 1200
                  }
              }
          },
          "interactivity": {
              "detect_on":"canvas", "events": {
                  "onhover": {
                      "enable": true, "mode": "repulse"
                  }
                  , "onclick": {
                      "enable": true, "mode": "push"
                  }
                  , "resize":true
              }
              , "modes": {
                  "grab": {
                      "distance":400, "line_linked": {
                          "opacity": 1
                      }
                  }
                  , "bubble": {
                      "distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3
                  }
                  , "repulse": {
                      "distance": 200, "duration": 0.4
                  }
                  , "push": {
                      "particles_nb": 4
                  }
                  , "remove": {
                      "particles_nb": 2
                  }
              }
          },
          "retina_detect":true
      },
  );
  }
}
