import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';

@Injectable({
  providedIn: 'root'
})

export class PublicasService{

  public url = config.url;
  constructor(public http: HttpClient) { }

  publicarPlaneacion(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    return this.http.post(this.url + `planeaciones/plan-publica`, data, { headers: headers });
  }

  planeacionesPublicas(token) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    return this.http.get(this.url + `planeaciones/publicas-all`, { headers: headers });
  }

  misplaneacionesCompartidas(token) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    return this.http.get(this.url + `planeaciones/mis-compartidas`, { headers: headers });
  }

  compartirPlaneacion(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    return this.http.post(this.url + `planeaciones/compartir-plan`, data, { headers: headers });
  }

  detallePlanes(token: string,id_plan,slug:string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `planeaciones/planeador-detalle/${id_plan}/${slug} `;
    return this.http.get(url, { headers: headers });
  }

  agregarPlanPublica(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    return this.http.post(this.url + `planeaciones/add-plan-publica`, data, { headers: headers });
  }

  getAsignaturasLibre(token) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.get(this.url + `planeaciones/asignaturas-libre`, { headers: headers });
  }

  getplanesLibre(token, data) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.post(this.url + `planeaciones/planes-libre`, data, { headers: headers });
  } 

  getplanesOnce(token, data) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.post(this.url + `planeaciones/planes-refonce`, data, { headers: headers });
  } 
  
  getplanesSiete(token, data) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.post(this.url + `planeaciones/planes-refsiete`, data, { headers: headers });
  } 


}
