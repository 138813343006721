import { Component, OnInit } from '@angular/core';
import { Navegacion } from '../models/navigate';
import { DetalleService } from '../service/detalle.service';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';
declare var $: any;

@Component({
  selector: 'app-plan-predise',
  templateUrl: './plan-predise.component.html',
  styleUrls: ['./plan-predise.component.css']
})
export class PlanPrediseComponent implements OnInit {

  public planesLT = [];
  public asignt = [];
  public habilidad = [];
  public habilidades: boolean = false;
  
  private token = localStorage.getItem('token');
  public navegacion = new Navegacion();
  private sections;
  constructor(private detalleService: DetalleService, private userService: UserService, private router: Router) {
    this.navegacion.seccion =30;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
    localStorage.removeItem('PlanId');
    localStorage.removeItem('PlanSlug');
    this.detalleService.mostrarPlanes(this.token).subscribe((response: any) => {
      response.filtros.forEach(filt => {
        if(filt.asignatura == 1) this.asignt.push(filt)
        else if(filt.habilidad == 1) this.habilidad.push(filt)
      });
      this.planesLT = response.planesLT;
      let plt = [], modulosHab = [];
      response.planesLT.forEach(pl => {
        if(pl.pago == 1) plt.push(pl);
        else modulosHab.push(pl);
      });
      this.planesLT = modulosHab.concat(plt);
    });
  }

  handleMostrar:any = (periodo) => {
    if(periodo==1) {this.habilidades=false;}
    else if(periodo==0) {this.habilidades=true;}
  }

  ngOnInit() {
    this.sections = this.userService.getSecciones();

    $(".modal .close").click(function () {
      $('.modal').fadeOut();
      $('.modal').removeClass('show');
      console.log('close modal');
    });
  }

}
