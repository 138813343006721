import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';


@Injectable({
  providedIn: 'root'
})

export class ReportakService{

  public url = config.url;
  constructor(public http: HttpClient) { }

  getPerfil(token): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url + 'user/perfil/', { headers: headers });

  }

  getPlanes(token): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url + 'lt-users/planeador/', { headers: headers });

  }

  getGrupos(token):Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url + 'lt-users/grupos/', { headers: headers });

  }

  getAlumnos(token, accessLink):Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    // TODO se deben traer los gpos de un plan en específico, pero no hay en la bdd dev
    return this.http.get(this.url + `reportak/alumnoslist/${accessLink}`, { observe: 'response', headers: headers });

  }

  getReporte(token, grupo, alumnos, periodo, games):Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url + `reportak/alumnos`, {grupo: grupo, alumnos: alumnos, periodo: periodo, games: games}, { observe: 'response', headers: headers });

  }

  getPeriodos(token, grupo, alumnos):Observable<any>{
    
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url + `reportak/getperiodos`, {grupo: grupo, alumnos: alumnos}, { observe: 'response', headers: headers });

  }

  getGraficaAlumnos(token, alumno, materia, periodo, games):Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url + `reportak/getgraficaalumnos`, {alumno: alumno.player_id, materia: materia.id, periodo: periodo, games: games}, { observe: 'response', headers: headers });

  }

  getReporteVideojuegos(token, alumno, periodo, games):Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url + `reportak/reportevideojuegos`, {alumno: alumno.player_id, periodo: periodo, games: games}, { observe: 'response', headers: headers });

  }

  getGraficaAsignatura(token, alumno, periodo, materia, games):Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url + `reportak/graficaasignatura`, {alumno: alumno, periodo: periodo, materia: materia, games: games}, { observe: 'response', headers: headers });

  }

  getGraficaIAJuego(token, alumno, periodo, juego):Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url + `reportak/iajuegohistorico`, {alumno: alumno, periodo: periodo, juego: juego}, { observe: 'response', headers: headers });

  }

  getReporteGrupo(token, alumnos, periodo, games):Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url + `reportak/reportegrupo`, {alumnos: alumnos, periodo: periodo, games: games}, { observe: 'response', headers: headers });

  }

  registrarActividad(token, grupo, plan, periodo, navegacion):Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(this.url + `reportak/registrarActividad`, {grupo: grupo, plan: plan, periodo: periodo, navegacion: navegacion}, {headers: headers});
  }


}