import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  campanias: string;

  constructor(private _config: NgbCarouselConfig, private activatedRoute: ActivatedRoute, private router: Router) { 
    this.campanias = this.activatedRoute.snapshot.params.campanias;
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.campanias = params.campanias;
      }
    );
  }

  login() {
    if(this.campanias=='taktaktak') this.router.navigate(['login/taktaktak']);
    else if(this.campanias=='emgm') this.router.navigate(['login/emgm']);
    else this.router.navigate(['login']);
  }
  register() {
    if(this.campanias=='taktaktak') this.router.navigate(['registro/taktaktak']);
    else if(this.campanias=='emgm') this.router.navigate(['registro/emgm']);
    else this.router.navigate(['registro']);
  }

  ngOnInit() {
    setTimeout(function () {
      $(".js-video-play").click(function (e) {
        e.preventDefault();
        $('#video-item').addClass("active");
        $('#video-item').get(0).play();
        $(this).fadeOut();
      });
    }, 1500);

    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  
  }

}
