import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-recibos-pagos',
  templateUrl: './recibos-pagos.component.html',
  styleUrls: ['./recibos-pagos.component.css']
})
export class RecibosPagosComponent implements OnInit {

  private recibo:any;
  private formato: Number;

  constructor(private route: ActivatedRoute) { }

  generatePDF(){
    let bloque = document.getElementById("formato");
    const options = {
      margin: [0.3,3,0,3],
      filename: this.recibo.charges.data[0].order_id+'.pdf',
      image: { type: 'png' },
      html2canvas: {
        letterRendering: false,
        useCORS: false
      },
      jsPDF: { unit: 'cm', orientation: 'portrait' }
    }
    html2pdf()
        .from(bloque)
        .set(options)
        .toPdf()
        .get('pdf')
        .save();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        this.recibo = JSON.parse(params['checkout']);
        //1-Tarjeta 2-Oxxo 3-Paypal 4-Transferencia.
        if (this.recibo.charges.data[0].payment_method.type == "credit" || this.recibo.charges.data[0].payment_method.type == "debit"){
          this.formato = 1;
        }
        if(this.recibo.charges.data[0].payment_method.type=="oxxo"){
          this.formato = 2;
        }
        if(this.recibo.charges.data[0].payment_method.type=="paypal"){
          this.formato = 3;
        }
        if(this.recibo.charges.data[0].payment_method.type=="spei"){
          this.formato = 4;
        }
        console.log(this.recibo);
      }
    )
  }

}
