import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { LoginTTT, RecoverTTT, UserTTT } from '../models/user';
import { TttService } from '../service/ttt.service';
import { timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-sylos-nino',
  templateUrl: './sylos-nino.component.html',
  styleUrls: ['./sylos-nino.component.css']
})
export class SylosNinoComponent implements OnInit {

  public game='';
  public userttt = new UserTTT();
  public loginttt = new LoginTTT();
  public recover = new RecoverTTT();

  public imgAvatar = "";
  public numbers;
  public register = 1;
  public section = 1;
  public questions;
  public userExist; 
  public passwordConf; public pass_confConf;
  public avisoSimbolos;
  public errores= [0,0,0,0,0,0];
  public errorlogin= [0,0];
  public errorPass = [,];
  public respuestaI;
  isDisabled:boolean = false;

  public minutes: any; public seconds: any;
  private _second = 1000; private _minute = this._second * 60;
  private end: any; private now: any;
  private source = timer(0, 1000); private clock:any; 
  private timer = localStorage.getItem('timer');
  private time: Array<String>;

  constructor(private tttService: TttService,private activatedRoute: ActivatedRoute, private router: Router) { 
    this.numbers = Array(72).fill(1).map((x,i)=>i); 
    this.userttt.imgAvatar = Math.floor(Math.random() * 3) + "_" + Math.floor(Math.random() * 3) + "_" + Math.floor(Math.random() * 3) +  "_" + Math.floor(Math.random() * 3) + "_" + Math.floor(Math.random() * 3) + ".png"
    this.tttService.getQuestions().subscribe((response) =>{
      this.questions = response;
    });
  }

  ngOnInit() {
    localStorage.setItem('timer','0,0');
  }

  showDate(){
    let distance = this.end - this.now;
    this.minutes = Math.floor(distance / this._minute);
    this.seconds = Math.floor((distance % this._minute) / this._second);
    localStorage.setItem('timer',this.minutes+","+this.seconds+"");
    if(this.minutes < 0){
      this.clock.unsubscribe();
      $('#modalJugar').modal('hide');
      this.game='';
      localStorage.setItem('timer','0,0');
    }
  }

  openJuego(val){
    if(val == 1) this.game = 'http://tak3dev.com/jugar/chanpikart/';
    else if(val == 2) this.game = 'http://tak3dev.com/jugar/delimonster/';
    else if(val == 3) this.game = 'http://tak3dev.com/jugar/beni/';

    localStorage.setItem('timer','5,0');
    this.timer = localStorage.getItem('timer');
    this.time = this.timer.split(',');
    this.now = new Date();
    this.end = new Date(this.now.getFullYear(),this.now.getMonth(),this.now.getDate(),this.now.getHours(),Number(this.time[0]) + Number(this.now.getMinutes()),Number(this.time[1])+Number(this.now.getSeconds()));
    console.log(this.now,this.end)
    this.clock = this.source.subscribe(t => {
      this.now = new Date();
      this.showDate();
    }); 
  }
  cerrarJuego(){
    $('#modalJugar').modal('hide');
    this.game='';
    localStorage.setItem('timer','0,0');
    this.clock.unsubscribe();
  }

  checkUsername(username){
    if(username.replace(/[^a-zA-Z0-9]/g, '/').includes('/')) {
      this.avisoSimbolos = true;
      this.userExist = false;
      (document.getElementById("username") as HTMLInputElement).value = "";
    }
    else this.avisoSimbolos = false;

    if(this.avisoSimbolos == false){
      this.tttService.checkUsername({username}).subscribe((response) =>{
        this.userExist = response;
      });
    }
  }

  checkPassword(pass){
    if(pass !=  ""  && this.userttt.password == this.userttt.password_conf) {
      this.pass_confConf = true;
      this.passwordConf = true;
    }
    else {
      this.pass_confConf = false;
      this.passwordConf = false
    }
  }

  nextRegister(){
    this.checkUsername(this.userttt.username);
    if(this.userExist == false && this.passwordConf == true && this.pass_confConf == true && this.avisoSimbolos ==false){
      this.register = 2;
    }
  }

  registerUser(){
    this.isDisabled = true; 
    Swal.showLoading();
    this.checkUsername(this.userttt.username);
    if(this.userttt.edad== "") this.errores[0] = 1;
    else this.errores[0] = 0;
    if(this.userttt.gender== "") this.errores[1] = 1;
    else this.errores[1] = 0;
    if(this.userttt.answer== "") this.errores[2] = 1;
    else this.errores[2] = 0;
    if(this.userttt.terms== false) this.errores[3] = 1;
    else this.errores[3] = 0;
    if(this.userttt.consent== false) this.errores[4] = 1;
    else this.errores[4] = 0;
    if(this.userttt.question_id == 0) this.errores[5] = 1;
    else this.errores[5] = 0;

    let i = 0;
    this.errores.forEach(e => {
      if(e == 0) i++;
    });

    if(this.userExist == false && this.avisoSimbolos==false){
      if(i==6){
        this.tttService.newRegister(this.userttt).subscribe((response) =>{
          Swal.close();
          this.router.navigate(['gracias_ninos/'+this.userttt.username]);
        },error=>{
          this.isDisabled == false;
          Swal.fire({
            type: 'error',
            title: 'Ocurrio un error con tu registro, intentalo de nuevo por favor.',
            showConfirmButton: false,
            timer: 1500
          });
        });
      }else{
        this.isDisabled = false;
        Swal.close();
      }
    }else{
      this.register=1;
      this.isDisabled = false;
      Swal.close();
    }
  }

  login(){
    Swal.showLoading();
    if(this.loginttt.login_username== "") this.errorlogin[0] = 1;
    else this.errorlogin[0] = 0;
    if(this.loginttt.login_password== "") this.errorlogin[1] = 1;
    else this.errorlogin[1] = 0;

    if(this.errorlogin[0]==0 && this.errorlogin[1]==0){
      this.tttService.login(this.loginttt).subscribe((response) =>{
        Swal.fire({
          type: 'success',
          title: '¡Bienvenido de nuevo!',
          html: '<p>En los próximos <span>segundos</span> serás redirigido a <a href="https://taktaktak.com/registro-land/'+this.loginttt.login_username+'">taktaktak.com</a>, si no sucede así puedes dar <a href="https://taktaktak.com/registro-land/'+this.loginttt.login_username+'"><span>Clic aquí</span></a></p>',
          showConfirmButton: false,
          timer: 3000
        });
        setTimeout(() => {
           window.location.href = 'https://taktaktak.com/registro-land/'+this.loginttt.login_username
        }, 3000);
      },error=>{
        Swal.fire({
          type: 'error',
          title: 'Tu usuario o contraseña son incorrectos. Intentalo de nuevo.',
          showConfirmButton: false,
          timer: 1500
        });
      });
    }
  }

  recoverPass(){
    Swal.showLoading();
    if(this.userExist && this.recover.username!="" && this.recover.slc_question!=0 && this.recover.slc_answer!=""){
      this.tttService.recover(this.recover).subscribe((response:any) =>{
        console.log(response)
        this.recover.id = response.id;
        this.section = 4;
        Swal.close();
      },error=>{
        this.recover.slc_answer = "";
        Swal.fire({
          type: 'error',
          title: 'Tu respuesta es incorrecta. Intentalo de nuevo.',
          showConfirmButton: false,
          timer: 1500
        });
      });
    }
  }

  cambiarPass(){
    Swal.showLoading();
    if(this.recover.password== "" && this.recover.password_conf==""){
      this.errorPass[0] = false;
      Swal.close();
    }else if(this.recover.password != this.recover.password_conf){
      this.errorPass[1] = false;
      Swal.close();
    }
    else{
      this.errorPass=[true,true];
      this.tttService.recoverPass(this.recover).subscribe((response:any) =>{
        Swal.fire({
          type: 'success',
          title: '¡Cambiaste tu contraseña! Ahora puedes iniciar sesión en Tak-Tak-Tak',
          showConfirmButton: false,
          timer: 3000
        });
        this.section=2;
      },error=>{
        this.recover.slc_answer = "";
        Swal.fire({
          type: 'error',
          title: 'Tu respuesta es incorrecta. Intentalo de nuevo.',
          showConfirmButton: false,
          timer: 1500
        });
      });
    }
  }

}
