import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';

@Injectable({
  providedIn: 'root'
})

export class CapacitacionesService{
  public url = config.url;
  constructor(public http: HttpClient) { }

  getTemas(token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/temas', { headers: headers });
  }

  getProximos(token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/proximos-mes', { headers: headers });
  }

  postBusqueda(body:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/buscador', body, { headers: headers });
  }

  getWebinarInfo(id:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/webinar-info/'+id, { headers: headers });
  }

  getOtros(token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/otros-webinars', { headers: headers });
  }

  postInscribirWebinarGratis(body:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/inscribir-webinar/gratis', body, { headers: headers });
  }

  postInscribirWebinarCobro(body:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/inscribir-webinar/pago', body, { headers: headers });
  }

  getMisCapacitaciones(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.get(this.url + `capacitacion/mis-capacitaciones`, { headers: headers });
  }

  postComment(body:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/enviar-comentario', body, { headers: headers });
  }

  getComment(idWebinar:any, token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.get(this.url + `capacitacion/webinar-comentarios/`+idWebinar, { headers: headers });
  }

  postVisitedWebinar(body:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/visited_webinar', body, { headers: headers });
  }

  postZoom(body:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/zoom', body, { headers: headers });
  }

  getMooc(id:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/mooc-info/'+id, { headers: headers });
  }

  getMoocInsc(id:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/mooc-info-ins/'+id, { headers: headers });
  }

  postInscribirMoocGratis(body:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/inscribir-mooc/gratis', body, { headers: headers });
  }

  postInscribirMoocCobro(body:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/inscribir-mooc/pago', body, { headers: headers });
  }

  sendCalificacion(calf, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/calificar-mooc', calf, { headers: headers });
  }

  registrarMaterial(material, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/registrar-material', material, { headers: headers });
  }

  registrarEvaluacion(evaluacion, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/registrar-evaluacion', evaluacion, { headers: headers });
  }
  
}