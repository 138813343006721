import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ForoService } from '../service/foro.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { imagenes } from '../service/config';

import Swal from 'sweetalert2';
import { DetalleService } from '../service/detalle.service';

@Component({
  selector: 'app-foro-entrada',
  templateUrl: './foro-entrada.component.html',
  styleUrls: ['./foro-entrada.component.css']
})
export class ForoEntradaComponent implements OnInit {

  private urlIMG=imagenes.url;

  private IDsubtema : string;
  private entradas : any;
  private CreateEntradaAvailable: boolean;
  private tituloCrearEntrada:string;
  private textoCrearEntrada:string;
  private textoComment:any;
  private textoResponse:any;

  private token=localStorage.getItem('token');
  private IDemail:any;

  constructor(private route: ActivatedRoute, private foroService: ForoService, private detalleService: DetalleService) { }

  eliminarEntrada(IDe:number,nombre:any,texto:any){
    let b ={
      "ID":IDe,
      "nombre":nombre,
      "texto":texto,
      "visivle":false
    }
    Swal.fire({
      title: '¿Seguro quieres eliminar tu entrada?',
      text: "no podras deshacer esto!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminala'
    }).then((result) => {
      if (result.value) {
        this.foroService.postUpdateEntrada(b).subscribe((response: any) => {
          this.refreshEntradas();
        });
        Swal.fire(
          'Eliminado!',
          'Tu entrada se ha eliminado.',
          'success'
        )
      }
    })
  }

  eliminarComment(IDc:number){
    let b = {"ID":IDc, "visible":false}
    Swal.fire({
      title: '¿Seguro quieres eliminar tu comentario?',
      text: "no podras deshacer esto!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalo'
    }).then((result) => {
      if (result.value) {
        this.foroService.postUpdateComment(b).subscribe((response: any) => {
          this.refreshEntradas();
        });
        Swal.fire(
          'Eliminado!',
          'Tu comentario se ha eliminado.',
          'success'
        )
      }
    })
  }

  subirResponse(IDentrada:string,IDrespuesta:any){    
    let fecha = new Date().toISOString();
    if(!(this.token==null || this.token == undefined || this.token == "")){
      if(!(this.textoResponse==undefined || this.textoResponse=="")){
        let body = ({
          "IDentrada":IDentrada,
          "IDsubtema":this.IDsubtema,
          "IDrespuesta":IDrespuesta,
          "IDusuario":this.IDemail,
          "fecha":fecha,
          "texto":this.textoResponse
        });
        console.log(body);
        this.foroService.postComment(body).subscribe((response: any) => {
          console.log(response);
          this.textoComment="";
          this.textoResponse="";
          this.refreshEntradas();
          Swal.fire({
            position: 'top-end',
            title: 'Comentario publicado',
            showConfirmButton: false,
            timer: 1500,
            type: 'success'
          });
        }); 
      }else{
        Swal.fire("","Escribe tu respuesta primero","warning");
      }
    }else{
      Swal.fire("","Debes registrarte para comentar","warning");
    }
  }

  subirComment(IDentrada:string,IDrespuesta:any){    
    let fecha = new Date().toISOString();
    if(!(this.token==null || this.token == undefined || this.token == "")){
      if(!(this.textoComment==undefined || this.textoComment=="")){
        let body = ({
          "IDentrada":IDentrada,
          "IDsubtema":this.IDsubtema,
          "IDrespuesta":IDrespuesta,
          "IDusuario":this.IDemail,
          "fecha":fecha,
          "texto":this.textoComment
        });
        console.log(body);
        this.foroService.postComment(body).subscribe((response: any) => {
          console.log(response);
          this.textoComment="";
          this.textoResponse="";
          this.refreshEntradas();
          Swal.fire({
            position: 'top-end',
            title: 'Comentario publicado',
            showConfirmButton: false,
            timer: 1500,
            type: 'success'
          });
        }); 
      }else{
        Swal.fire("","Escribe tu respuesta primero","warning");
      }
    }else{
      Swal.fire("","Debes registrarte para comentar","warning");
    }
  }

  subirEntrada(){
    let fecha = new Date().toISOString();
    if(!(this.token==null || this.token == undefined || this.token == "")){
      if(!(this.textoCrearEntrada==undefined || this.textoCrearEntrada=="") && 
        !(this.tituloCrearEntrada==undefined || this.tituloCrearEntrada=="")){
        let body = ({
          "IDsubtema":this.IDsubtema,
          "IDusuario":this.IDemail,
          "nombre":this.tituloCrearEntrada,
          "fecha":fecha,
          "texto":this.textoCrearEntrada,
          "visible":true
        });
        this.foroService.postEntrada(body).subscribe((response: any) => {
          console.log(response);
          this.tituloCrearEntrada ="";
          this.textoCrearEntrada ="";
          this.CreateEntradaAvailable = false; 
          this.refreshEntradas();
          Swal.fire({
            position: 'top-end',
            title: 'Entrada registrada',
            showConfirmButton: false,
            timer: 1500,
            type: 'success'
          });
        }); 
      }else{
        Swal.fire("","Llenar todos los campos","warning");
      }
    }else{
      Swal.fire("","Debes registrarte para subir entradas","warning");
    }
  }

  async selectResponse(id:number,entradaIndex:number){
    var resText = document.getElementById('response-'+id);
    var resbtn = document.getElementById('responseBtn-'+id);
    if(resText.classList.contains("unselected")){
      this.refreshResponses(entradaIndex);
        resText.classList.remove("unselected");
        resText.classList.add("selected");
        resbtn.classList.remove("selected");
        resbtn.classList.add("unselected");
    }
  }
  
  refreshResponses(entradaI:number){
    for(let i=0; i<this.entradas.entradas[entradaI].comments.length; i++){
      this.cancelResponse(this.entradas.entradas[entradaI].comments[i].ID);
    }
  } 

  cancelResponse(id:number){
    var resText = document.getElementById('response-'+id);
    resText.classList.remove("selected");
    resText.classList.add("unselected");
    var resbtn = document.getElementById('responseBtn-'+id);
    resbtn.classList.remove("unselected");
    resbtn.classList.add("selected");
    this.textoResponse = "";
  }

  SelectEntrada(IDC:number){
    let cd:string = "containerEntrada-"+Number(IDC).toString();
    var element = document.getElementById(cd);
    if(element.classList.contains("unselected")){
      this.refreshTemas();
      element.classList.remove("unselected");
      element.classList.add("selected");
    } else if(element.classList.contains("selected")){
      element.classList.remove("selected");
      element.classList.add("unselected");
    }
    this.goTo(cd);
  }

  refreshTemas(){
    for(let i=0; i<this.entradas.entradas.length; i++){
      var element = document.getElementById('containerEntrada-'+this.entradas.entradas[i].ID);
      element.classList.remove("selected");
      element.classList.add("unselected");
    }
  }

  cancelEntrada(){
    this.CreateEntradaAvailable = false;
    this.textoCrearEntrada = "";
    this.tituloCrearEntrada = "";
  }

  refreshEntradas(){
    this.foroService.getEntradas(this.IDsubtema).subscribe((response: any) => {
      this.entradas = response;
      console.log(this.entradas);
    });
  }

  goTo(id:string){
    let top = document.getElementById(id);
    if (top !== null) {
      top.scrollIntoView();
      top = null; 
    }
  }

  ngOnInit() {
    this.IDsubtema = this.route.snapshot.paramMap.get('id');
    this.refreshEntradas();
    this.goTo("top");
    this.CreateEntradaAvailable = false;

    if(!(this.token==null || this.token == undefined || this.token == "")){
      this.IDemail=localStorage.getItem('email');
    }
    this.detalleService.navegacionForo(this.token, { subtema_id: this.IDsubtema }).subscribe();
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '135px',
      minHeight: '0',
      maxHeight: 'auto', 
      width: '100%',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Ingresa el contenido de la entrada aqui...',
      defaultParagraphSeparator: '',
      defaultFontName: 'Roboto',
      defaultFontSize: '3',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote', 
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };
}
