import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from './config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  url: any = config.url;

  constructor(private http: HttpClient) { }

  verJuegos(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'ts/game/', { headers: headers });
  }

  mostrarRecursos(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'ts/recursos-externos', { headers: headers });
  }

  getGrupos(token: string): Observable<any[]> {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get<any>(this.url + 'lt-users/grupos', { headers: headers });
  }

  deleteGrupo(token: string, id_grupo: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.delete(this.url + `lt-users/grupos/${id_grupo}`, { headers: headers });
  }
  crearNewGrupo(token: string, data) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/grupos/`, data, { headers: headers });
  }


  // insertHappy(token: string, ) {
  //   // Cabezeras
  //   let headers: HttpHeaders = new HttpHeaders()
  //   headers = headers.append('Content-Type', 'application/json');
  //   headers = headers.append('Authorization', `Bearer ${token}`);
  //   // Petición
  //   return this.http.post(this.url + `/tk-users/alumnos`,
  //   {

  //   }
  //   ,{ headers: headers });
  // }  

  setTags(token: string, search: string): Observable<any[]> {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post<any>(this.url + `ts/takthesaurus`,
      {
        "search": search
      }
      , { headers: headers });
  }

  verDetalle(token: string, slug: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.get(this.url + `ts/game/${slug}`, { headers: headers });
  }

  crearPlan(token: string, data) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/planeador/`, data, { headers: headers });
  }
  updatePlan(token: string, data, id) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/planeador/${id}`, data, { headers: headers });
  }

  vincularJuego(token: string, id_plan: string, game_id: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/game-to-plan/${id_plan}`,
      {
        "game_id": `${game_id}`
      }
      , { headers: headers });
  }

  vincularRecurso(token: string, id_plan: string, recurso_id: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/recurso-to-plan/${id_plan}`,
      {
        "recurso_id": recurso_id
      }
      , { headers: headers });
  }

  vincularLibros(token: string, id_plan: string, libro_id: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/libro-to-plan/${id_plan}`,
      {
        "libro_id": libro_id
      }
      , { headers: headers });
  }

  vincularDescripcion(token: any, id_plan: any, id_descripcion: any) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/descripciones-to-plan/${id_plan}`,
      {
        "descripcion_id": id_descripcion
      }
      , { headers: headers });
  }
  vincularPrograma(token: any, id_plan: any, programa: any) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/programas-academicos-to-plan/${id_plan}`,
      {
        "programa": programa
      }
      , { headers: headers });
  }
  vincularRuta(token: any, id_plan: any, ruta: any) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/ruta-to-plan/${id_plan}`,
      {
        "ruta": ruta
      }
      , { headers: headers });
  }
  vincularOtrosRecursos(token: any, id_plan: any, or: any) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/otros-recursos-to-plan/${id_plan}`,
      {
        "otros_recursos_id": or
      }
      , { headers: headers });
  }
  vincularHabilidad(token: any, id_plan: any, ability: any) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/habilidades-to-plan/${id_plan}`,
      {
        "ability_id": ability
      }
      , { headers: headers });
  }

  vincularRecursos(token: string, id_plan: string, recurso_id: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `lt-users/recurso-to-plan/${id_plan}`,
      {
        "recurso_id": recurso_id
      }
      , { headers: headers });
  }

  salir(token: string, email:String) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `cerrar/sesion`,
      {
        "email": email
      }
      , { headers: headers });
  }

  mostrarLibros(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'ts/libros-texto', { headers: headers });
  }

}
