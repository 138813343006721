import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import jwt_decode from "jwt-decode";

import { config } from './config';


@Injectable({
    providedIn: 'root'
})
export class UserService {
    public url = config.url;
    public colombia;
    public FIFYA ;

    constructor(public http: HttpClient) { }

    /**
     * Login Service
     */
    login(user): Observable<any> {
        // Cabezeras
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        // Parámetros
        let params = user;

        // Petición
        return this.http.post(this.url + 'register/login', params, { headers: headers });
    }

    /**
     * Auth Service
     */
    isLogged() {
        let token = localStorage.getItem('token');

        if (token && token != 'undefined') {
            return true;
        } else {
            return false;
        }
    }

    registro( data) {
        let headers: HttpHeaders = new HttpHeaders()
        headers = headers.append('Content-Type', 'application/json');
        if(data.state_id == '') {data.state_id='33';}
        // Peticion
        let url = this.url + `register/register`;
        return this.http.post(url, {
            "email": data.email,
            "name": data.name,
            "lastname":" ",
            "password": data.password,
            "password_conf": data.password_conf,
            "genero": 1,
            "area_id": 0,
            "pais_id": data.pais_id,
            "terms": data.aviso,
            "type_form": "labtak",
            "user_rol_id": data.user_role_id,
            "txt_source": data.txt_source,
            "captcha": 1,
            "state_id": data.state_id,
            "invt_username": 0,
            "phone": " ",
            "colombia": data.colombia,
            "fya": data.fya,
            "codigofya": data.codigofya,
            "datacole": data.datacole,
            "piloto": data.piloto,
            "sek": data.sek,
            "techint": data.techint,
            "activar_licencia": data.activar_licencia,
            "codigo": data.codigo,
            "codigoproed": data.codigoproed,
            "proed": data.proed,
            "taktaktak": data.taktaktak,
            "emgm": data.emgm
        }, { headers: headers });
    }
    recuperarContraseña(data) {
      // Cabezeras
      let headers: HttpHeaders = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json');
      // Peticion
      let url = this.url + `recuperar/password`;
      return this.http.post(url,data, { headers: headers });
    }
    recuperarContraseñaEmail(data) {
      // Cabezeras
      let headers: HttpHeaders = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json');
      // Peticion
      let url = this.url + `recuperar/passwordEmail`;
      return this.http.post(url,data, { headers: headers });
    }

    hasAccess(section){
        let token = localStorage.getItem('token');
        let token_decoded = jwt_decode<MyToken>(token);
        let licencias_adqui = token_decoded.licencias_adqui;
        //console.log(licencias_adqui);
        if(Object.keys(licencias_adqui).includes(section)){
            return (licencias_adqui[section]) ? true : false; 
        }else{
            return false;
        }
    }
    getSecciones(){
        let token = localStorage.getItem('token');
        let token_decoded = jwt_decode<MyToken>(token);
        return token_decoded.licencias_adqui;
    }

    checklicense(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.get(this.url + `register/checklicense `, { headers: headers });
  }
    
    getCopy(section: string){
        // Cabezeras
      let headers: HttpHeaders = new HttpHeaders()
      headers = headers.append('Content-Type', 'application/json');
      // Peticion
      let url = this.url + `cat/areas-educacion/page-landing` + section;
      return this.http.get(url, { headers: headers });
    }
    
}

interface MyToken {
    licencias_adqui: any;
}