import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ReportakService } from '../service/reportak.service';
import Swal from 'sweetalert2';

import * as html2pdf from 'html2pdf.js';

import { style } from '@angular/animations';
import { borderLeftStyle } from 'html2canvas/dist/types/css/property-descriptors/border-style';
import { DetalleService } from '../service/detalle.service';
import { Navegacion } from '../models/navigate';

@Component({
  selector: 'app-reportak-grupo',
  templateUrl: './reportak-grupo.component.html',
  styleUrls: ['./reportak-grupo.component.css']
})

export class ReportakGrupoComponent implements OnInit {
  
  @ViewChild('screen', {static: false}) screen: ElementRef;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;

  private token: string = localStorage.getItem('token');
  private userData: any = [];
  private planes: any[];
  private grupos: any[];
  private games: any[];
  private accessLink: '';
  private alumnos: any[];
  private periodos = {mensuales:[], quincenales:[]};
  private periodo: any[] = [];
  private periodoSelected:string;
  private mensual: boolean = true;
  private reporte;
  private reporteSize = 0;

  private pl:string;
  private gr:string;
  private bestgame;
  public planSelected;
  
  public navegacion = new Navegacion();

  private onPlanChange:any = (e) => {
    //console.log("onPlanChange", e);
    this.planes.forEach((item, index) => {
      if(item.id == e.target.value){
        this.pl = item.name;
        this.planSelected = item.id;
        if(item.grupos.length > 0){
          this.grupos = item.grupos;
          this.games = item.games;
          this.gr = this.grupos[0].access_link.substring(this.accessLink.length-2);
          this.getAlumnos(this.token, this.grupos[0].access_link);
        }else{
          Swal.fire(" ", "Este plan no tiene grupos asignados", "error");
        }
      }
    });
  }

  private getAlumnos:any = (token, grupo) => {
    
    this.reporTakService.getAlumnos(token, grupo).subscribe((response: any) => {
      if(response.status == 200 && response.body.message === 'Ok'){
        if(response.body.alumnos.length){
          this.alumnos = response.body.alumnos;
          this.getPeriodos(this.token, grupo, response.body.alumnos);
        }else{
          this.alumnos = [];
          Swal.fire(" ", "Este grupo no tiene alumnos asociados", "error");
        }
      }else{
        this.alumnos = [];
        Swal.fire(" ", "Este grupo no tiene alumnos asociados", "error");
      }
    });

  }

  private getPeriodos:any = (token, grupo, alumnos) => {

    this.reporTakService.getPeriodos(token, grupo, alumnos).subscribe((response: any) => {
      if(response.status == 200 && response.body.message === 'Ok'){
        this.periodos = response.body.periodos;
        this.periodo = this.mensual ? this.periodos.mensuales : this.periodos.quincenales;
        this.periodoSelected = this.periodo[0].date_start;
        this.reporteGrupo(this.token, this.alumnos, this.periodo[0].date_start); // TODO primer periodo
      }else{
        this.periodos = {mensuales:[], quincenales:[]};
      }
    });

  }

  private reporteGrupo:any = (token, alumnos, periodo) => {
    Swal.showLoading();
    this.reporTakService.registrarActividad(token, this.accessLink, this.planSelected, periodo, this.navegacion.seccion).subscribe();
    this.reporTakService.getReporteGrupo(token, alumnos, periodo, this.games).subscribe((response: any) => {
      this.reporteSize= 0;
      if(response.status == 200 && response.body.message === 'Ok'){
        if(response.body.reporte.reporteGrupo.length){
          //console.log("reporteGrupo", response);
          this.reporte = response.body.reporte;
          this.reporteSize = Object.keys(this.reporte).length;
          Swal.close();
        }else{
          Swal.fire(" ", "No se pudo obtener información con los filtros utilizados", "warning");
        }
      }else{
        Swal.fire(" ", "No se pudo obtener información con los filtros utilizados", "warning");
      }
    });
  }
  
  private onGrupoChange:any = (e) => {
    this.getAlumnos(this.token, e.target.value);
    this.gr=this.accessLink.substring(this.accessLink.length-2);
  };

  private handlePeriodo:any = (periodo) => {
    this.mensual = periodo === 'm';
    this.periodo = periodo === 'm' ? this.periodos.mensuales : this.periodos.quincenales;
    this.periodoSelected = this.periodo[0].date_start + (periodo === 'q' ? ('-' + this.periodo[0].quincena) : '');
    this.reporteGrupo(this.token, this.alumnos, this.periodo[0].date_start + (periodo === 'q' ? ('-' + this.periodo[0].quincena) : ''));
    //console.log("this.periodo", this.periodo);
  }

  private handleSelectPeriodo:any = (event) => {
    //console.log("handleSelectPeriodo", event.target.value);
    this.periodoSelected = event.target.value;
    this.reporteGrupo(this.token, this.alumnos, event.target.value);
  }

  private filterItemsOfType(status){
    if(this.grupos && this.grupos.length){
      return this.grupos.filter(x => x.status == status);
    }else{
      return false;
    }
  }

  async generatePdf()
  {
    this.navegacion.seccion = 23;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
   if(this.reporteSize!= 0)
   {
      Swal.showLoading();
      let header:Element = document.createElement("div");
      let reportak = document.getElementById("reportakescondido").cloneNode(true);
      header.appendChild(reportak);
      //Detalles del reporte
      let col1:Element = document.createElement("div");
      col1.classList.add("col-6");
      let plan:Element = document.createElement("div");
      plan.innerHTML = "<b>Planeación: </b>"+this.pl;
      col1.appendChild(plan);
      let tipo:Element = document.createElement("div");
      tipo.innerHTML = "<b>Reporte por: </b> Grupo";
      col1.appendChild(tipo);
      let grupo:Element = document.createElement("div");
      grupo.innerHTML = "<b>Grupo: </b>"+this.gr;
      col1.appendChild(grupo);
      let periodo:Element = document.createElement("div");
      periodo.innerHTML = "<b>Periodo: </b>"+this.periodoSelected;
      col1.appendChild(periodo);
      let col2:Element = document.createElement("div");
      col2.classList.add("col-6");
      let docente:Element = document.createElement("div");
      docente.innerHTML = "<b>Docente: </b>"+this.userData.name+' '+this.userData.lastname;
      col2.appendChild(docente);
      let escuela:Element = document.createElement("div");
      (this.userData.centro_educativo==null) ? escuela.innerHTML = "<b>Escuela: </b>"
        : escuela.innerHTML = "<b>Escuela: </b>"+this.userData.centro_educativo;
      col2.appendChild(escuela);
      let cct:Element = document.createElement("div");
      (this.userData.cct==null) ? cct.innerHTML = "<b>CCT: </b>"
        : cct.innerHTML = "<b>CCT: </b>"+this.userData.cct;
      col2.appendChild(cct);
      let row:Element = document.createElement("div");
      row.classList.add("row");
      row.appendChild(col1);
      row.appendChild(col2);
      header.appendChild(row);

      let bloque:Element = document.createElement("div");
      let bests:Element = document.createElement("div");
      let asignaturas:Element = document.createElement("div");

      bests.classList.add("col-3");
      if(this.reporte.mejorUsuario.username != ""){
        const bestUser = document.getElementById(this.reporte.mejorUsuario.username).cloneNode(this.reporte.mejorUsuario.username);
        bests.append(bestUser);
      }
      if(this.reporte.mejorJuego.title != ""){
        const bestGame = document.getElementById(this.reporte.mejorJuego.title).cloneNode(this.reporte.mejorJuego.title);
        bests.append(bestGame);
      }
      if(this.reporte.juegoMasLikes.title != ""){
        const mostLikes = document.getElementById(this.reporte.juegoMasLikes.title).cloneNode(this.reporte.juegoMasLikes.title);
        bests.append(mostLikes);
      }

      asignaturas.classList.add("row");
      this.reporte.reporteGrupo.forEach(repor => {
        let wrapp = document.createElement("div");
        wrapp.classList.add("col-4");
        let nodetemp = document.getElementById(repor.asignature).cloneNode(true);
        wrapp.appendChild(nodetemp);
        asignaturas.append(wrapp);
      });
      let asign:Element = document.createElement("div");
      asign.classList.add("col-9");
      asign.appendChild(asignaturas);

      let fusion:Element = document.createElement("div");
      fusion.classList.add("row");
      fusion.classList.add("scale");
      fusion.appendChild(bests);
      fusion.appendChild(asign);

      let indicadores = document.getElementById("indicador");
      let nodeIndicadores = indicadores.cloneNode(true);

      bloque.appendChild(header);
      bloque.appendChild(fusion);
      bloque.appendChild(nodeIndicadores);

      const options = {
        margin: [0.5,2,2,2],
        filename: 'Reportak-grupos.pdf',
        image: { type: 'jpeg' },
        html2canvas: {
          dpi: 300,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: { unit: 'cm', orientation: 'landscape' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      }

      html2pdf()
        .from(bloque)
        .set(options)
        .toPdf()
        .get('pdf')
        .then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();

          for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor("#00bae0");
              pdf.setFontType("bold");
              pdf.text('ReporTak | LabTak®', pdf.internal.pageSize.getWidth()/2 - 1.5, pdf.internal.pageSize.getHeight() - 1.5);
              pdf.setFontType("normal");
              pdf.setTextColor("#00bae0");
              pdf.text('Página ' + i + ' de ' + totalPages, pdf.internal.pageSize.getWidth()/2 - 1, pdf.internal.pageSize.getHeight() - 1);
          } 
        })
        .save();
        
        setTimeout(() => {Swal.close();},5000);
    }
    else
    {
      Swal.fire(" ","No existe información para generar los reportes", "warning");
    }
  }
    

  constructor(private reporTakService: ReportakService, private detalleService: DetalleService){
    
    this.navegacion.seccion = 19;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();

    this.reporTakService.getPerfil(this.token).subscribe((response: any) => {
      if(response.message == 'Ok'){
        this.userData = response.user_data;
      }
    });

    this.reporTakService.getPlanes(this.token).subscribe((response: any) => {
      if(response.message === 'OK'){
        //console.log("getPlanes", response);
        if(response.planes.length > 0){
          this.pl = response.planes[0].name;
          this.planSelected = response.planes[0].id;
          this.planes = response.planes;
          this.grupos = response.planes[0].grupos;
          this.games = response.planes[0].games;
          if( response.planes[0].grupos.length >0 ){ 
            let ig = 0;
            response.planes[0].grupos.forEach((g,index) => {
              if(g.status==1 && ig==0){
                this.accessLink = response.planes[0].grupos.length ? response.planes[0].grupos[index].access_link : '';
                this.getAlumnos(this.token, response.planes[0].grupos[index].access_link);
                this.gr = this.accessLink.substring(this.accessLink.length-2);
                ig=1;
              }
            });
          }
          else{
            Swal.fire(" ","Este plan no tiene grupos asignados", "error");
          }
        }
      }
    });

  }

  ngOnInit() {
    let top = document.getElementById('top');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }
  }

}
