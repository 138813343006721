import { Component, OnInit, Input } from '@angular/core';
import {DetalleService} from '../service/detalle.service';
import {ActivatedRoute,Router} from '@angular/router';
import { PlanesLT, UpdatePlanesLT, CrearPlanes, NuevoPlan, ModeloEducativo, JuegosRecursosSesion, UpdateNuevosPlanes, Sesiones} from '../models/planes';
import { Plan } from '../models/plan';
import Swal from 'sweetalert2';
import { Navegacion } from '../models/navigate';
import { ImprimirService } from '../service/imprimir.service';
import { UserService } from '../service/user.service';
declare var $: any;

@Component({
  selector: 'app-plan-detalle',
  templateUrl: './plan-detalle.component.html',
  styleUrls: ['./plan-detalle.component.css']
})
export class PlanDetalleComponent implements OnInit {
  private token = localStorage.getItem('token');
  
  
  public inicioPlan = new CrearPlanes();
  public nuevoplan = new NuevoPlan();
  public modEduplan = new ModeloEducativo();
  public updatePlan = new UpdateNuevosPlanes();

  public is_edit = [];
  public editar = [];
  
  public modeloEducativo = [];
  public modeloEducativoselected = [];
  public modeloEducativoaux = [];
  public asignaturas = [];
  public asignaturasaux = [];
  public formacion = [];
  public formacionaux = [];
  public eje = [];
  public ejeaux = [];
  public temas = [];
  public temasaux = [];
  public aprendizajesaux = [];
  public aprendizajes = [];
  public aprenselected = [];
  public aprenselectedid = [];

  public rmateriasall = [];
    public rmateriasgrado = [];
    public rmaterias = [];
    public rmodeloeducativo = [];
    public raprendizajesesp = [];
    public rcompetencias = [];
    public raprendizajesespaux = [];
    public rcompetenciasaux = [];
    public rbloque = [];
    public rrefmost = [];
    public raprenselected = [];
    public rcompetselected = [];
    public rejetem = [];
    public rttx = [];
    public rpsl = [];
    public rtemas = [];
    public rlengart = [];
    public rmodselected = [];

    public rvs = [{partes: 'Selecciona la parte que corresponde la planeación', contenido: 'Selecciona el contenido que corresponde la planeación', sesion: '', objetivo: ''}];
    public rvspartes = []
    public rvscontenido = [];
    public rvssesion = [];
    public rvsobjetivo = [];
    public rvssesobjetivo = [];
    public rvsvidasaludable = [];

    public idReformaOnce;
    public bl = 0;
    public activar = 0;
    public idBloque;
    public idAmbitoEje;
    public idTemas;
    public idLengArt;
    public vidasal = 0;

  public select = 0;
  public otro = 2;
  public sesiones;
  Arr = Array;
  public numberSesionesN = Array();
  public sesionnueva:number = 0;
  public sesionesinfo ;
  public ejeambito: boolean; //0=ambito 1=eje

  public gruposSelected = [];
    
  public ccts;
  private idGrado;
  private idFormacion;
  private idAsignatura;
  private idEje;
  private idTema;
  private idModEdu;

  public id  ;
  public slug;
  public info;
  public idRuta;
  public games;
  public recursos;
  public libros;
  public grupos;
  public classrooms;
  public grupoplanes;
  public gruposModal;
  public parametros;
  public infoplanesLT;
  public planEdit = new Plan();
  public navegacion = new Navegacion();

  public juegosRecursos = new JuegosRecursosSesion();
  
  public arraySesiones = [];

  public todosGrupos = false; 
  public reforma = -1;
  public reformatxt ='';
  public busqueda = 0;
  public grado_bus = 0;
  public materia_bus = 0;
  public cambiarmod = 0;
  public fromBusqueda = 0;

  public detalleplan = [];
  public detallereformas = [];

  Grados: Array<any> = [
    { grado: 'Primero de primaria', id: 1 },
    { grado: 'Segundo de primaria', id: 2 },
    { grado: 'Tercero de primaria', id: 3 },
    { grado: 'Cuarto de primaria', id: 4 },
    { grado: 'Quinto de primaria', id: 5 },
    { grado: 'Sexto de primaria', id: 6 },
  ];

  private sections: any;

  constructor(private detalleService: DetalleService, private imprimirService: ImprimirService, private activatedRoute: ActivatedRoute,private router:Router, private userService: UserService) {
      this.navegacion.seccion = 12;
      this.detalleService.navegacion(this.token, this.navegacion).subscribe();
   }

  ngOnInit() {

    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    
    this.sections = this.userService.getSecciones();
    
    for (let index = 0; index <25; index++) {
      this.is_edit[index] = false;
    }
    for (let index = 0; index < 28; index++) {
      this.editar[index] = false;
    }

    this.activatedRoute.params.subscribe(async params => {
      this.parametros = params;
      this.id = params.id;
      this.idRuta = params.id;
      this.slug=params.slug;
      localStorage.setItem('PlanId', this.id);
      localStorage.setItem('PlanSlug', this.slug);

      /* OBTENER EL DETALLE DEL PLAN */
      
      await this.detalleService.detallePlanes(this.token, this.id, params.slug).subscribe( (response: any) => { 
        console.log(response);
        this.detalleplan= response.plan;
        this.games = response.plan.games ;
        this.recursos = response.plan.contenidos.recursos;
        this.libros = response.plan.contenidos.libros;
        this.classrooms = response.plan.contenidos.classroom;
        this.gruposSelected = response.plan.contenidos.classroom;
        this.info = response.plan.info;

        this.detalleService.grupos(this.token).subscribe(async (response: any) => {
          if(response["message"]!= "No hay grupos"){
            this.gruposModal = [];
            this.grupos = response.grupos;
            this.gruposModal = this.grupos.slice();
      
            await this.gruposModal.forEach(element => {
              element.status = false;
            });
      
          if(this.classrooms.length>0){
            let a = 0,  i=0;
      
            this.gruposModal.forEach(gm => {
              a=0; i=0;
              while(a==0){
                if (gm.id == this.classrooms[i].id_classroom ){
                  gm.status = true;
                  a=1;
                }
                else {
                  if(i<this.classrooms.length-1) i=i+1;
                  else a=1;
                }
              }
            });
          }
            }
          
        });

        this.inicioPlan.name = this.info.name;
        this.inicioPlan.descripcion = this.info.descripcion;
        this.inicioPlan.busc = this.info.busc;
        this.busqueda = this.inicioPlan.busc;
        this.nuevoplan.ciclo = this.info.ciclo;

        this.nuevoplan.aprendizajes_clave = this.info.aprendizajes_clave;
        this.nuevoplan.area = this.info.area;
        this.nuevoplan.bloque = this.info.bloque;
        this.nuevoplan.cct = this.info.cct;
        this.nuevoplan.contexto = this.info.contexto;
        this.nuevoplan.director = this.info.director;
        this.nuevoplan.duracion = this.info.duracion;
        this.nuevoplan.fecha = this.info.fecha;
        this.nuevoplan.grado = this.info.grado;
        this.grado_bus = this.info.grado;
        this.nuevoplan.institucion = this.info.institucion;
        this.nuevoplan.nombre_docente = this.info.nombre_docente;
        this.nuevoplan.propositos_pedagogicos = this.info.propositos_pedagogicos;
        this.nuevoplan.semana = this.info.semana;
        this.nuevoplan.reforma = this.info.reforma;
        this.nuevoplan.sesiones = this.info.sesiones;

        if(this.nuevoplan.reforma=='' && this.busqueda==1) {this.otro=2; this.reforma=-1; this.fromBusqueda=1;}
        else if(this.nuevoplan.reforma=='' && this.busqueda==0) {this.otro=2; this.reforma=4}
        else if(this.nuevoplan.reforma=='libre') {this.otro=1; this.reforma=0; this.reformatxt = 'Planeación libre';}
        else if(this.nuevoplan.reforma=='2017') {this.otro=0; this.reforma=2; this.reformatxt = 'México - Reforma educativa 2017';}
        else if(this.nuevoplan.reforma=='2011') {this.otro=3; this.reforma=1; this.reformatxt = 'México - Reforma educativa 2011';}

        this.sesiones = response.plan.sesiones.length;
        this.sesionesinfo = response.plan.sesiones;

        if(this.otro==0){ //Modelo 2017
          this.idModEdu = response.plan.mod_edu[0].id_modelo_educativo;
          this.aprenselectedid = response.plan.mod_edu[0].id_aprendizajes.split(',');
          this.idGrado = this.info.grado;

          this.detalleService.getModedu(this.token).subscribe(async (response: any) => {
            this.detallereformas = response;
            this.modeloEducativo = await response.modedu;
            this.formacion = response.formacion;
            this.asignaturas = response.asignaturas;
            this.eje = response.eje_amb;
            this.temas = response.temas;
            this.aprendizajes = response.aprendizajes;
            
            if(this.idModEdu!=undefined || this.idModEdu!=null){
              this.modeloEducativo.forEach(me => {
                if(this.idModEdu == me.idModEdu) {
                  this.modeloEducativoselected = me;
                  console.log('modeloEducativoselected',this.modeloEducativoselected);
                  this.idAsignatura = me.idasignatura;
                  this.checarGradoMateria();
                  if(me.eje_ambito == 1)  this.ejeambito = true;
                  else this.ejeambito = false;
                }
              });
            }
            
            if(this.aprenselectedid.length>0){
              this.aprendizajes.forEach(apren => {
                this.aprenselectedid.forEach(aprenid => {
                  if(apren.id == aprenid) this.aprenselected.push(apren);
                });
              });
            }
          });

        }
        else if(this.otro==1){//Modelo libre
          this.select = 1;
          this.ejeambito = true;
          this.modEduplan.formacion = response.plan.mod_edu[0].formacion;
          this.modEduplan.asignatura = response.plan.mod_edu[0].asignatura;
          this.modEduplan.eje = response.plan.mod_edu[0].eje;
          this.modEduplan.tema = response.plan.mod_edu[0].tema;
          this.modEduplan.aprendizajes_esperados = response.plan.mod_edu[0].aprendizajes_esperados;
        }
        else if(this.otro==3){//Reforma 2011 Obtener
          this.idGrado = this.nuevoplan.grado;

          if(response.plan.mod_edu[0].id_mod_edu_2011 == null){
            this.modEduplan.vida_saludable = response.plan.mod_edu[0].vida_saludable;
            this.idAsignatura = 19;
          }
          else{
            this.modEduplan.id_mod_edu_2011 = response.plan.mod_edu[0].id_mod_edu_2011;
            this.modEduplan.id_apren_2011 = response.plan.mod_edu[0].id_apren_2011;
            this.modEduplan.id_comp_2011 = response.plan.mod_edu[0].id_comp_2011;
            this.idAsignatura = response.plan.reformaonce[0].asignatura;
            this.idReformaOnce = this.modEduplan.id_mod_edu_2011;
          } 
          this.detalleService.getReformaxGrado(this.token, this.idGrado, this.idAsignatura).subscribe((response: any) => {
            this.detallereformas = response;
            this.rbloque = [];
            if(this.idAsignatura!=19){
                this.rmodeloeducativo = response.reforma;
                this.raprendizajesesp = response.aprendizajes;
                this.rcompetencias = response.competencias;

                if(this.idAsignatura== 2 || this.idAsignatura == 11 || this.idAsignatura==15){
                    this.rbloque = [
                        { bloque: 'Bloque 1', id: 1 },
                        { bloque: 'Bloque 2', id: 2 },
                        { bloque: 'Bloque 3', id: 3 },
                        { bloque: 'Bloque 4', id: 4 },
                        { bloque: 'Bloque 5', id: 5 },
                    ];
                }
                else{
                    response.reforma.forEach((element, index) => {
                        let b = 'Bloque ' + element.bloque + ' - ' + element.nombre_bloque;
                        if(index==0) this.rbloque.push({bloque: b, id: element.bloque});
                        else{
                            let i = this.rbloque.length;
                            if(element.bloque != this.rbloque[i-1].id) this.rbloque.push({bloque: b, id: element.bloque});
                        }
                    });
                }

                this.rmodeloeducativo.forEach(r => {
                  if(r.id == this.idReformaOnce) {
                    this.rmodselected.push(r); 
                    this.idBloque = r.bloque
                  }
                });

                let arraycomp = this.modEduplan.id_comp_2011.split(',');
                let arrayapre = this.modEduplan.id_apren_2011.split(',');

                this.rcompetencias.forEach(c => {
                  if(c.mod_edu_2011 == this.idReformaOnce) {
                    arraycomp.forEach(ac => {
                      if(c.idcomp == ac) this.rcompetselected.push(c);
                    });
                  }
                });
                this.raprendizajesesp.forEach(a => {
                  if(a.mod_edu_2011 == this.idReformaOnce) {
                    arrayapre.forEach(ac => {
                      if(a.idapren == ac)  this.raprenselected.push(a);
                    });
                  }
                });
          
                this.bl =1;
                this.activar=1;
            }
            else{
              this.rvspartes = response.reforma.partes;
              this.rvscontenido = response.reforma.contenido;
              this.rvssesion = response.reforma.sesion;
              this.rvssesobjetivo = response.reforma.sesobj;
              this.rvsvidasaludable = response.reforma.vida_saludable;

              this.rvsvidasaludable.forEach(vs => {
                if(this.modEduplan.vida_saludable == vs.id){
                  this.rvs[0].partes = vs.partes;
                  this.rvs[0].contenido = vs.contenido;
                  this.rvs[0].objetivo = vs.objetivos;
                  this.rvs[0].sesion = vs.sesion;
                }
              });
              this.vidasal = 1;
            }
          });
          this.checarGradoMateria();
        }
        else this.idModEdu = null;

        let juegostotales = "";
        let recursostotales = "";
        let librostotales = "";

        this.games.forEach(g => {
          if(juegostotales=="") juegostotales = g.id_game;
          else juegostotales = juegostotales + ',' + g.id_game;
        });
        this.recursos.forEach(r => {
          if(recursostotales=="") recursostotales = r.id_recurso;
          else recursostotales = recursostotales + ',' + r.id_recurso;
        });
        this.libros.forEach(l => {
          if(librostotales=="") librostotales = l.id_libro;
          else librostotales = librostotales + ',' + l.id_libro;
        });
        
        if(this.sesionesinfo.length==0) {
          if(juegostotales!="" || recursostotales!="" || librostotales!="") this.agregarSesionnueva();
        }
        //console.log(this.sesionesinfo);
        this.sesionesinfo.forEach(ses => {
          this.juegosRecursos.juegos = ses.juegos;
          this.juegosRecursos.otros_recursos = ses.otros_recursos;
          this.juegosRecursos.libros = ses.libros;
          this.juegosRecursos.id = ses.id;

          if(this.juegosRecursos.otros_recursos=="" && this.juegosRecursos.juegos=="" && this.juegosRecursos.libros=="") {
            if(this.juegosRecursos.juegos=="") {
              this.juegosRecursos.juegos = juegostotales;
              ses.juegos = this.juegosRecursos.juegos;
            }
            if(this.juegosRecursos.otros_recursos==""){
              this.juegosRecursos.otros_recursos = recursostotales;
              ses.otros_recursos = this.juegosRecursos.otros_recursos;
            }
            if(this.juegosRecursos.libros==""){
              this.juegosRecursos.libros = librostotales;
              ses.libros = this.juegosRecursos.libros;
            }
            this.detalleService.editarJuegoRecSesiones(this.token, this.juegosRecursos ,this.id).subscribe();
          }
        });



      });
  
    });

    this.detalleService.detallePerfil(this.token).subscribe((response:any)=>{
      this.ccts = response.cct_info;
    });

    setTimeout(function () {
      $(".js-modal-group-2").click(function (e) {
        e.preventDefault();
        $(".modal--medium2").modal("show");
      });
    }, 1500);
  }

  checarGradoMateria(){
    if(this.idAsignatura==1) this.materia_bus = 10; // Lenguaje y comunicacion
        else if(this.idAsignatura==2) this.materia_bus = 5; //matematicas
        else if(this.idAsignatura==3) this.materia_bus = 6; //Ecologia
        else if(this.idAsignatura==4) this.materia_bus = 13; //Civica y etica
        else if(this.idAsignatura==5) this.materia_bus = 3; //Fisica
        else if(this.idAsignatura==6) this.materia_bus = 2; //Historia
        else if(this.idAsignatura==7) this.materia_bus = 12; //Geografia
        else if(this.idAsignatura==8) this.materia_bus = 13; //Civica y etica
        else if(this.idAsignatura==9) this.materia_bus = 4; //Artes
        else if(this.idAsignatura==10) this.materia_bus = 8; //Ed. socioemocional
        else if(this.idAsignatura==11) this.materia_bus = 10; // Lenguaje y comunicacion
        else if(this.idAsignatura==12) this.materia_bus = 6; //Ecologia
        else if(this.idAsignatura==13) this.materia_bus = 3; //Ciencias Naturales
        else if(this.idAsignatura==14) this.materia_bus = 13; //Formacion civica y etica
        else if(this.idAsignatura==15) this.materia_bus = 4; //Artes
        else if(this.idAsignatura==16) this.materia_bus = 13; //Civica y etica
        else if(this.idAsignatura==17) this.materia_bus = 12; //Geografia
        else if(this.idAsignatura==18) this.materia_bus = 2; //Historia
        else if(this.idAsignatura==19) this.materia_bus = 1; //Vida Saludable
  }


  deletePlan(){
    Swal.fire({
      title: '¿Estas seguro de eliminar tu planeación?',
      showCancelButton: true,
      confirmButtonColor: '#0098CE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText:'Cancelar'
  }).then((result) => {
      if (result.value) {
          this.detalleService.eliminarPlan(this.token, this.info.id).subscribe((response: any) => {
              Swal.fire({
                  type: 'success',
                  title: 'Plan eliminado ',
                  showConfirmButton: false,
                  timer: 1500
              });
              this.router.navigate(["/mis-planes"]);
              //console.log("hecho");
          }, error => {
              Swal.fire({
                  type: 'error',
                  title: 'Error al eliminar el plan ',
                  showConfirmButton: false,
                  timer: 1500
              });
              // -----
              this.router.navigate(["/mis-planes"]);

          });
      }
  });
  }
  
  eliminarGrupo(id){
    this.detalleService.deleteGrupo(this.token,this.id,id).subscribe((response:any)=>{
      //console.log(response);
      this.detalleService.detallePlanes(this.token, this.id, this.slug).subscribe((response: any) => {
        this.classrooms = response.plan.contenidos.classroom;
        this.info = response.plan.info;

        this.detalleService.grupos(this.token).subscribe((response: any) => {
          this.grupos = response.grupos;
          this.gruposModal = this.grupos.slice();

          this.gruposModal.forEach(element => {
            element.status = false;
          });

          for(let i in this.classrooms){
            for(let x in this.gruposModal){
              if (this.gruposModal[x].id == this.classrooms[i].id_classroom ){
                this.gruposModal[x].status = true;
                //this.gruposModal.splice(x,1);
              }
            }
          }
        });
      });
    });
    $(".modal .close").click(function () {
      $('.modal').fadeOut();
      $('.modal').removeClass('show');
    });

  }

  vincularGrupo(grupo) {
    this.detalleService.vincularGrupo(this.token, this.id, grupo).subscribe((response: any) => {
      this.detalleService.detallePlanes(this.token, this.id, this.slug).subscribe((response: any) => {
        this.classrooms = response.plan.contenidos.classroom;
        this.info = response.plan.info;

        this.detalleService.grupos(this.token).subscribe((response: any) => {
          this.grupos = response.grupos;
          this.gruposModal = this.grupos.slice();

          this.gruposModal.forEach(element => {
            element.status = false;
          });

          for(let i in this.classrooms){
            for(let x in this.gruposModal){
              if (this.gruposModal[x].id == this.classrooms[i].id_classroom ){
                this.gruposModal[x].status = true;
              }
            }
          }
        });
      });
    });
  }

  isDisabled(value) {
    (this.is_edit[value]) ? this.is_edit[value] = false :  this.is_edit[value] = true; 
  }
  isEditable(value){
      (this.editar[value]) ? this.editar[value] = false :  this.editar[value] = true; 

      if(value==9){ this.changeEje(this.modeloEducativoselected['idasignatura'], 0);}
      if(value==10){ this.changeTema(this.modeloEducativoselected['ideje'], 0);}
      if(value==11){ this.changeAprendizajes(this.modeloEducativoselected['idtemas'], 0);}
      if(value==26){ this.cargarcompapre(0);}
      if(value==27){ this.cargarcompapre(1);}

  }

  /* REFORMA 2017 */
  changeFormacion:any = (e) => {
      this.modeloEducativoaux.splice(0);
      this.formacionaux.splice(0);
      this.asignaturasaux.splice(0);
      this.ejeaux.splice(0);
      this.temasaux.splice(0);
      this.aprendizajesaux.splice(0);
      this.cambiarmod = 1;
      //this.modeloEducativoaux = this.modeloEducativo.slice();
      this.idGrado = e.target.value;
      this.formacionaux = this.formacion.slice();
      this.modeloEducativo.forEach((modedu, index) => {
          if(this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level){
              this.modeloEducativoaux.push(modedu);
          }
      });
  }

changeAsig:any = (e) => {
    this.asignaturasaux.splice(0);
    this.ejeaux.splice(0);
    this.temasaux.splice(0);
    this.aprendizajesaux.splice(0);
    this.idFormacion = e.target.value;
    if(this.idFormacion == 0) {this.select = 1; this.aprenselected = [] }
    else{
        this.select = 0;
        this.asignaturasaux = this.asignaturas.slice();

        this.modeloEducativo.forEach(modedu => {
            if(this.idFormacion != modedu.idformacion){
                this.asignaturasaux.forEach(function(asi, index, object) {
                    if(asi.asignatura == modedu.asignatura){
                        object.splice(index,1);
                    }
                });
            }
            else {
                if((this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level) == false) {
                    this.asignaturasaux.forEach(function(asi, index, object) {
                        if(asi.asignatura == modedu.asignatura){
                            object.splice(index,1);
                        }
                    });
                }
            }
        });
    }
}

changeEje:any = (e,value) =>{
    this.ejeaux.splice(0);
    this.temasaux.splice(0);
    this.aprendizajesaux.splice(0);
    (value==1) ? this.idAsignatura = e.target.value : this.idAsignatura = e;
    this.cambiarmod=1; 
    this.ejeaux = this.eje.slice();

    this.modeloEducativo.forEach(modedu => {
        if(this.idAsignatura != modedu.idasignatura){
            this.ejeaux.forEach(function(eje, index, object) {
                if(eje.descripcion == modedu.descripcion){
                    object.splice(index,1);
                } 
            });
        }
        else {
            if((this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level) == false) {
                this.ejeaux.forEach(function(eje, index, object) {
                    if(eje.descripcion == modedu.descripcion){
                        object.splice(index,1);
                    } 
                });
            }
        }
    });

    if(this.ejeaux[0].ambito_eje == "0") this.ejeambito = false;
    else this.ejeambito = true;

    this.checarGradoMateria();
}

changeTema:any = (e,value) => {
    this.temasaux.splice(0);
    this.aprendizajesaux.splice(0);
    (value==1) ? this.idEje = e.target.value : this.idEje = e;
    this.cambiarmod=1; 
    this.temasaux = this.temas.slice();
    this.modeloEducativo.forEach(modedu => {
        if(this.idEje != modedu.ideje){
            this.temasaux.forEach(function(tema, index, object) {
                if(tema.practicas_temas == modedu.practicas_temas){
                    object.splice(index,1);
                } 
            });
        }
        else {
            if((this.idGrado >= modedu.first_level && this.idGrado <= modedu.last_level) == false) {
                this.temasaux.forEach(function(tema, index, object) {
                    if(tema.practicas_temas == modedu.practicas_temas){
                        object.splice(index,1);
                    } 
                });
            }
        }
    });
}

changeAprendizajes:any = (e,value) => {
    this.aprendizajesaux.splice(0);
    (value==1) ? this.idTema = e.target.value : this.idTema = e;
    
    this.modeloEducativo.forEach(modedu => {
        if(this.idTema == modedu.idtemas) this.idModEdu = modedu.idModEdu;
    });

    this.aprendizajes.forEach(apren => {
        if(apren.modelo_educativo == this.idModEdu){
            if(apren.grado == this.idGrado){
                this.aprendizajesaux.push(apren);
            }
        }
    });
}

  changeAll() {
    if(this.todosGrupos) this.todosGrupos = false;
    else{
      this.todosGrupos = true;
      this.gruposModal.forEach(element => {
        if(!element.status){ 
          element.status=true;
          this.vincularGrupo(element.id);
        }
      });
    }
    
  }

  changeStatus(index){
    if(this.classrooms.length<1) this.vincularGrupo(this.gruposModal[index].id);
    else{
      for (let j = 0; j < this.classrooms.length; j++) {
        if((this.gruposModal[index].id == this.classrooms[j].id_classroom) && this.gruposModal[index].status){
          this.eliminarGrupo(this.gruposModal[index].id);
          this.gruposModal[index].status = false;
        }
        else if(!this.gruposModal[index].status && (this.gruposModal[index].id != this.classrooms[j].id_classroom)){
          this.vincularGrupo(this.gruposModal[index].id);
          this.gruposModal[index].status = true;
        }
      }
    }

  }

  cambiar(){
    if(this.arraySesiones.length>0){ //Nuevas sesiones
      this.arraySesiones.forEach(ses => {
        this.detalleService.editarNuevoPlan(this.token,ses,this.id).subscribe((response:any)=>{});
      });
    }

    this.revisarDes();

    this.updatePlan.name = this.inicioPlan.name;
    this.updatePlan.descripcion = this.inicioPlan.descripcion;
    this.updatePlan.nuevoPlan =  this.nuevoplan;
    this.updatePlan.sesiones = this.sesionesinfo;

    console.log(this.updatePlan)

    if(this.otro==0){ //Modelo 2017 EDITAR
      this.updatePlan.modeloEducativo.id_modelo_educativo = this.idModEdu;
      this.updatePlan.modeloEducativo.id_apren_2011 = null;
      this.updatePlan.modeloEducativo.id_comp_2011 = null;
      this.updatePlan.modeloEducativo.id_mod_edu_2011 = null;
      this.updatePlan.modeloEducativo.vida_saludable = null;
      if(this.aprenselected.length>0){
        this.aprenselected.forEach((element, index) => {
          if(index==0) this.updatePlan.modeloEducativo.id_aprendizajes = element.id;
          else this.updatePlan.modeloEducativo.id_aprendizajes = this.updatePlan.modeloEducativo.id_aprendizajes + ',' + element.id;
        });
      }
      else this.updatePlan.modeloEducativo.id_aprendizajes = null;
      this.updatePlan.modeloEducativo.formacion = null;
      this.updatePlan.modeloEducativo.eje = null;
      this.updatePlan.modeloEducativo.asignatura = null;
      this.updatePlan.modeloEducativo.tema = null;
      this.updatePlan.modeloEducativo.aprendizajes_esperados = null;
    }
    else if(this.otro==1){ //Libre EDITAR
      this.updatePlan.modeloEducativo = this.modEduplan;
      this.updatePlan.modeloEducativo.id_aprendizajes = null;
      this.updatePlan.modeloEducativo.id_modelo_educativo = null;
      this.updatePlan.modeloEducativo.id_apren_2011 = null;
      this.updatePlan.modeloEducativo.id_comp_2011 = null;
      this.updatePlan.modeloEducativo.id_mod_edu_2011 = null;
      this.updatePlan.modeloEducativo.vida_saludable = null;
    } 
    else if(this.otro==3){ //Reforma 2011 EDITAR
      this.updatePlan.modeloEducativo.id_aprendizajes = null;
      this.updatePlan.modeloEducativo.formacion = null;
      this.updatePlan.modeloEducativo.eje = null;
      this.updatePlan.modeloEducativo.asignatura = null;
      this.updatePlan.modeloEducativo.tema = null;
      this.updatePlan.modeloEducativo.aprendizajes_esperados = null; 
      this.updatePlan.modeloEducativo.id_modelo_educativo = null; 

      if(this.idAsignatura==19){
        this.updatePlan.modeloEducativo.id_mod_edu_2011 = null;
        this.updatePlan.modeloEducativo.id_apren_2011 = null;
        this.updatePlan.modeloEducativo.id_comp_2011 = null;

        this.rvsvidasaludable.forEach(v => {
          if((v.partes == this.rvs[0].partes) && (v.contenido == this.rvs[0].contenido) 
              &&(v.sesion == this.rvs[0].sesion) && (v.objetivos == this.rvs[0].objetivo)){
              this.updatePlan.modeloEducativo.vida_saludable = v.id;
          }
        });
      }
      else{
        this.updatePlan.modeloEducativo.vida_saludable=null;
        this.updatePlan.modeloEducativo.id_mod_edu_2011 = this.idReformaOnce;

        let allapren = '';
            let allcompt = '';
            this.raprenselected.forEach(element => {
                if(allapren=='') allapren = element.idapren;
                else allapren = allapren + ',' + element.idapren;
             }); 
            this.rcompetselected.forEach(element => {
                if(allcompt=='') allcompt = element.idcomp;
                else allcompt = allcompt + ',' + element.idcomp;
             });
            this.updatePlan.modeloEducativo.id_apren_2011 = allapren;
            this.updatePlan.modeloEducativo.id_comp_2011 = allcompt;
      }
    }

    if(this.fromBusqueda==1){
      this.modEduplan = this.updatePlan.modeloEducativo;
      this.detalleService.editarNuevoPlan(this.token,this.modEduplan,this.id).subscribe((response:any)=>{});
    }

    this.detalleService.editarNuevoPlan(this.token,this.updatePlan,this.id).subscribe((response:any)=>{
      Swal.fire({
        type: 'success',
        title: 'Planeación actualizada',
        showConfirmButton: false,
        timer: 3000
      });
      this.router.navigate(["/mis-planes"]);
    },error => {
      console.log(error);
      this.router.navigate(["/mis-planes"]);
    });

  }

  changeReforma:any = (e) => {
    if(this.reforma==0){
        this.select = 1;
        this.otro=1;
        this.ejeambito = true;
        this.aprenselected = [];
        this.nuevoplan.reforma= 'libre';
    }
    else if(this.reforma==1){
        this.nuevoplan.reforma= '2011';
        this.otro=3;
        this.detalleService.getMateriaGrado(this.token).subscribe((response: any) => {
            this.rmateriasgrado = response.grado_mat;
            this.rmateriasall = response.materias;
        });
    }
    else if(this.reforma==2){
        this.select = 0; 
        this.otro=0;
        this.nuevoplan.reforma= '2017';
        this.detalleService.getModedu(this.token).subscribe((response: any) => {
            this.modeloEducativo = response.modedu;
            this.formacion = response.formacion;
            this.asignaturas = response.asignaturas;
            this.eje = response.eje_amb;
            this.temas = response.temas;
            this.aprendizajes = response.aprendizajes;
        });
        this.ejeambito = true;    
    }
  }

  recibirSesiones(sesion){
    this.sesionesinfo[sesion.numero-1] = sesion;
    console.log(this.sesionesinfo);
  }

  recibirSesionesNuevas(sesion){
    let ses = new Sesiones();
    ses = sesion;
    let chk = 0;
    let index;
    this.arraySesiones.forEach((s,k) => {
      if(s.numero == ses.numero){ chk++; index=k; }
    });
    if(chk==0) this.arraySesiones.push(ses);
    else this.arraySesiones[index] = ses; 
  }

  modalVincular(){
    $(".js-modal-group-2").click(function (e) {
      e.preventDefault();
      $(".modal--medium2").modal("show");
    });
  }

  /* REFORMA 2011 */
  changeMaterias:any = (e) => {
    this.rmaterias.slice();
    this.rmaterias = [];
    this.rejetem =[];
    this.raprenselected = [];
    this.rcompetselected = [];
    this.rtemas = [];
    this.idGrado = e.target.value;
    this.grado_bus = this.idGrado;

    this.activar = 0;
    this.bl=0;
    this.vidasal=0;

    this.rmateriasgrado.forEach(element => {
        if(element.grado == this.idGrado){
            this.rmaterias.push(element);
        }
    });
}

obtenerBloque:any = (e,value) => {
    (value==1) ? this.idAsignatura = e.target.value : this.idAsignatura = e;
    this.raprenselected = [];
    this.rcompetselected = [];
    this.rejetem =[];
    this.rtemas = [];
    this.activar = 0;
    
    this.detalleService.getReformaxGrado(this.token, this.idGrado, this.idAsignatura).subscribe((response: any) => {
        this.rbloque = [];
        if(this.idAsignatura!=19){
            this.rmodeloeducativo = response.reforma;
            this.raprendizajesesp = response.aprendizajes;
            this.rcompetencias = response.competencias;

            if(this.idAsignatura== 2 || this.idAsignatura == 11 || this.idAsignatura==15){
                this.rbloque = [
                    { bloque: 'Bloque 1', id: 1 },
                    { bloque: 'Bloque 2', id: 2 },
                    { bloque: 'Bloque 3', id: 3 },
                    { bloque: 'Bloque 4', id: 4 },
                    { bloque: 'Bloque 5', id: 5 },
                ];
            }
            else{
                response.reforma.forEach((element, index) => {
                    let b = 'Bloque ' + element.bloque + ' - ' + element.nombre_bloque;
                    if(index==0) this.rbloque.push({bloque: b, id: element.bloque});
                    else{
                        let i = this.rbloque.length;
                        if(element.bloque != this.rbloque[i-1].id) this.rbloque.push({bloque: b, id: element.bloque});
                    }
                });
            }
            this.bl =1; 
        }
        else{ //Vida Saludable
            this.bl =0;
            this.rvspartes= response.reforma.partes;
            this.rvscontenido = response.reforma.contenido;
            this.rvssesion = response.reforma.sesion;
            this.rvssesobjetivo = response.reforma.sesobj;
            this.rvsvidasaludable = response.reforma.vida_saludable;
            this.vidasal =1;
        }
    });

    this.checarGradoMateria();
}

obtenerReforma:any = (e) => {

    this.idBloque= e.target.value;
    this.activar = 1;
    this.rrefmost = [];
    this.rcompetenciasaux = [];
    this.raprendizajesespaux = [];
    this.rejetem =[];
    this.rtemas = [];
    this.rlengart = [];

    if(this.idAsignatura==12 || this.idAsignatura==13 || this.idAsignatura==14 || 
        this.idAsignatura==16 || this.idAsignatura==17 || this.idAsignatura==18 ){

        this.rmodeloeducativo.forEach(md => {
            if(md.bloque == this.idBloque)
                this.rrefmost.push(md);
        }); 
        this.rcompetencias.forEach(c  => {
            if(c.mod_edu_2011 == this.rrefmost[0].id) this.rcompetenciasaux.push(c);
        });
        this.raprendizajesesp.forEach(ae  => {
            if(ae.mod_edu_2011 == this.rrefmost[0].id) this.raprendizajesespaux.push(ae);
        });

        this.idReformaOnce = this.rrefmost[0].id; // ID DE LA REFORMA

        if(this.idAsignatura==14 || this.idAsignatura==17 ){
            this.rrefmost.forEach(r => { //Eje Tematico
                this.rejetem.push({eje_ambito: r.eje_ambito, descripcion: r.descripcion});
            });
        }
    }

    else if(this.idAsignatura==11 ){ //ESPAÑOL
        this.rmodeloeducativo.forEach(md => {
            if(md.bloque == this.idBloque)
                this.rrefmost.push(md);
        });
        this.rrefmost.forEach(r => { //Ambito
            this.rejetem.push({eje_ambito: r.eje_ambito, descripcion: r.descripcion});
        }); 
    }

    else if(this.idAsignatura==2){ //Mate 
        this.rmodeloeducativo.forEach(md => {
            if(md.bloque == this.idBloque)
                this.rrefmost.push(md);
        });
        this.rrefmost.forEach((r,index) => { //Eje
            if(index == 0) this.rejetem.push({eje_ambito: r.eje_ambito, descripcion: r.descripcion});
            else{
                let i = this.rejetem.length;
                if(r.eje_ambito != this.rejetem[i-1].eje_ambito) this.rejetem.push({eje_ambito: r.eje_ambito, descripcion: r.descripcion});
            }
        }); 
    }
    
    else if(this.idAsignatura==15){ //Ed.Art.
        this.rmodeloeducativo.forEach(md => {
            if(md.bloque == this.idBloque)
                this.rrefmost.push(md);
        });
        this.rrefmost.forEach((r,index) => { //Leng.art
            if(index == 0) this.rlengart.push({leng_artistico: r.leng_artistico, lenguaje_artistico: r.lenguaje_artistico});
            else{
                let i = this.rlengart.length;
                if(r.leng_artistico != this.rlengart[i-1].leng_artistico)
                    this.rlengart.push({leng_artistico: r.leng_artistico, lenguaje_artistico: r.lenguaje_artistico});
            }
        }); 
    }
}

changetdt:any = (e) => {
    this.idAmbitoEje = e.target.value;
    this.rttx = [];
    this.rpsl = [];
    if(this.idAsignatura==11){ //ESPAÑOL
        this.raprendizajesespaux = [];
        this.rcompetenciasaux = [];
        this.rrefmost.forEach((rr, index) => {
            if(rr.eje_ambito == this.idAmbitoEje) {
                this.rttx.push({ttid: rr.ttid, tipo_texto: rr.tipo_texto});
                this.rpsl.push({pract_social: rr.pract_social, practica_social_lenguaje: rr.practica_social_lenguaje })
                this.rcompetencias.forEach(c  => {
                    if(c.mod_edu_2011 == rr.id) this.rcompetenciasaux.push(c);
                });
                this.raprendizajesesp.forEach(ae  => {
                    if(ae.mod_edu_2011 == rr.id) this.raprendizajesespaux.push(ae);
                });
                this.idReformaOnce = rr.id;
            }
        });
    }
    else if(this.idAsignatura==14){ //FCYE
        this.rrefmost.forEach(rr => {
            if(rr.eje_ambito == this.idAmbitoEje) {
                this.idReformaOnce = rr.id;
            }
        });
    }
    
}

changeTem:any = (e) => {
    this.rtemas = [];
    this.idAmbitoEje = e.target.value;

    if(this.idAsignatura==2){ //Matematicas
        this.rrefmost.forEach((rr, index) => {
            if(rr.eje_ambito == this.idAmbitoEje) { 
                this.rtemas.push(rr);
            }
        });
    }

    else if(this.idAsignatura==15){ //Ed.Art
        this.rrefmost.forEach((rr, index) => {
            if(rr.leng_artistico == this.idLengArt && rr.eje_ambito == this.idAmbitoEje) { 
                this.rtemas.push(rr);
            }
        });
    }
    
}

changeLeng:any = (e) => {
    this.rtemas = [];
    this.rejetem = [];
    
    this.rcompetenciasaux = [];
    this.raprendizajesespaux = [];
    
    this.idLengArt = e.target.value;

    if(this.idAsignatura==15){ //Ed.Art
        this.rrefmost.forEach((rr, index) => {
            if(rr.leng_artistico == this.idLengArt) { 
                this.rejetem.push(rr);
            }
        });
    }
    
}

changeMate:any = (e) => {
    
    this.rcompetenciasaux = [];
    this.raprendizajesespaux = [];
    this.idTemas = e.target.value;

    if(this.idAsignatura==2){
        this.rrefmost.forEach((rr, index) => {
            if(rr.tema == this.idTemas) {
                this.rcompetencias.forEach(c  => {
                    if(c.mod_edu_2011 == rr.id) this.rcompetenciasaux.push(c);
                });
                this.raprendizajesesp.forEach(ae  => {
                    if(ae.mod_edu_2011 == rr.id) this.raprendizajesespaux.push(ae);
                });
                this.idReformaOnce = rr.id;
            }
        });
    }
    else if(this.idAsignatura==15){
    
        this.rrefmost.forEach((rr, index) => {
            if(rr.tema == this.idTemas && rr.eje_ambito == this.idAmbitoEje) {
                this.rcompetencias.forEach(c  => {
                    if(c.mod_edu_2011 == rr.id) this.rcompetenciasaux.push(c);
                });
                this.raprendizajesesp.forEach(ae  => {
                    if(ae.mod_edu_2011 == rr.id) this.raprendizajesespaux.push(ae);
                });
                this.idReformaOnce = rr.id;
            }
        });
    }
}

changeContenidovs:any = (e) => {
  this.rvscontenido = [];
  this.rvs[0].partes = e.target.value;
  this.rvsvidasaludable.forEach(vs => {
      if(this.rvs[0].partes == vs.partes){
          if(this.rvscontenido.length==0) this.rvscontenido.push({contenido: vs.contenido});
      }
  });
}

changeObjetivosvs:any = (e) => {
    this.rvs[0].sesion = e.target.value;
    this.rvsobjetivo = [];
    this.rvssesobjetivo.forEach(element => {
        if(this.rvs[0].sesion == element.sesion) this.rvsobjetivo.push(element);
    });
}

selectedVidaSaludable:any = (e) => {
    this.rvs[0].objetivo = e.target.value;
    this.rvsvidasaludable.forEach(v => {
        if((v.partes == this.rvs[0].partes) && (v.contenido == this.rvs[0].contenido) 
            &&(v.sesion == this.rvs[0].sesion) && (v.objetivos == this.rvs[0].objetivo)){
            this.modEduplan.vida_saludable = v.id;
        }
    });
    this.activar=1;
}

  cargarcompapre(value){
    if(value==0) { //competencias
      this.rcompetenciasaux = [];
      this.rcompetencias.forEach(c  => {
        if(c.mod_edu_2011 == this.rmodselected[0].id) this.rcompetenciasaux.push(c);
      });
    }
    else if(value==1){ //aprendizajes
      this.raprendizajesespaux = [];
      this.raprendizajesesp.forEach(ae  => {
        if(ae.mod_edu_2011 == this.rmodselected[0].id) this.raprendizajesespaux.push(ae);
      });
    }
  }

  generatePdf(){
    Swal.showLoading();

    setTimeout(() => {
      if(this.nuevoplan.reforma == '2017')this.imprimirService.generarPdf(this.detalleplan, this.detallereformas); 
      if(this.nuevoplan.reforma == '2011')this.imprimirService.generarPdf(this.detalleplan, this.detallereformas); 
      if(this.nuevoplan.reforma == 'libre')this.imprimirService.generarPdf(this.detalleplan, ''); 
    }, 1000);

    setTimeout(() => {Swal.close();}, 6000);
  }

  addAprendizajes:any = (e, aprendizaje) =>{
    if(e.checked == true) this.aprenselected.push(aprendizaje);
    else {
      this.aprenselected.forEach((a,index) => {
          if(a.id == aprendizaje.id) this.aprenselected.splice(index, 1);
      });
    }
}
addRAprendizajes:any = (e, aprendizaje) =>{
  if(e.checked == true) this.raprenselected.push(aprendizaje);
  else {
    this.raprenselected.forEach((a,index) => {
        if(a.idapren == aprendizaje.idapren) this.raprenselected.splice(index, 1);
    });
  }
  }
  addCompt:any = (e, compt) =>{
      if(e.checked == true) this.rcompetselected.push(compt);
      else {
        this.rcompetselected.forEach((a,index) => {
            if(a.idcomp == compt.idcomp) this.rcompetselected.splice(index, 1);
        });
      }
  }

  agregarSesionnueva(){
    this.sesionnueva = this.sesionnueva + 1;
    this.numberSesionesN.push({id: this.sesionnueva, status: 1});
    //this.numberSesionesN  = Array(this.sesionnueva).fill(this.sesionnueva);
  }
  eliminarsesionNueva(numero){
    this.numberSesionesN.forEach(ns => {
      if(ns.id == numero) ns.status = 0;
    });
    if(this.arraySesiones.length>0){
      this.arraySesiones.forEach((element,k) => {
        if(element.numero == numero) this.arraySesiones.splice(k,1);
      });
    }
  }

  changeCct:any = (e) => {
    let cctselected = e.target.value;
    this.ccts.forEach(cct => {
        if(cctselected == cct.id) this.nuevoplan.institucion = cct.centro_educativo;
    });
  }

  revisarDes() {
    if (this.is_edit[0]) this.nuevoplan.cct = 0;
    if (this.is_edit[1]) this.nuevoplan.area = '/';
    if (this.is_edit[12]) this.nuevoplan.duracion = '/';
    if (this.is_edit[2]) this.nuevoplan.semana = '/';
    if (this.is_edit[7]) this.nuevoplan.aprendizajes_clave = '/';
    if (this.is_edit[8]) this.nuevoplan.propositos_pedagogicos = '/';
    if (this.is_edit[10]) this.nuevoplan.contexto = '/';
    if (this.is_edit[15]) this.nuevoplan.bloque = '/';
    if (this.is_edit[13]) this.nuevoplan.director = '/';
    if (this.is_edit[14]) this.nuevoplan.fecha = '/';
    if (this.is_edit[21]) this.nuevoplan.ciclo = '/';

    if (this.otro == 1) {
      if (this.is_edit[16]) this.modEduplan.formacion = '/';
      if (this.is_edit[4]) this.modEduplan.asignatura = '/';
      if (this.is_edit[5]) this.modEduplan.eje = '/';
      if (this.is_edit[6]) this.modEduplan.tema = '/';
      if (this.is_edit[9]) this.modEduplan.aprendizajes_esperados = '/';
    }
  }

  modalCompartir(){
    $(".modal-compartir").modal("show");
  }

}