import { Component, OnInit, Input } from '@angular/core';
import {DetalleService} from '../service/detalle.service';
import {ActivatedRoute,Router} from '@angular/router';
import { PlanesLT, UpdatePlanesLT} from '../models/planes';
import { Plan } from '../models/plan';
import Swal from 'sweetalert2';
import { Navegacion } from '../models/navigate';
import { UserService } from '../service/user.service';
declare var $: any;

@Component({
  selector: 'app-planpre-detalle',
  templateUrl: './planpre-detalle.component.html',
  styleUrls: ['./planpre-detalle.component.css']
})
export class PlanpreDetalleComponent implements OnInit {

  private token = localStorage.getItem('token');
  public planeslt = new PlanesLT();
  public updateLT = new UpdatePlanesLT();
  public selected = 1;
  public id  ;
  public slug;
  public info = {
    'name': '',
    'is_template': "1",
    'id': 0
  };
  public idRuta;
  public games;
  public grupos;
  public classrooms;
  public letra;
  public grupoplanes;
  public gruposModal;
  public parametros;
  public planEdit = new Plan();
  public navegacion = new Navegacion();

  public todosGrupos = false;

  private sections: any;

  constructor(private detalleService: DetalleService, private activatedRoute: ActivatedRoute,private router:Router, private userService: UserService) {
      this.navegacion.seccion = 31;
      this.detalleService.navegacion(this.token, this.navegacion).subscribe();
      this.activatedRoute.params.subscribe(params => {
        this.parametros = params;
        this.id = params.id;
        this.idRuta = params.id;
        this.slug=params.slug;
        localStorage.setItem('PlanId', this.id);
        localStorage.setItem('PlanSlug', this.slug);
        if(params.id == 0){
         this.id = 1;
        }
        this.detalleService.detallePlanes(this.token, this.id, params.slug).subscribe((response: any) => { 
          this.games = response.plan.games ;
          this.info = response.plan.info;
          this.planEdit.name = this.info.name;
          this.detalleService.navegacionModulo(this.token, {modulo_id: this.info.id, peticion: 0}).subscribe();
          if(this.info.is_template == undefined){
            this.info.is_template = "1"; 
          }
          let primer_valor=0
          for(let x of this.info.name){
            if (primer_valor==0){
              this.letra= x;
              primer_valor=1;
            }
          }
                });

          this.detalleService.grupos(this.token).subscribe((response: any) => {
            this.grupos = response.grupos;
            this.gruposModal = this.grupos.slice();

            this.gruposModal.forEach(element => {
              element.status = false;
            });

            for(let i in this.classrooms){
              for(let x in this.gruposModal){
                if (this.gruposModal[x].id == this.classrooms[i].id_classroom ){
                  this.gruposModal[x].status = true;
                  //this.gruposModal.splice(x,1);
                }
              }
            }
          });
        
          this.detalleService.planesInGroup(this.token,this.id).subscribe(
            (response:any)=>{
              this.grupoplanes=response.grupos;
            }
          );
        
    
    });
   }

  ngOnInit() {
    // Search
    setTimeout(function () {
      // Search
      $(".js-modal-search").click(function (e) {
        e.preventDefault();
        $(".modal-search").modal("show");
      });
      // Medium
      $(".js-modal-group-2").click(function (e) {
        e.preventDefault();
        $(".modal--medium2").modal("show");
      });
      //--- Carousel
      $(".modal .close").click(function () {
        $('.modal').fadeOut();
        $('.modal').removeClass('show');
      });

      
    }, 1500);
    this.sections = this.userService.getSecciones();
  }
  deletePlan(){
    Swal.fire({
      title: '¿Estas seguro de eliminar tu planeación?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText:'Cancelar'
  }).then((result) => {
      if (result.value) {
          this.detalleService.eliminarPlan(this.token, this.info.id).subscribe((response: any) => {
              Swal.fire({
                  type: 'success',
                  title: 'Módulo eliminado ',
                  showConfirmButton: false,
                  timer: 1500
              });
              this.router.navigate(["/mis-planes"]);
              //console.log("hecho");
          }, error => {
              Swal.fire({
                  type: 'error',
                  title: 'Error al eliminar el plan ',
                  showConfirmButton: false,
                  timer: 1500
              });
              // -----
              this.router.navigate(["/mis-planes"]);

          });
      }
  });
  }
  addPlan(){
    this.planeslt.name=this.info.name;
    this.planeslt.template_id=this.info.id;
    //console.log(this.planeslt);
    this.detalleService.addPlanesLT(this.token, this.planeslt ).subscribe((response:any)=>{
      //console.log(response)
      Swal.fire({
        type: 'success',
        title: 'Módulo agregado',
        showConfirmButton: false,
        timer: 2000
      });
      this.router.navigate(["/mis-planes"]);
      //console.log("hecho");
    }, error => {
      
      Swal.fire({
        type: 'error',
        title: '¡Algo falló!',
        html: '<h3>Revisa si ya tienes agregado este módulo.</h3>',
        showConfirmButton: false,
        timer: 3000
      });
      // -----
        this.updateLT.name = this.info.name;
        setTimeout(() => {
          this.router.navigate(["/mis-planes"]);
        }, 3000);
        //console.log(this.updateLT)
        // this.detalleService.actualizarPlanLTeliminado(this.token, this.updateLT).subscribe((response: any) => {
        //   this.router.navigate(["/mis-planes"]);
        // });
    });
    $(".modal .close").click(function () {
      $('.modal').fadeOut();
      $('.modal').removeClass('show');
    });
  }
  
  eliminarGrupo(id){
    //console.log(id);
    //console.log(this.id)
    this.detalleService.deleteGrupo(this.token,this.id,id).subscribe((response:any)=>{
      //console.log(response);
      this.detalleService.detallePlanes(this.token, this.id, this.slug).subscribe((response: any) => {
        this.classrooms = response.plan.contenidos.classroom;
        this.info = response.plan.info;
        let primer_valor = 0
        for (let x of this.info.name) {
          if (primer_valor == 0) {
            this.letra = x;
            primer_valor = 1;
          }
        }

        this.detalleService.grupos(this.token).subscribe((response: any) => {
          this.grupos = response.grupos;
          this.gruposModal = this.grupos.slice();

          this.gruposModal.forEach(element => {
            element.status = false;
          });

          for(let i in this.classrooms){
            for(let x in this.gruposModal){
              if (this.gruposModal[x].id == this.classrooms[i].id_classroom ){
                this.gruposModal[x].status = true;
                //this.gruposModal.splice(x,1);
              }
            }
          }
        });
      });
    });
    $(".modal .close").click(function () {
      $('.modal').fadeOut();
      $('.modal').removeClass('show');
    });

  }

  vincularGrupo(grupo) {

    //console.log(grupo);
    //console.log(this.id);
    //console.log(this.token);
    this.detalleService.vincularGrupo(this.token, this.id, grupo).subscribe((response: any) => {
      //console.log(response);
      this.detalleService.detallePlanes(this.token, this.id, this.slug).subscribe((response: any) => {
        this.classrooms = response.plan.contenidos.classroom;
        //console.log(this.classrooms)
        this.info = response.plan.info;

        this.detalleService.grupos(this.token).subscribe((response: any) => {
          this.grupos = response.grupos;
          this.gruposModal = this.grupos.slice();

          this.gruposModal.forEach(element => {
            element.status = false;
          });

          for(let i in this.classrooms){
            for(let x in this.gruposModal){
              if (this.gruposModal[x].id == this.classrooms[i].id_classroom ){
                this.gruposModal[x].status = true;
                //this.gruposModal.splice(x,1);
              }
            }
          }
        });

      });
      
      
    });
  }


  changeAll() {
    if(this.todosGrupos) this.todosGrupos = false;
    else{
      this.todosGrupos = true;
      this.gruposModal.forEach(element => {
        if(!element.status){ 
          element.status=true;
          this.vincularGrupo(element.id);
        }
      });
    }
    
  }

  changeStatus(index){

    for (let j = 0; j < this.classrooms.length; j++) {
      if(this.gruposModal[index].id == this.classrooms[j].id_classroom && this.gruposModal[index].status){
        this.eliminarGrupo(this.gruposModal[index].id);
        this.gruposModal[index].status = false;
      }
      else if(!this.gruposModal[index].status && this.gruposModal[index].id != this.classrooms[j].id_classroom){
        this.vincularGrupo(this.gruposModal[index].id);
        this.gruposModal[index].status = true;
      }
    }

  }

  onSubmit(form:any){
    //console.log(this.planEdit)
    this.detalleService.editarNamePlan(this.token,this.planEdit,this.id).subscribe((response:any)=>{
      //console.log(response)
      this.detalleService.detallePlanes(this.token, this.id, this.slug).subscribe((response: any) => {
        this.info = response.plan.info;
        let primer_valor = 0
        for (let x of this.info.name) {
          if (primer_valor == 0) {
            this.letra = x;
            primer_valor = 1;
          }
        }
      });
    });
  }

  back(){
    this.selected = 1;
  }

  actDescarga(){
    this.detalleService.navegacionModulo(this.token, {modulo_id: this.info.id, peticion: 1}).subscribe();
  }


  ngAfterViewInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

}
