import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Navegacion } from '../models/navigate';
import { CapacitacionesService } from '../service/capacitaciones.service';
import { materiales } from '../service/config';
import { DetalleService } from '../service/detalle.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-mooc',
  templateUrl: './mooc.component.html',
  styleUrls: ['./mooc.component.css']
})
export class MoocComponent implements OnInit {

  private token: string = localStorage.getItem('token');
  public navegacion = new Navegacion();
  private idMooc: any;
  private mooc: any;
  private origen: any;
  private dateAvaible: any = {};
  private peticion = {
    id_mooc:null,
    buscador:null,
    peticion:null,
    id: null
  }
  private id_navegacion: any;
  private urlMat = materiales.url;

  private sections: any;
  Arr = Array;
  private micalf=0;
  public porcentaje = 30;
  public modulomostrar = [];

  constructor(private route: ActivatedRoute, private userService: UserService, private router: Router,
            private detalleService: DetalleService, private capacitacionService: CapacitacionesService) { }

  ngOnInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

     this.sections = this.userService.getSecciones();
     this.idMooc = this.route.snapshot.paramMap.get('id');
     this.origen = this.route.snapshot.paramMap.get('from');

    this.capacitacionService.getMoocInsc(this.idMooc,this.token).subscribe((response: any) => {
        this.mooc = response.mooc;
        console.log(response);

        if(this.sections.moocs.includes(''+this.mooc.id_seccion)){
          // this.navegacion.seccion = 29;
          // this.detalleService.navegacion(this.token, this.navegacion).subscribe();

          this.setDateAvability(this.mooc.fecha);
          //if (this.peticion.id==null) this.registroVisited();
          //else this.registroSalida();

          if(this.mooc.modulos.length>0){
            for(let [i,modulo] of this.mooc.modulos.entries()) {
              if(i==0 && !modulo.completo){
                this.modulomostrar = modulo;
                break;
              }
              else if(!modulo.completo) {
                this.modulomostrar = modulo;
                break;
              }
            }
          }
        console.log(this.modulomostrar)
        }
        else{
          this.router.navigate(['capacitacion-docente/mooc/'+this.idMooc]);
        }
    });
  }

  setDateAvability(dateMooc:any){
    let today:any = new Date();
    let dateMo: any = new Date(dateMooc);
    var diff:any  = (dateMo - today)/10000;
    if(diff < 0){
      this.dateAvaible = false;
    }else{
      this.dateAvaible = true;
    }
  }

  back(){
    if(this.origen == 0){
      this.router.navigate(['capacitacion-docente/mooc/'+this.idMooc]);
    }
    if(this.origen == 1){
      this.router.navigate(['mi-capacitacion']);
    }
  }

  calificar(c){
    this.micalf = c;
    let estrellas = '';
    this.Arr(c).fill(1).forEach(e => { (estrellas=='') ? estrellas = '★' : estrellas = estrellas + '★';});
    console.log(estrellas)
    let calf = {
      id_mooc: this.idMooc,
      calificacion : this.micalf,
      comentario: ''
    }
    Swal.fire({
      input: 'textarea',
      titleText: 'Tu calificación: '+estrellas,
      text: 'Escribe un comentario acerca del mooc',
      inputPlaceholder: 'Escribe un comentario acerca del mooc:',
      confirmButtonText: 'Enviar',
      showCancelButton: true
    }).then((text) => {
      if (text.value) {
        calf.comentario = text.value;
        console.log(calf);
        this.capacitacionService.sendCalificacion(calf,this.token).subscribe((response: any) => {
          Swal.fire(" ", response.message, "success");
          this.mooc.micalificacion = this.micalf;
        }, error => {
            Swal.fire(" ", error.error.message, "warning");
        });

      } 
    });
  }

  otherModule(index){
    this.modulomostrar = this.mooc.modulos[index];
  }

  downloadMaterial(material, id_mod){
    const mat = {
      id_mooc: this.mooc.id,
      id_material: material.id,
      id_modulo: id_mod,
      tiempo: material.duracion
    }
    console.log(material)
    console.log(mat)
    this.capacitacionService.registrarMaterial(mat,this.token).subscribe((response: any) => {
        console.log(response.message)
    });
  }
  doEval(id_mod, tipo){
    const evalu = {
      id_mooc: this.mooc.id,
      id_modulo: id_mod,
      tipo: tipo
    }
    console.log(eval)
    this.capacitacionService.registrarEvaluacion(evalu,this.token).subscribe((response: any) => {
        console.log(response.message)
        Swal.fire(" ", response.message, "success");
    }, error => {
          Swal.fire("Error", error.error.message, "warning");
    });
  }

}
