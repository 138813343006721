import { Component, OnInit } from '@angular/core';
import { Game, GameAbility, GamePrograma, GameRecurso, GameRuta, GameAsignatura, GameConceptos, GameEtapas } from '../models/game'
import { DetalleService } from '../service/detalle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService } from '../service/game.service';
import InfoGames from 'src/assets/JSON/infoGames.json';
import { Navegacion } from '../models/navigate';
import { Games } from '../models/navigation';
import {Location} from '@angular/common';

declare var $: any;
declare var dragula: any;

@Component({
  selector: 'app-ver-juego',
  templateUrl: './ver-juego.component.html',
  styleUrls: ['./ver-juego.component.css']
})
export class VerJuegoComponent implements OnInit {

  public game = new Game();
  public gameAbility = new GameAbility();
  public gamePrograma = new GamePrograma();
  public gameRecurso = new GameRecurso();
  public gameRuta = new GameRuta();
  public gameAsignatura = new GameAsignatura();
  public gameConceptos = new GameConceptos();
  public gameEtapas = new GameEtapas();
  public idDescripcion;
  public idHabilidad;
  public idOtrosRecursos;
  public idProgramas;


  public gamesHTML2: any[] = [];
  public gamesHTML2Aux: any[] = [];
  private juego: any;


  public isFull = false;
  public numRutas = 0;
  private token: string = localStorage.getItem('token');
  public rutasTotales: any[];

  public nombrePlan: string;
  public descripcion: string;
  private id_plan: string;

  public dataGame;
  public gameSelected;
  public navegacion = new Navegacion();
  public navigateGame = new Games();

  constructor(private gameService: DetalleService, private activatedRoute: ActivatedRoute,
              private router: Router, private gameServices1: GameService, private _location: Location) {
    this.navegacion.seccion = 9;
    this.gameService.navegacion(this.token, this.navegacion).subscribe();


    this.activatedRoute.params.subscribe(params => {
      this.gameService.verDetalle(this.token, params.slug).subscribe((response: any) => {
        this.game = response.game;
        this.navigateGame.place = 4;
        this.navigateGame.game = this.game.id;
        this.gameService.navegacionJuego(this.token, this.navigateGame).subscribe();
        this.gameAsignatura = response.asignatura;
        this.dataGame = InfoGames.info;
        console.log(this.gameSelected);
        this.dataGame.forEach(element => {
          if (element.name == this.game.slug) {
            this.gameSelected = element;
          }
        });
        console.log(this.gameSelected);
        this.gameConceptos = response.conceptos;
        this.idDescripcion = response.descripcion.id;
        if (response.habilidades != null) {
          this.gameAbility = response.habilidades;
          const aprendizaje = JSON.parse(response.habilidades.aprendizaje);
          const modelodb = JSON.parse(response.habilidades.me);
          const imt = JSON.parse(response.habilidades.imt);
          const fms = JSON.parse(response.habilidades.fms);
          const ed = JSON.parse(response.habilidades.ed);
          this.gameAbility.modelos = {
            autoconocimiento: {
              conciencia: modelodb.autoconocimiento.conciencia,
              autoestima: modelodb.autoconocimiento.autoestima,
              aprecio: modelodb.autoconocimiento.aprecio,
              bienestar: modelodb.autoconocimiento.bienestar
            },
            autorregulacion: {
              meta: modelodb.autorregulacion.meta,
              expresion: modelodb.autorregulacion.expresion,
              regulacion: modelodb.autorregulacion.regulacion,
              generacion: modelodb.autorregulacion.generacion,
              perseverancia: modelodb.autorregulacion.perseverancia
            },
            autonomia: {
              iniciativa: modelodb.autonomia.iniciativa,
              soluciones: modelodb.autonomia.soluciones,
              eficacia: modelodb.autonomia.eficacia
            },
            empatia: {
              bienestar: modelodb.empatia.bienestar,
              perspectiva: modelodb.empatia.perspectiva,
              prejuicios: modelodb.empatia.prejuicios,
              sensibilidad: modelodb.empatia.sensibilidad,
              cuidado: modelodb.empatia.cuidado
            },
            colaboracion: {
              comunidad: modelodb.colaboracion.comunidad,
              responsabilidad: modelodb.colaboracion.responsabilidad,
              inclusion: modelodb.colaboracion.inclusion,
              resolucion: modelodb.colaboracion.resolucion,
              interdependencia: modelodb.colaboracion.interdependencia
            },
            ramas: {
              colaboracion: modelodb.ramas.colaboracion,
              empatia: modelodb.ramas.empatia,
              autonomia: modelodb.ramas.autonomia,
              autorregulacion: modelodb.ramas.autorregulacion,
              autoconocimiento: modelodb.ramas.autoconocimiento
            },
            modelo: modelodb.modelo
          };
          this.gameAbility.habilidades = {
            aprendizaje: {
              pensamiento: aprendizaje.pensamiento,
              comunicacion: aprendizaje.comunicacion,
              creatividad: aprendizaje.creatividad,
              colaboracion: aprendizaje.colaboracion
            },
            imt: {
              teclado: {
                nombre: imt.teclado.nombre,
                porcentaje: imt.teclado.porcentaje = imt.teclado.porcentaje
              },
              mouse: {
                nombre: imt.mouse.nombre,
                porcentaje: imt.mouse.porcentaje = imt.mouse.porcentaje
              },
              tableta: {
                nombre: imt.tableta.nombre,
                porcentaje: imt.tableta.porcentaje = imt.tableta.porcentaje
              }
            },
            fms: {
              atencion: fms.atencion,
              memoria: fms.memoria,
              lenguaje: fms.lenguaje,
              visoespacial: fms.visoespacial,
              sensoriomotoras: fms.sensoriomotoras,
              ejecutivas: fms.ejecutivas
            },
            ed: {
              visual: ed.visual,
              trabajo: ed.trabajo,
              informacion: ed.informacion,
              verbal: ed.verbal,
              auditiva: ed.auditiva
            },
            tags: response.habilidades.tags
          };
        }
        if (response.otros != null) {
          this.gameRecurso = response.otros;
        }
        if (response.programas != null) {
          this.gamePrograma = response.programas;
        }
        this.gameEtapas = response.etapas;


        this.rutasTotales = response.rutas;
        console.log(response);
        this.idHabilidad = response.habilidades.id;
        this.idOtrosRecursos = response.otros.id;
        this.idProgramas = response.programas.id;
        this.ngOnInit();
      }, error => {
        console.log(error);
      });
    });
  }

  ngOnInit() {

  }

  agregarADrop(event) {
    var cuadro = event["path"][2];
    document.getElementById('js-drop-documents').append(cuadro);
    var prueba = document.getElementById("js-drop-documents").getElementsByClassName("square__add");
    [].forEach.call(prueba, function (a) {
      a.classList.add("noMas");
    });
  }

  agregarDesc(event) {
    this.agregarADrop(event);
    localStorage.setItem("descripcion_id", this.idDescripcion.toString());
  }

  agregarComp(event) {
    this.agregarADrop(event);
    localStorage.setItem("Habilidad", this.idHabilidad.toString());
  }

  agregarRuta(event) {
    let auxarray: any[] = [];
    this.agregarADrop(event);
    for (let x in this.rutasTotales) {
      console.log(x)
      auxarray.push(this.rutasTotales[x].id);
    }
    console.log(auxarray);
    localStorage.setItem("Ruta", auxarray.toString());
  }

  agregarProgr(event) {
    this.agregarADrop(event);
    localStorage.setItem("programa", this.idProgramas.toString());
  }

  agregarOtros(event) {
    this.agregarADrop(event);
    localStorage.setItem("Recursos", this.idOtrosRecursos.toString());
  }

  agregarPorMas(event) {

    document.getElementById('js-drop-games').append(event["path"][2]);

    this.gamesHTML2Aux.push(event["path"][2]);

    this.gamesHTML2Aux.forEach(element => {
      this.gamesHTML2.push(element.outerHTML);
    });

    localStorage.setItem("gamesHTML2", JSON.stringify(this.gamesHTML2));

    var URLactual = window.location;

    var Aux = URLactual.toString();

    var gamesA2 = Aux.replace(Aux.substring(28, 0), "");

    localStorage.setItem("gamesA2", gamesA2);

  }
  back(){
    this._location.back();
  }
  kill(element, id) {
    $(element).modal('hide');
    let iframe = document.getElementById(id) as HTMLIFrameElement;
    let src = $(iframe).attr('src');  
    console.log(src);    
    $(iframe).attr('src', '').attr('src', src);
  }
  ngAfterViewInit() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

}
