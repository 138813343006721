import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { DetalleService } from '../service/detalle.service';
import { UserService } from '../service/user.service';
import { imagenesBlog } from '../service/config'; 
import { videosCapacitaciones } from '../service/config'; 
import Swal from 'sweetalert2';
import { CapacitacionesService } from '../service/capacitaciones.service';
import { Navegacion } from '../models/navigate';
declare var $: any;

@Component({
  selector: 'app-capacitacion-webinar-live',
  templateUrl: './capacitacion-webinar-live.component.html',
  styleUrls: ['./capacitacion-webinar-live.component.css']
})

export class CapacitacionWebinarLiveComponent implements OnInit, OnDestroy {

  private token: string = localStorage.getItem('token');
  public navegacion = new Navegacion();
  private IDwebinar: any;
  private webinar: any;
  private otros: any = [];
  private origen: any;
  private dateAvaible: any = {};
  private comments: any;
  private comment = {
    id_respuesta: null,
    id_webinar: 0,
    comentario: ''
  }
  private peticion = {
    id_webinar:null,
    buscador:null,
    peticion:null,
    id: null,
    video: null
  }
  private id_navegacion: any;
  private urlVIDEO = videosCapacitaciones.url;
  private urlIMG = imagenesBlog.url;

  private sections: any;

  constructor(private route: ActivatedRoute, private userService: UserService, private router: Router, private location: Location,
    private detalleService: DetalleService, private capacitacionService: CapacitacionesService,  @Inject(DOCUMENT) document) {

    localStorage.removeItem('ak');
    localStorage.removeItem('mn');
    localStorage.removeItem('ps');

    let iframev = document.getElementById('zoom-boost') as HTMLIFrameElement;
    $(iframev).attr('href', '');
    const zoomMeeting = document.getElementById('zmmtg-root').style.display = 'none';

    this.capacitacionService.getOtros(this.token).subscribe((response: any) => {
      this.otros = response;
    });
  }

  async postComentario(idRespuesta:any){
    this.comment.id_respuesta = idRespuesta;
    this.comment.id_webinar = this.IDwebinar; 
    if(this.comment.id_respuesta == null){ 
      Swal.showLoading();
      this.capacitacionService.postComment(this.comment,this.token).subscribe((response: any) => {
        this.refreshComments();
        Swal.close();
      });
    }
    else if(this.comment.id_respuesta != null){
      await Swal.fire({
        input: 'textarea',
        inputPlaceholder: 'Escriba aqui su respuesta',
        showCancelButton: true
      }).then((text) => {
        if (text.value) {
          this.comment.comentario = text.value;
        } else{
          this.comment.comentario = "  ";
        }
      })
      if(this.comment.comentario != "  "){
        this.capacitacionService.postComment(this.comment,this.token).subscribe((response: any) => {
          this.refreshComments();
          Swal.fire("","Comentario publicado","success");
        });
      }
    }
  }
  
  back(){
    if(this.origen == 0){
      this.router.navigate(['capacitacion-docente/webinar/'+this.IDwebinar]);
    }
    if(this.origen == 1){
      this.router.navigate(['mi-capacitacion']);
    }
  }

  setDateAvability(dateWebinar:any, duracion:Number){
    this.dateAvaible = {
      soon: false, //la fecha es proxima
      live: false, //una hora antes y 45min despues de la fecha seleccionada + duracion
      past: false, //mas de 45min despues de la fecha seleccionada + duracion
    }
    let today:any = new Date();
    let dateWeb: any = new Date(dateWebinar);
    var diff:any  = (dateWeb - today)/1000;

    if(diff < (-60*(Number(duracion)+45))){
      this.dateAvaible.past = true;
      this.dateAvaible.live = false;
      this.dateAvaible.soon = false;
    }
    if(diff >= (-60*(Number(duracion)+45)) && diff <= 3600){
      this.dateAvaible.past = false;
      this.dateAvaible.live = true;
      this.dateAvaible.soon = false;
    }
    if(diff > 3600){
      this.dateAvaible.past = false;
      this.dateAvaible.live = false;
      this.dateAvaible.soon = true;
    }
  }

  refreshPeticion(){
    this.peticion.id_webinar = null;
    this.peticion.buscador=null;
    this.peticion.peticion=null;
    /*this.peticion.id= null; //conservar */
    this.peticion.video = null
  }

  refreshComments(){
    this.capacitacionService.getComment(this.IDwebinar, this.token).subscribe((response: any) => {
      this.comments = response;
      console.log(this.comments);
    });
    this.comment = {
      id_respuesta: null,
      id_webinar: 0,
      comentario: ''
    }
  }

  registroSalida(){
    //Registro de salida del Webinar//  
    this.peticion.peticion = 3;
    this.capacitacionService.postVisitedWebinar(this.peticion,this.token).subscribe((response: any) => {
      this.refreshPeticion();
      console.log("response",response);
    });
  }

  registroClickVideo(from){
    if (from == 1) {
      document.getElementById('webinar-video').style.display = 'block';
      document.getElementById('webinar-video').setAttribute('autoplay', '');
      document.getElementById('video-boton').style.display = 'none';
    }
    //Registro de click en el video//    
    this.peticion.video = 1;
    this.peticion.peticion = 2;
    this.capacitacionService.postVisitedWebinar(this.peticion,this.token).subscribe((response: any) => {
      this.refreshPeticion();
      console.log("response",response);
    });
  }

  registroVisited(){
    //Registro de visita//    
    this.peticion.id_webinar = this.IDwebinar
    this.peticion.buscador = Number((this.origen==0));
    this.peticion.peticion = 1;
    this.capacitacionService.postVisitedWebinar(this.peticion,this.token).subscribe((response: any) => {
      this.peticion.id = response.id;
      this.refreshPeticion();
      console.log("id_navegacion",response); 
    });
  }

  joinMeeting(){
    this.registroClickVideo(0);
    let iframe = document.getElementById('zoom-boost') as HTMLIFrameElement;
    $(iframe).attr('href', 'https://source.zoom.us/1.9.7/css/bootstrap.css');
    let path = (this.location.path().split('/').length == 6) ? this.location.path() 
      : this.location.path() + '/' + this.peticion.id;

    let meeting = {
      //leaveUrl: 'https://labtak.takflics.com' + path,
      leaveUrl: 'http://localhost:4200' + path,
      //leaveUrl: 'https://labtak.fundacioninoma.com' + path,
      name: '',
      email: ''
    }
    this.detalleService.detallePerfil(this.token).subscribe((response: any) => {
      meeting.name = response.user_data.name + ' ' + response.user_data.lastname;
      meeting.email = response.user_data.email;
      this.router.navigate(['zoom'], { queryParams: { checkout: JSON.stringify(meeting) } });
    });
  }


  ngOnInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    this.sections = this.userService.getSecciones();
    this.IDwebinar = this.route.snapshot.paramMap.get('id');
    this.origen = this.route.snapshot.paramMap.get('from');
    this.peticion.id = this.route.snapshot.paramMap.get('id_nav');

    this.capacitacionService.getWebinarInfo(this.IDwebinar,this.token).subscribe((response: any) => {
        this.webinar = response.webinar;
        console.log(this.webinar);

        if(this.sections.webinars.includes(''+this.webinar.id_seccion)){
          this.navegacion.seccion = 29;
          this.detalleService.navegacion(this.token, this.navegacion).subscribe();
          
          localStorage.setItem('ak', 'BAld7JHIQUyzD8x2UsGQdQ');
          localStorage.setItem('mn', this.webinar.codigo);
          localStorage.setItem('ps', this.webinar.password);

          this.setDateAvability(this.webinar.fecha, this.webinar.duracion.split(' ')[0]);
          if (this.peticion.id==null) this.registroVisited();
          else this.registroSalida();
          
          this.refreshComments();
        }
        else{
          this.router.navigate(['capacitacion-docente/webinar/'+this.IDwebinar]);
        }
    });

  }

  ngOnDestroy() {
    console.log('destroy')
    this.registroSalida();
  }
}
