import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { GameService } from '../service/game.service';
import { imagenes } from '../service/config';
import { DetalleService } from '../service/detalle.service';
import { Navegacion } from '../models/navigate'; 
import { Game, GameAbility, GamePrograma, GameRecurso, GameRuta, GameAsignatura, GameConceptos, GameEtapas } from '../models/game';
import InfoGames from 'src/assets/JSON/infoGames.json';
import { Games } from '../models/navigation';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';

declare var $: any;
declare var dragula: any;
declare var particlesJS: any;

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnInit {

  
  private token: string = localStorage.getItem('token');
  private email: string = localStorage.getItem('email');
  private urlImg: string = imagenes.url;

  public selected = 1;

  public letter = 'todos';
  active = [];

  public Agames: any[] = [];
  public Bgames: any[] = [];
  public Cgames: any[] = [];
  public Dgames: any[] = [];
  public Egames: any[] = [];
  public Fgames: any[] = [];
  public Ggames: any[] = [];
  public Hgames: any[] = [];
  public Igames: any[] = [];
  public Jgames: any[] = [];
  public Kgames: any[] = [];
  public Lgames: any[] = [];
  public Mgames: any[] = [];
  public Ngames: any[] = [];
  public Ogames: any[] = [];
  public Pgames: any[] = [];
  public Qgames: any[] = [];
  public Rgames: any[] = [];
  public Sgames: any[] = [];
  public Tgames: any[] = [];
  public Ugames: any[] = [];
  public Vgames: any[] = [];
  public Wgames: any[] = [];
  public Xgames: any[] = [];
  public Ygames: any[] = [];
  public Zgames: any[] = [];
  public simbolosGames: any[] = [];
  public asignaturas;
  public allGames;
  public filtrados: any[] = [];

  public navegacion = new Navegacion();


  public game = new Game();
  public gameAbility = new GameAbility();
  public gamePrograma = new GamePrograma();
  public gameRecurso = new GameRecurso();
  public gameRuta = new GameRuta();
  public gameAsignatura = new GameAsignatura();
  public gameConceptos = new GameConceptos();
  public gameEtapas = new GameEtapas();
  public idDescripcion;
  public idHabilidad;
  public idOtrosRecursos;
  public idProgramas;

  public gamesHTML2: any[] = [];
  public gamesHTML2Aux: any[] = [];
  public juego: any;


  public isFull = false;
  public numRutas = 0;
  public rutasTotales: any[];

  public nombrePlan: string;
  public descripcion: string;

  public dataGame;
  public gameSelected;
  public navigateGame = new Games();

  public idgame;

  private sections: any;

  constructor(private gameService: GameService, private router: Router, private detalleService: DetalleService, private userService: UserService) {
    this.navegacion.seccion = 2;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
  }
  

  kill(element, id) {
    $(element).modal('hide');
    let iframe = document.getElementById(id) as HTMLIFrameElement;
    let src = $(iframe).attr('src');  
    $(iframe).attr('src', '').attr('src', src);
  }

  addtoplan(){
    $(".modal-addplan").modal("show");
  }

  ngOnInit() {
    this.letter='todos';
    for (let index = 0; index < 20; index++) {
      this.active[index] = " ";
    }
    this.active[0]='active';
    this.gameService.verJuegos(this.token).subscribe(
      data => {
        // console.log(data["games"]);
        // data["games"].forEach(element => {
        //   console.log(element.tags.split(","));
        // });
        this.allGames = data["games"];
        this.listaJuegos(this.allGames);   
      }
    ); 
    setTimeout(function () {
      // Second level
      $(".js-second-level").on('click', function (e) {
        e.preventDefault();
        $(this).next("ol").toggleClass("active");
        $(this).toggleClass("active");
      });
      //$(".modal-addplan").modal("show");
      $(".js-modal-addplan").click(function (e) {
        e.preventDefault();
        $(".modal-addplan").modal("show");
      });
      //--- Open
      $("#open").click(function (e) {
        e.preventDefault();
        $(this).toggleClass("active");
        $(".menu").toggleClass("active");
      });
      
    }, 1800);
    this.sections = this.userService.getSecciones();
  }

  listaJuegos(lista){
    this.Agames = [];
    this.Bgames = [];
    this.Cgames = [];
    this.Dgames = [];
    this.Egames = [];
    this.Fgames = [];
    this.Ggames = [];
    this.Hgames = [];
    this.Igames = [];
    this.Jgames = [];
    this.Kgames = [];
    this.Lgames = [];
    this.Mgames = [];
    this.Ngames = [];
    // this.Ñgames = [];
    this.Ogames = [];
    this.Pgames = [];
    this.Qgames = [];
    this.Rgames = [];
    this.Sgames = [];
    this.Tgames = [];
    this.Ugames = [];
    this.Vgames = [];
    this.Wgames = [];
    this.Xgames = [];
    this.Ygames = [];
    this.Zgames = [];
    this.simbolosGames = [];

    lista.forEach(game => {
      if (game.title.indexOf('¡') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Agames.push(game);
      }
      if (game.title.indexOf('¿') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Dgames.push(game);
      }
      if (game.title.indexOf('A') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Agames.push(game);
      }
      if (game.title.indexOf('Á') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Agames.push(game);
      }
      if (game.title.indexOf('B') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Bgames.push(game);
      }
      if (game.title.indexOf('C') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Cgames.push(game);
      }
      if (game.title.indexOf('D') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Dgames.push(game);
      }
      if (game.title.indexOf('E') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Egames.push(game);
      }
      if (game.title.indexOf('F') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Fgames.push(game);
      }
      if (game.title.indexOf('G') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Ggames.push(game);
      }
      if (game.title.indexOf('H') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Hgames.push(game);
      }
      if (game.title.indexOf('I') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Igames.push(game);
      }
      if (game.title.indexOf('J') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Jgames.push(game);
      }
      if (game.title.indexOf('K') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Kgames.push(game);
      }
      if (game.title.indexOf('L') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Lgames.push(game);
      }
      if (game.title.indexOf('M') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Mgames.push(game);
      }
      if (game.title.indexOf('N') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Ngames.push(game);
      }
      if (game.title.indexOf('O') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Ogames.push(game);
      }
      if (game.title.indexOf('P') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Pgames.push(game);
      }
      if (game.title.indexOf('Q') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Qgames.push(game);
      }
      if (game.title.indexOf('R') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Rgames.push(game);
      }
      if (game.title.indexOf('S') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Sgames.push(game);
      }
      if (game.title.indexOf('T') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Tgames.push(game);
      }
      if (game.title.indexOf('U') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Ugames.push(game);
      }
      if (game.title.indexOf('V') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Vgames.push(game);
      }
      if (game.title.indexOf('W') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Wgames.push(game);
      }
      if (game.title.indexOf('X') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Xgames.push(game);
      }
      if (game.title.indexOf('Y') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Ygames.push(game);
      }
      if (game.title.indexOf('Z') == 0) {
        game.imagename = this.urlImg + game.imagename;
        this.Zgames.push(game);
      }
    });  
  }

  filtro(asignatura, i){
    this.letter='todos';
    this.activeButton(i);
    this.filtrados = [];
    this.allGames.forEach(game => {
      if(game.asignatura != null){
        if(game.asignatura.indexOf(asignatura) != -1){
          game.imagename = game.imagename.substring(48);// estos 48 caracteres son del path
          this.filtrados.push(game);
        }
      }
    });
    this.listaJuegos(this.filtrados);
  }
  filtro_nivel(nivel, i) {
    this.letter='todos';
    this.activeButton(i);
    this.filtrados = [];
    this.allGames.forEach(game => {
      if (game.nivel != null) {
        if (game.nivel.indexOf(nivel) != -1) {
          game.imagename = game.imagename.substring(48);// estos 48 caracteres son del path
          this.filtrados.push(game);
        }
      }
    });
    this.listaJuegos(this.filtrados);
  }

  detailGame(slug){

    this.ngAfterViewInit()

    this.selected = 2;

    
    this.navegacion.seccion = 8;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();

      this.detalleService.verDetalle(this.token, slug).subscribe((response: any) => {
        this.game = response.game;
        this.navigateGame.place = 1;
        this.navigateGame.game = this.game.id;
        this.idgame = this.game.id;
        this.detalleService.navegacionJuego(this.token, this.navigateGame).subscribe();
        this.gameAsignatura = response.asignatura;
        this.dataGame = InfoGames.info;
        this.dataGame.forEach(element => {
          if (element.name == this.game.slug) {
            this.gameSelected = element;
          }
        });
        this.gameConceptos = response.conceptos;
        this.idDescripcion = response.descripcion.id;
        if (response.habilidades != null) {
          this.gameAbility = response.habilidades;
          const aprendizaje = JSON.parse(response.habilidades.aprendizaje);
          const modelodb = JSON.parse(response.habilidades.me);
          const imt = JSON.parse(response.habilidades.imt);
          const fms = JSON.parse(response.habilidades.fms);
          const ed = JSON.parse(response.habilidades.ed);
          this.gameAbility.modelos = {
            autoconocimiento: {
              conciencia: modelodb.autoconocimiento.conciencia,
              autoestima: modelodb.autoconocimiento.autoestima,
              aprecio: modelodb.autoconocimiento.aprecio,
              bienestar: modelodb.autoconocimiento.bienestar
            },
            autorregulacion: {
              meta: modelodb.autorregulacion.meta,
              expresion: modelodb.autorregulacion.expresion,
              regulacion: modelodb.autorregulacion.regulacion,
              generacion: modelodb.autorregulacion.generacion,
              perseverancia: modelodb.autorregulacion.perseverancia
            },
            autonomia: {
              iniciativa: modelodb.autonomia.iniciativa,
              soluciones: modelodb.autonomia.soluciones,
              eficacia: modelodb.autonomia.eficacia
            },
            empatia: {
              bienestar: modelodb.empatia.bienestar,
              perspectiva: modelodb.empatia.perspectiva,
              prejuicios: modelodb.empatia.prejuicios,
              sensibilidad: modelodb.empatia.sensibilidad,
              cuidado: modelodb.empatia.cuidado
            },
            colaboracion: {
              comunidad: modelodb.colaboracion.comunidad,
              responsabilidad: modelodb.colaboracion.responsabilidad,
              inclusion: modelodb.colaboracion.inclusion,
              resolucion: modelodb.colaboracion.resolucion,
              interdependencia: modelodb.colaboracion.interdependencia
            },
            ramas: {
              colaboracion: modelodb.ramas.colaboracion,
              empatia: modelodb.ramas.empatia,
              autonomia: modelodb.ramas.autonomia,
              autorregulacion: modelodb.ramas.autorregulacion,
              autoconocimiento: modelodb.ramas.autoconocimiento
            },
            modelo: modelodb.modelo
          };
          this.gameAbility.habilidades = {
            aprendizaje: {
              pensamiento: aprendizaje.pensamiento,
              comunicacion: aprendizaje.comunicacion,
              creatividad: aprendizaje.creatividad,
              colaboracion: aprendizaje.colaboracion
            },
            imt: {
              teclado: {
                nombre: imt.teclado.nombre,
                porcentaje: imt.teclado.porcentaje = imt.teclado.porcentaje
              },
              mouse: {
                nombre: imt.mouse.nombre,
                porcentaje: imt.mouse.porcentaje = imt.mouse.porcentaje
              },
              tableta: {
                nombre: imt.tableta.nombre,
                porcentaje: imt.tableta.porcentaje = imt.tableta.porcentaje
              }
            },
            fms: {
              atencion: fms.atencion,
              memoria: fms.memoria,
              lenguaje: fms.lenguaje,
              visoespacial: fms.visoespacial,
              sensoriomotoras: fms.sensoriomotoras,
              ejecutivas: fms.ejecutivas
            },
            ed: {
              visual: ed.visual,
              trabajo: ed.trabajo,
              informacion: ed.informacion,
              verbal: ed.verbal,
              auditiva: ed.auditiva
            },
            tags: response.habilidades.tags
          };
        }
        if (response.otros != null) {
          this.gameRecurso = response.otros;
          this.transformarLink();
        }
        if (response.programas != null) {
          this.gamePrograma = response.programas;
        }
        this.gameEtapas = response.etapas;


        this.rutasTotales = response.rutas;
        this.idHabilidad = response.habilidades.id;
        this.idOtrosRecursos = response.otros.id;
        this.idProgramas = response.programas.id;
      }, error => {
        console.log(error);
      });
  }

  back(){
    this.letter='todos';
    this.selected=1;
    this.game = new Game();
    this.game = new Game();
    this.gameAbility = new GameAbility();
    this.gamePrograma = new GamePrograma();
    this.gameRecurso = new GameRecurso();
    this.gameRuta = new GameRuta();
    this.gameAsignatura = new GameAsignatura();
    this.gameConceptos = new GameConceptos();
    this.gameEtapas = new GameEtapas();
    this.navegacion.seccion = 2;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
  }

  exit(){
    localStorage.removeItem('token');
    this.router.navigate(['/login/FIFYA']);  
    this.gameService.salir(this.token, this.email).subscribe(      
      data => {
        console.log(data);
      }
    );
  }

  ngAfterViewInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    
    particlesJS(
      // Footer
      "js-particles--footer", {
          "particles": {
              "number": {
                  "value":80, "density": {
                      "enable": true, "value_area": 800
                  }
              }
              , "color": {
                  "value": "#c0890f"
              }
              , "shape": {
                  "type":"circle", "stroke": {
                      "width": 0, "color": "#000000"
                  }
                  , "polygon": {
                      "nb_sides": 5
                  }
                  , "image": {
                      "src": "img/github.svg", "width": 100, "height": 100
                  }
              }
              , "opacity": {
                  "value":0.5, "random":false, "anim": {
                      "enable": false, "speed": 1, "opacity_min": 0.1, "sync": false
                  }
              }
              , "size": {
                  "value":11.83721462448409, "random":true, "anim": {
                      "enable": false, "speed": 40, "size_min": 0.1, "sync": false
                  }
              }
              , "line_linked": {
                  "enable": true, "distance": 160.3412060865523, "color": "#c0890f", "opacity": 0.4, "width": 3.630079151508454
              }
              , "move": {
                  "enable":true, "speed":6, "direction":"none", "random":false, "straight":false, "out_mode":"out", "bounce":false, "attract": {
                      "enable": false, "rotateX": 600, "rotateY": 1200
                  }
              }
          },
          "interactivity": {
              "detect_on":"canvas", "events": {
                  "onhover": {
                      "enable": true, "mode": "repulse"
                  }
                  , "onclick": {
                      "enable": true, "mode": "push"
                  }
                  , "resize":true
              }
              , "modes": {
                  "grab": {
                      "distance":400, "line_linked": {
                          "opacity": 1
                      }
                  }
                  , "bubble": {
                      "distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3
                  }
                  , "repulse": {
                      "distance": 200, "duration": 0.4
                  }
                  , "push": {
                      "particles_nb": 4
                  }
                  , "remove": {
                      "particles_nb": 2
                  }
              }
          },
          "retina_detect":true
      },
  );
  }

  selectedLetter(letra){
    this.letter=letra;
  }
  activeButton(i){
    for (let index = 0; index < 20; index++) {
      this.active[index] = " ";
    }
    this.active[i] = 'active';
  }

  transformarLink(){
    let khan;
    let sitios;
    let videos;
    if(this.gameRecurso.khan_academy != ""){ 
      khan = this.gameRecurso.khan_academy.replace(/<a/gi, '<a target="__blank"'); 
      this.gameRecurso.khan_academy = khan;
    }
    if(this.gameRecurso.sitios != ""){ 
      sitios = this.gameRecurso.sitios.replace(/<a/gi, '<a target="__blank"');
      this.gameRecurso.sitios = sitios;
    }
    if(this.gameRecurso.videos != ""){ 
      videos = this.gameRecurso.videos.replace(/<a/gi, '<a target="__blank"');
      this.gameRecurso.videos = videos;
    }
  }

  botonReg(peticion){
    this.detalleService.navegacionJuegoBoton(this.token, {peticion: peticion}).subscribe();
  }
}